import React from 'react';
import { useSelector } from 'react-redux';

const VehicleDetail = () => {

    function convertToEST(unixTime) {
        const date = new Date(unixTime * 1000);
        const options = {
            timeZone: 'America/New_York',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            timeZoneName: "short",
        };
        return date.toLocaleDateString('en-US', options);
    }


    const { data } = useSelector((state) => state.report);
    const { reportData } = data
    const { VIN, testDate,deviceId,odo } = reportData;
    const { make } = VIN.VehicleMake;
    const { model } = VIN.VehicleModel;
    const { vin, year } = VIN;

    const estDateTime = convertToEST(testDate);
    // const unixTime = 1675143428;
    // console.log(estDateTime);

    // console.log({
    //     component: "VehicleDetail",
    //     reportData,
    //     model,
    //     make,
    //     vin,
    //     year
    // })

    return (
        <div className="group-338-kuqdKP">
            <div className="x07142021-359-pm-IeZKfi segoeui-semi-bold-black-28px">
                {estDateTime}<br />
            </div>
            <div className="group-337-IeZKfi">
                <div className="vehicle-id-group-P89pU0">
                    <div className="vinmakeodo-3tvl3U segoeui-semi-bold-green-house-28px-2">
                        <span className="span0-6pxC1x segoeui-semi-bold-green-house-28px">VIN:<br /></span>
                        <span className="span1-6pxC1x segoeui-semi-bold-green-house-28px">Device:<br /></span>
                        <span className="span2-6pxC1x segoeui-semi-bold-green-house-28px">Make:<br />Odo:</span>
                    </div>
                </div>
                <div className="jtdkxxxxxxxxxxxxxtoyota123456-P89pU0 segoeui-regular-normal-black-28px">
                    {vin || "-"}<br />{deviceId || "-"}<br />{make || "-"}<br />{odo || "-"}
                </div>
                <div className="group-336-P89pU0">
                    <div className="vehicle-id-group-SVm1Xq">
                        <div className="modelyear-nMDBFg segoeui-semi-bold-green-house-28px">Model:<br />Year:</div>
                    </div>
                    <div className="prius2006-SVm1Xq segoeui-regular-normal-black-28px">{model || "-"}<br />{year || "-"}</div>
                </div>
            </div>
        </div>
    )
}

export default VehicleDetail
