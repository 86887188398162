import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Config } from "../../config/index"
import STATES from "../../states"
import { Link, useNavigate, useParams } from 'react-router-dom';
import { FiEye } from "react-icons/fi"
import { AiOutlineClose } from "react-icons/ai"
import { apiGET, apiPOST } from 'utils/apiHelper';

const initialUserState = {
    "storeId": "",
    "firstName": "",
    "lastName": "",
    "email": "",
    "phoneNo": "",
    "country": "",
    "state": "",
    "address": "",
    "password": "",
}

const stringPattern = new RegExp("[a-zA-Z ]$");
const numberPattern = new RegExp("[0-9]$");
const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

const StoreUsers = ({ storeId, showModal, setShowModal }) => {
    const navigate = useNavigate();
    // const { id: storeId } = useParams();
    const { user } = useSelector((state) => state.auth);

    const [storeUser, setStoreUser] = useState(initialUserState)
    const [users, setUsers] = useState([]);
    const [stores, setStores] = useState([]);
    const [validPassword, setValidPassword] = useState(false);
    // const [showModal, setShowModal] = useState(show);

    // Get All users related to the current store
    async function getStoreUsers(storeId) {
        try {
            const res = await apiGET(`/users/store-user/storeId/${storeId}`);
            if (res.status === 200) {
                // console.log("Store Supervisor - All Store Workers =========== ", res.data)
                setUsers(res.data);
            }
        } catch (error) {
            console.log(error);
        }
    }

    // Get all stores | store spuervisor
    async function getStores() {
        try {
            const res = await apiGET(`/users/store-supervisor`);
            if (res.status === 200) {
                // console.log("Get All Store Response =========== ", res.data)
                setStores(res.data);
            }
        } catch (error) {
            console.log(error);
        }
    }

    // Add Store Worker - Based on store Id:
    async function handleAddStoreUser(e) {
        e.preventDefault();
        console.log("Add store worker : ", storeUser);

        const allTruthy = Object.values(storeUser).every(value => value);
        if (!allTruthy) {
            return toast.error("Please fill all mandatory fields!");
        }

        if (storeUser.email && emailRegex.test(storeUser.email) === false) {
            return toast.error("Invalid Email!")
        }

        if (storeUser.phoneNo && storeUser.phoneNo.length !== 10) {
            return toast.error("Invalid Phone No!")
        }

        if (storeUser.firstName && storeUser.firstName.trim().length < 3) {
            return toast.error("First name not valid!")
        }

        if (storeUser.lastName && storeUser.firstName.trim().length < 3) {
            return toast.error("Last name not valid!")
        }

        if (storeUser?.password && storeUser?.password?.length > 0 && !validPassword) {
            return toast.error('Password should contain small, capital letter, number & special character!');
        }

        try {
            const response = await apiPOST(`/users/store-user`, storeUser);

            if (response.status !== 200) {
                console.log(response.data);
                return toast.error(response?.data?.message);
            }

            if (response.status === 200) {
                toast.success("Store user addedd successfully!");
                setStoreUser(initialUserState);
                getStoreUsers(storeId);
                setShowModal(false);
            }

        } catch (error) {
            toast.error("Something went wrong!");
            console.log("Error Add Store User: ", error);
        }
    }

    const onChange = (e) => {

        if ((e.target.name === "firstName" && e.target.value) || (e.target.name === "lastName" && e.target.value)) {
            if (stringPattern.test(e.target.value) == false) {
                return
            }
        }

        if (e.target.name === "phoneNo" && e.target.value) {
            if (numberPattern.test(e.target.value) == false) {
                return
            }
        }

        if (e.target.name === 'password' && e.target.value) {
            const isValidPassword = passwordRegex.test(e.target.value);
            console.log('isValidPassword: ', isValidPassword)
            setValidPassword(isValidPassword);
        };

        setStoreUser((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }))
    }

    useEffect(() => {
        getStoreUsers(storeId);
        getStores()
    }, [storeId]);


    return (
        <>
            <div className="flex flex-col mb-20">
                <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="py-4 inline-block min-w-full sm:px-6 lg:px-8">
                        <div className="overflow-hidden">
                            {/* <div className='flex justify-end items-center mb-2'>
                                <button
                                    onClick={() => setShowModal(true)}
                                    className={'text-white py-2 px-5 rounded bg-green-500 font-medium'}>Add Store User</button>
                            </div> */}
                            <table className="min-w-full text-center">
                                <thead className="border-b bg-gray-50">
                                    <tr>
                                        <th scope="col" className="text-sm font-semibold text-gray-900 px-6 py-4">
                                            Sr.No
                                        </th>
                                        <th scope="col" className="text-sm font-semibold text-gray-900 px-6 py-4">
                                            Id
                                        </th>
                                        <th scope="col" className="text-sm font-semibold text-gray-900 px-6 py-4">
                                            Name
                                        </th>
                                        <th scope="col" className="text-sm font-semibold text-gray-900 px-6 py-4">
                                            Email
                                        </th>
                                        <th scope="col" className="text-sm font-semibold text-gray-900 px-6 py-4">
                                            Phone
                                        </th>
                                        <th scope="col" className="text-sm font-semibold text-gray-900 px-6 py-4 text-end">
                                            View Details
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {users.length > 0 ? users.map((item, index) => {
                                        return (
                                            <tr key={index} className="bg-white border-b">
                                                <td className="px-6 py-4 whitespace-nowrap text-sm font-semibold text-gray-900">
                                                    {index + 1}
                                                </td>
                                                <td className="text-sm text-gray-900 font-normal px-6 py-4 whitespace-nowrap">
                                                    <Link to={`/user-detail/${item.userId}`}> {item.userId}</Link>
                                                </td>
                                                <td className="text-sm text-gray-900 font-normal px-6 py-4 whitespace-nowrap">
                                                    <Link to={`/user-detail/${item.userId}`}> {item.firstName} {item.lastName}</Link>
                                                </td>
                                                <td className="text-sm text-gray-900 font-normal px-6 py-4 whitespace-nowrap">
                                                    {item.email}
                                                </td>
                                                <td className="text-sm text-gray-900 font-normal px-6 py-4 whitespace-nowrap">
                                                    {item.phoneNo}
                                                </td>
                                                <td className="text-sm text-gray-900 font-semibold px-6 py-4 whitespace-nowrap text-end">
                                                    <button onClick={() => navigate(`/user-detail/${item.userId}`)} className="bg-[#25C454] text-white py-2 px-3 rounded">
                                                        <span className='flex items-center justify-center'>
                                                            <FiEye className='mr-1.5' size={16} /> View Details
                                                        </span>
                                                    </button>
                                                </td>
                                            </tr>
                                        );
                                    }) :
                                        <tr className='mt-5' >
                                            <td colSpan={5}>No Data Available!</td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            {/* Add Store User Modal */}
            {showModal ? (
                <>
                    <div
                        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div className="relative w-auto my-3 mx-auto max-w-3xl">
                            {/*content*/}
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                {/*header*/}
                                <div className="flex items-center justify-between p-3 border-b border-solid border-slate-200 rounded-t">

                                    <h3 className="text-2xl font-semibold">
                                        Add User
                                    </h3>
                                    <button
                                        className="p-1 ml-auto bg-transparent border-0 text-black  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                        onClick={() => setShowModal(false)}
                                    >
                                        <span className="bg-transparent  text-black  h-6 w-6 text-2xl block outline-none focus:outline-none">
                                            <AiOutlineClose color='#000000' />
                                        </span>
                                    </button>
                                </div>
                                {/*body*/}
                                <div className="relative flex-auto">
                                    <div className="bg-[#F8F8F8] rounded p-[1.375rem]">
                                        <form className="w-full" onSubmit={handleAddStoreUser}>
                                            <div className="flex flex-wrap -mx-3 mb-3">
                                                <div className="w-full md:w-1/2 px-3 md:mb-0">
                                                    <label className="block required  tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-first-name">
                                                        First Name
                                                    </label>
                                                    <input
                                                        className="appearance-none block w-full bg-white  border-[#25C454]  border-none  dark:border-[#25C454] dark:focus:border-[#25C454]  text-gray-700  rounded py-3 px-4 mb-3 leading-tight  focus:outline-1 focus:bg-white"
                                                        id="grid-first-name"
                                                        type="text"
                                                        name="firstName"
                                                        value={storeUser.firstName}
                                                        onChange={onChange}
                                                        placeholder="Enter first name"
                                                    //required
                                                    />
                                                </div>
                                                <div className="w-full md:w-1/2 px-3">
                                                    <label className="block required tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name">
                                                        Last Name
                                                    </label>
                                                    <input
                                                        className="appearance-none block w-full bg-white  border-[#25C454]  border-none  dark:border-[#25C454] dark:focus:border-[#25C454]  text-gray-700  rounded py-3 px-4 mb-3 leading-tight  focus:outline-1 focus:bg-white"
                                                        id="grid-first-name"
                                                        type="text"
                                                        name="lastName"
                                                        value={storeUser.lastName}
                                                        onChange={onChange}
                                                        placeholder="Enter last name"
                                                    //required
                                                    />
                                                </div>
                                            </div>

                                            <div className="flex flex-wrap -mx-3 mb-3">
                                                <div className="w-full md:w-1/2 px-3  md:mb-0">
                                                    <label className="block required tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name">
                                                        Phone Number
                                                    </label>
                                                    <div className="flex">
                                                        <div className='bg-white rounded py-3 px-4 mb-3 mr-2'>
                                                            +1
                                                        </div>
                                                        <input
                                                            className="appearance-none block w-full bg-white  border-[#25C454]  border-none  dark:border-[#25C454] dark:focus:border-[#25C454]  text-gray-700  rounded py-3 px-4 mb-3 leading-tight  focus:outline-1 focus:bg-white"
                                                            id="grid-first-name"
                                                            type="text"
                                                            name="phoneNo"
                                                            minLength={10}
                                                            maxLength={10}
                                                            value={storeUser.phoneNo}
                                                            onChange={onChange}
                                                            placeholder="Enter phone no"
                                                        //required
                                                        />
                                                    </div>
                                                </div>

                                                <div className="w-full md:w-1/2 px-3">
                                                    <label className="block required tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name">
                                                        Email
                                                    </label>
                                                    <input
                                                        className="appearance-none block w-full bg-white  border-[#25C454]  border-none  dark:border-[#25C454] dark:focus:border-[#25C454]  text-gray-700  rounded py-3 px-4 mb-3 leading-tight  focus:outline-1 focus:bg-white"
                                                        id="grid-first-name"
                                                        type='email'
                                                        name="email"
                                                        value={storeUser.email}
                                                        onChange={onChange}
                                                        placeholder="Enter email" />
                                                </div>
                                            </div>

                                            <div className="flex flex-wrap -mx-3 mb-3">
                                                <div className="w-full md:w-1/2 px-3  md:mb-0">
                                                    <label className="block required tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name">
                                                        Country
                                                    </label>
                                                    <select
                                                        className="appearance-none block w-full bg-white  border-[#25C454]  border-none  dark:border-[#25C454] dark:focus:border-[#25C454]  text-gray-700  rounded py-3 px-4 mb-3 leading-tight  focus:outline-1 focus:bg-white"
                                                        name='country'
                                                        value={storeUser.country}
                                                        onChange={onChange}
                                                        id="storeUser"
                                                    //required
                                                    >
                                                        <option value={""}>Select Country</option>
                                                        <option value={'US'}>United States</option>
                                                    </select>
                                                </div>

                                                <div className="w-full md:w-1/2 px-3  md:mb-0">
                                                    <label className="block required tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name">
                                                        State
                                                    </label>
                                                    <select
                                                        className="appearance-none block w-full bg-white  border-[#25C454]  border-none  dark:border-[#25C454] dark:focus:border-[#25C454]  text-gray-700  rounded py-3 px-4 mb-3 leading-tight  focus:outline-1 focus:bg-white"
                                                        name='state'
                                                        value={storeUser.state}
                                                        onChange={onChange}
                                                        id="businessCountry"
                                                    //required
                                                    >
                                                        <option value={""}>Select State</option>
                                                        {STATES.map((item, index) => (
                                                            <option key={index} value={item.abbreviation}>{item.name}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="flex flex-wrap -mx-3 mb-3">
                                                <div className="w-full md:w-1/2 px-3  md:mb-0">
                                                    <label className="block required tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name">
                                                        Select Store
                                                    </label>
                                                    <select
                                                        className="appearance-none block w-full bg-white  border-[#25C454]  border-none  dark:border-[#25C454] dark:focus:border-[#25C454]  text-gray-700  rounded py-3 px-4 mb-3 leading-tight  focus:outline-1 focus:bg-white"
                                                        name='storeId'
                                                        value={storeUser.storeId}
                                                        onChange={onChange}
                                                        id="businessCountry"
                                                    //required
                                                    >
                                                        <option value={""}>Select Store</option>
                                                        {stores.map((item, index) => (
                                                            <option key={index} value={item.storeId}>{item?.store?.storeName}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="w-full md:w-1/2 px-3 md:mb-0">
                                                    <label className="block required tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-first-name">
                                                        Password
                                                    </label>
                                                    <input
                                                        className="appearance-none block w-full bg-white  border-[#25C454]  border-none  dark:border-[#25C454] dark:focus:border-[#25C454]  text-gray-700  rounded py-3 px-4 mb-3 leading-tight  focus:outline-1 focus:bg-white"
                                                        id="grid-first-name"
                                                        type="password"
                                                        name='password'
                                                        value={storeUser.password}
                                                        onChange={onChange}
                                                        placeholder="Enter password"
                                                    //required
                                                    />

                                                </div>
                                            </div>
                                            <div className="flex flex-wrap -mx-3 mb-3">
                                                <div className="w-full px-3 md:mb-0">
                                                    <label className="block required tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-first-name">
                                                        Address
                                                    </label>
                                                    <input
                                                        className="appearance-none block w-full bg-white  border-[#25C454]  border-none  dark:border-[#25C454] dark:focus:border-[#25C454]  text-gray-700  rounded py-3 px-4 mb-3 leading-tight  focus:outline-1 focus:bg-white"
                                                        id="grid-first-name"
                                                        type="text"
                                                        name='address'
                                                        value={storeUser.address}
                                                        onChange={onChange}
                                                        placeholder="Enter address"
                                                    //required
                                                    />
                                                </div>
                                            </div>

                                            <div className='flex items-center justify-end pt-2 border-t border-solid border-slate-200 rounded-b'>
                                                <button
                                                    type='button'
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        console.log("Cancel Clicked!");
                                                        setShowModal(false)
                                                    }}
                                                    className="bg-white rounded-[0.3125rem] py-2 px-3 border">Cancel</button>
                                                <button
                                                    type='submit'
                                                    // onClick={handleAddStoreUser}
                                                    className="ml-[0.5rem] bg-[#25C454] text-white rounded-[0.3125rem] py-2 px-3">
                                                    Save
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null
            }

        </>
    )
}

export default StoreUsers
