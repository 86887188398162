import { configureStore } from '@reduxjs/toolkit'
import authReducer from "../features/auth/authSlice";
import reportReducer from "../features/report/reportSlice"
import storeReducer from "../features/store/storeSlice"

const store = configureStore({
    reducer: {
        auth: authReducer,
        report: reportReducer,
        store: storeReducer
    },
});

export default  store;
