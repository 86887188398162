
import axios from 'axios';
import { useState, useEffect } from 'react';
import { BsArrowLeft } from 'react-icons/bs';
import { toast } from 'react-toastify';
import { Config } from "../../../config/index"
import STATES from '../../../states';
import { CardElement, useElements, useStripe, Elements } from "@stripe/react-stripe-js";
import { login, reset } from 'features/auth/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const CARD_ELEMENT_OPTIONS = {
    style: {
        base: {
            'color': '#32325d',
            'fontFamily': '"Helvetica Neue", Helvetica, sans-serif',
            'fontSmoothing': 'antialiased',
            'fontSize': '14px',
            '::placeholder': {
                color: '#aab7c4',
            },
        },
        invalid: {
            color: '#fa755a',
            iconColor: '#fa755a',
        },

    },
};

const initialValues = {
    "firstName": "",
    "lastName": "",
    "email": "",
    "country": "",
    "state": "",
    "address": "",
    "phoneNo": "",
}

function IndividualSignup() {

    const { user, isLoading, isError, isSuccess, message } = useSelector((state) => state.auth);

    const [showPayment, setShowPayment] = useState(false);
    const [loading, setLoadig] = useState(false);
    const [formData, setFormData] = useState(initialValues);
    const [userData, setUserData] = useState(null);
    const [subscLoading, setSubsLoading] = useState(false);

    const stripe = useStripe();
    const elements = useElements();
    const dispatch = useDispatch();
    const navigate = useNavigate()

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    const onChange = (e) => {

        const stringPattern = new RegExp("[a-zA-Z ]$");
        const numberPattern = new RegExp("[0-9]$");

        if ((e.target.name === "firstName" && e.target.value) || (e.target.name === "lastName" && e.target.value)) {
            if (stringPattern.test(e.target.value) == false) {
                // toast.error("Names can only contain alphabets!");
                return
            }
        }

        if (e.target.name === "phoneNo" && e.target.value) {
            if (numberPattern.test(e.target.value) == false) {
                // toast.error("Phone number cannot contain alphabets!");
                return
            }
        }

        if (e.target.name === 'address' && e.target.value) {
            setFormData({ ...formData, address: e.target.value });
            return
        };

        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value.trim(),
        }))

        // setFormData((prevState) => ({
        //     ...prevState,
        //     [e.target.name]: e.target.value,
        // }))
    }

    const signup = async (data) => {
        setLoadig(true);
        // const url = `${Config.API_URL}/users/register`
        const url = `${Config.API_URL}/users/individual`
        try {
            const res = await axios.post(url, data);

            if (res.status === 200) {
                setFormData(initialValues);
                setUserData(res.data);
                toast.success("Account Created Successfully!");
                setShowPayment(true);
            }

            if (res.status !== 200) {
                toast.error(res.data.message || 'Error!');
            }

        } catch (error) {
            console.log(error);
            toast.error(error?.response?.data?.message || 'Error!')
        } finally {
            setLoadig(false);
        }
    }

    const handleSignup = (e) => {
        e.preventDefault();
        // console.log(JSON.stringify(formData, null, 2));

        if (!formData.email || !formData.firstName || !formData.lastName || !formData.phoneNo || !formData.country || formData.state === "" || formData.country === "") {
            return toast.error("Please fill all mandatory fields!");
        }

        if (formData.email && emailRegex.test(formData.email) === false) {
            return toast.error("Invalid Email!")
        }

        if (formData.phoneNo && formData.phoneNo.length !== 10) {
            return toast.error("Invalid Phone No!")
        }

        if (formData.firstName && formData.firstName.trim().length < 3) {
            return toast.error("First name not valid!")
        }

        if (formData.lastName && formData.lastName.trim().length < 3) {
            return toast.error("Last name not valid!")
        }

        signup(formData);
    }

    const updatePayment = async (id, email) => {
        setSubsLoading(true);

        try {
            const response = await axios.put(`${Config.API_URL}/payment/${id}`);
            console.log('Update Payment Response: ', JSON.stringify(response.data, null, 3));

            if (response.status === 200) {
                const { status, subscriptionStatus } = response.data;
                if (status === 'paid' && subscriptionStatus === 'active') {
                    toast.success('Success! Please login');
                    navigate('/success')
                    // dispatch(login({
                    //     email,
                    //     password: 'pass123'
                    // }));
                }
            }

            if (response.status !== 200) {
                console.log(response.data)
                toast.error(response?.data?.message);
            }

        } catch (error) {
            console.log('Update Payment Error: ', error);
            toast.error(error?.response?.data?.message || 'Something went wrong!')
        } finally {
            setSubsLoading(false);
        }
    }

    const handleSubscription = async (e) => {
        e.preventDefault();

        const { email, firstName, lastName, userId, role, password } = userData;

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        const result = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardElement),
            billing_details: {
                email: email
            },
        });

        console.log("createPaymentMethod result: ", JSON.stringify(result, null, 3));

        if (result.error) {
            toast.error(result.error.message || 'Something went wrong!');
            console.log("CreatePaymentMethodError: ", result.error.message);
        } else {
            setSubsLoading(true);
            try {
                const res = await axios.post(`${Config.API_URL}/payment`,
                    {
                        'payment_method': result.paymentMethod.id,
                        'email': email
                    });
                // eslint-disable-next-line camelcase
                const { client_secret, status, paymentId } = res.data;
                console.log("subscription Response: ", JSON.stringify(res.data, null, 3));

                if (status === 'requires_action') {
                    stripe.confirmCardPayment(client_secret).then(function (result) {
                        console.log("confirmCardPayment repsonse: ", JSON.stringify(result, null, 3));
                        if (result.error) {
                            console.log('There was an issue!');
                            console.log(result.error);
                            toast.error('Something went wrong!');
                            // Display error message in your UI.
                            // The card was declined (i.e. insufficient funds, card has expired, etc)
                        } else if (result?.paymentIntent.status === "succeeded") {
                            if (paymentId) {
                                updatePayment(paymentId, email);
                            }
                        }
                    });
                } else {
                    if (paymentId) {
                        updatePayment(paymentId, email);
                    }
                    // No additional information was needed
                    // Show a success message to your customer
                }
            } catch (error) {
                console.log("Subscription Error: ", error);
                toast.error(error?.response?.data?.message || 'Something went wrong!')
            } finally {
                setSubsLoading(false);
            }
        }
    }

    useEffect(() => {
        if (isError) {
            toast.error(message)
        }

        if (isSuccess || user) {
            navigate('/home')
        }

        dispatch(reset())
    }, [user, isError, isSuccess, message, navigate, dispatch])

    return (
        <>
            {!showPayment ? (
                <div className="bg-white rounded-md">
                    <form onSubmit={handleSignup}>
                        <div className="mt-[0.5rem] mx-9">
                            <label className="text-left font-semibold text-xs required">First Name</label>
                            <input
                                type="text"
                                className="py-2 pl-2 font-medium text-xs bg-slate-50 w-full rounded-[0.375rem] border-x-0 border-t-0 border-b-2 border-[#25C454]"
                                id="firstName"
                                placeholder="Enter first name"
                                value={formData.firstName}
                                // required
                                onChange={onChange}
                                name="firstName" />
                        </div>
                        <div className="mt-[0.4rem] mx-9">
                            <label className="text-left font-semibold text-xs required">Last Name</label>
                            <input
                                type="text"
                                className="py-2 pl-2 font-medium text-xs bg-slate-50 w-full rounded-[0.375rem] border-x-0 border-t-0 border-b-2 border-[#25C454]"
                                id="lastName"
                                placeholder="Enter last name"
                                value={formData.lastName}
                                onChange={onChange}
                                // required
                                name="lastName" />
                        </div>
                        <div className="mt-[0.4rem] mx-9">
                            <label className="text-left font-semibold text-xs required">Email</label>
                            <input
                                type="email"
                                className="py-2 pl-2 font-medium text-xs bg-slate-50 w-full rounded-[0.375rem] border-x-0 border-t-0 border-b-2 border-[#25C454]"
                                id="email"
                                placeholder="Enter Email"
                                value={formData.email}
                                onChange={onChange}
                                // required
                                name="email" />
                        </div>
                        <div className="mt-[0.4rem] mx-9">
                            <label className="text-left font-semibold text-xs required">Phone Number</label>
                            <div className="flex w-full">
                                <div className="text-center py-[0.7rem] px-[1rem] bg-slate-50 text-xs rounded-[0.375rem] ">
                                    +1
                                </div>
                                <input
                                    type="text"
                                    className="font-medium text-xs ml-[0.625rem] pl-2 w-full bg-slate-50 rounded-[0.375rem] border-x-0 border-t-0 border-b-2 border-[#25C454]"
                                    id="userMobile"
                                    placeholder="Enter mobile number"
                                    name="phoneNo"
                                    value={formData.phoneNo}
                                    // required
                                    maxLength={10}
                                    minLength={10}
                                    onChange={onChange}
                                />
                            </div>
                        </div>
                        <div className="mt-[0.4rem] mx-9">
                            <b className="text-left font-semibold text-xs required">Country</b>
                            <select
                                className="font-medium py-2 pl-2 text-xs bg-slate-50 w-full rounded-[0.375rem] border-x-0 border-t-0 border-b-2 border-[#25C454]"
                                name='country'
                                value={formData.country}
                                onChange={onChange}
                                // required
                                id="userCountry">
                                <option value={""}>Select Country</option>
                                <option value={'US'}>United States</option>

                            </select>
                        </div>
                        <div className="mt-[0.4rem] mx-9">
                            <b className="text-left font-semibold text-xs required">State</b>
                            <select
                                name='state'
                                value={formData.state}
                                onChange={onChange}
                                // required
                                className="font-medium py-2 pl-2 text-xs bg-slate-50 w-full rounded-[0.375rem] border-x-0 border-t-0 border-b-2 border-[#25C454]"
                                id="state">
                                <option value={""}>Select State</option>
                                {STATES.map((item) => (
                                    <option value={item.abbreviation}>{item.name}</option>
                                ))}
                            </select>
                        </div>


                        <div className="mx-9 mt-[1rem] mb-[1rem]">
                            {loading ?
                                <button className="flex items-center justify-center py-3.5 font-semibold w-full rounded-[0.375rem] bg-[#25C454] text-white text-base">
                                    <svg aria-hidden="true" className="w-5 h-5 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-[#FFFFFF]" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                    </svg>
                                    Loading....
                                </button> :
                                <button
                                    type="submit"
                                    className="flex items-center justify-center py-3.5 font-semibold w-full rounded-[0.375rem] bg-[#25C454] text-white text-base">
                                    Next
                                </button>
                            }
                        </div>
                    </form>
                </div>
            ) : (
                <div className="bg-white rounded-md">
                    <div className="m-[2rem] border-2 border-black-100 pt-[1.25rem] px-[1.375rem]">
                        <div className="flex">
                            <div className="font-semibold text-base w-full text-center">Payment via Stripe</div>
                        </div>
                        <div className="mt-[1.5rem] mb-5">
                            <div className="py-2 pl-2 text-xs bg-[#F6F6F6] w-full rounded-[0.375rem] border-x-0 border-t-0 border-b-2 border-[#25C454]">
                                <CardElement options={CARD_ELEMENT_OPTIONS} />
                            </div>
                        </div>
                    </div>
                    <div className=" px-[2.375rem] pb-[1rem]">
                        {/* <button
                            onClick={() => {
                                window.location.reload();
                            }}
                            className="py-3 font-semibold w-full rounded-[0.375rem] bg-[#FFFFFF] text-black text-base mb-2 border">Skip Payment</button> */}
                        <button
                            onClick={handleSubscription}
                            className="py-3 font-semibold w-full rounded-[0.375rem] bg-[#25C454] text-white text-base"
                        >
                            {subscLoading ? 'Loading....' : 'Pay $50.00 USD'}
                        </button>
                    </div>
                </div>
            )}

        </>
    );
}

export default IndividualSignup;