import { useState } from 'react';
import { BsGeoAlt, BsArrowLeft, BsArrowRight } from 'react-icons/bs';
import { BiEnvelope } from 'react-icons/bi';
import { Config } from "../../../config/index";
import axios from "axios";
import { toast } from 'react-toastify';
import { CardElement, useElements, useStripe, Elements } from "@stripe/react-stripe-js";
import { useNavigate } from 'react-router-dom';

const CARD_ELEMENT_OPTIONS = {
    style: {
        base: {
            'color': '#32325d',
            'fontFamily': '"Helvetica Neue", Helvetica, sans-serif',
            'fontSmoothing': 'antialiased',
            'fontSize': '14px',
            '::placeholder': {
                color: '#aab7c4',
            },
        },
        invalid: {
            color: '#fa755a',
            iconColor: '#fa755a',
        },

    },
};



function BusinessSignup() {

    const [activeForm, setActiveForm] = useState('business')
    const [showPayment, setShowPayment] = useState(false);
    const [addStore, setAddStore] = useState(false);
    const [userId, setUserId] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [userData, setUserData] = useState(null);

    const stringPattern = new RegExp("[a-zA-Z ]$");
    const numberPattern = new RegExp("[0-9]$");
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;


    const Business = (props) => {
        const initialData = {
            "businessName": "",
            "ownerName": "",
            "email": "",
            "country": "",
            "state": "",
            "address": "",
            "phoneNo": ""
        }

        const [formData, setFormData] = useState(initialData)

        const onChange = (e) => {


            if ((e.target.name === "businessName" && e.target.value) || (e.target.name === "ownerName" && e.target.value)) {
                if (stringPattern.test(e.target.value) == false) {
                    return
                }
            }

            if (e.target.name === "phoneNo" && e.target.value) {
                if (numberPattern.test(e.target.value) == false) {
                    return
                }
            }

            setFormData((prevState) => ({
                ...prevState,
                [e.target.name]: e.target.value,
            }))
        }

        const signup = async (data) => {
            setIsLoading(true);
            const url = `${Config.API_URL}/users/business`
            try {
                const res = await axios.post(url, data);
                if (res.status === 200) {
                    console.log({
                        msg: 'Added Business User Response',
                        response: res.data
                    })
                    setUserData(res.data)
                    setUserId(res.data?.businessId);
                    toast.success("Business Account Created Successfully!");
                    setFormData(initialData);
                    setActiveForm('store');
                }

                if (res.status !== 200) {
                    const msg = res.data?.message ? res.data?.message : 'Something went wrong!'
                    toast.error(msg)
                }

            } catch (error) {
                toast.error(error?.response?.data?.message)
                console.log(error);
            } finally {
                setIsLoading(false);
            }
        }

        const handleSignup = (e) => {
            e.preventDefault();
            if (!formData.email || !formData.businessName || !formData.ownerName || !formData.phoneNo || !formData.address) {
                return toast.error("Please fill all mandatory fields!");
            }

            if (formData.email && emailRegex.test(formData.email) === false) {
                return toast.error("Invalid Email!")
            }

            if (formData.phoneNo && formData.phoneNo.length !== 10) {
                return toast.error("Invalid Phone No!")
            }

            if (formData.businessName && formData.businessName.trim().length < 3) {
                return toast.error("Business name not valid!")
            }

            if (formData.ownerName && formData.ownerName.trim().length < 3) {
                return toast.error("Owner name not valid!")
            }

            signup(formData);
        }

        return (
            <div className="bg-white rounded-md">
                <form onSubmit={handleSignup}>
                    <div className="mt-[2rem] mx-9">
                        <label className="text-start font-semibold text-xs required">Business Name</label>
                        <input
                            type="text"
                            className="font-medium text-xs py-2 pl-2 w-full bg-slate-50 rounded-[0.375rem] border-x-0 border-t-0 border-b-2 border-[#25C454]"
                            id="businessName"
                            name="businessName"
                            value={formData.businessName}
                            // required
                            onChange={onChange}
                            placeholder="Enter business name"
                        />
                    </div>
                    <div className="mt-[0.4rem] mx-9">
                        <label className="text-start font-semibold text-xs required">Owner Name</label>
                        <input
                            type="text"
                            className="font-medium text-xs py-2 pl-2 w-full bg-slate-50 rounded-[0.375rem] border-x-0 border-t-0 border-b-2 border-[#25C454]"
                            id="ownerName"
                            placeholder="Enter owner name"
                            name="ownerName"
                            value={formData.ownerName}
                            // required
                            onChange={onChange}
                        />
                    </div>
                    <div className="mt-[0.4rem] mx-9">
                        <label className="text-start font-semibold text-xs required ">Location</label>
                        <div className="flex relative ">
                            <input
                                type="text"
                                className="font-medium py-2 pl-2 text-xs relative bg-slate-50 w-full rounded-[0.375rem] border-x-0 border-t-0 border-b-2 border-[#25C454]"
                                id="location"
                                placeholder="Location"
                                name="address"
                                value={formData.address}
                                // required
                                onChange={onChange}
                            />
                            <BsGeoAlt className='absolute right-4 top-2.5' />
                        </div>
                    </div>
                    <div className="mt-[0.4rem] mx-9">
                        <label className="text-start  font-semibold text-xs required">Email</label>
                        <div className="flex relative">
                            <input
                                type="email"
                                className="font-medium text-xs py-2 pl-2 relative bg-slate-50 w-full rounded-[0.375rem] border-x-0 border-t-0 border-b-2 border-[#25C454]"
                                id="businessEmail"
                                placeholder="Enter your email"
                                name="email"
                                value={formData.email}
                                // required
                                onChange={onChange}
                            />
                            <BiEnvelope className='absolute right-4 top-2.5' />
                        </div>
                    </div>
                    <div className="mt-[0.4rem] mx-9">
                        <label className="text-start font-semibold text-xs required">Phone Number</label>
                        <div className="flex">
                            <div className="text-xs text-center py-[0.7rem] px-[1rem] bg-slate-50 text-[1rem] rounded-[0.375rem] ">
                                +1
                            </div>
                            <input
                                type="text"
                                className="font-medium pl-2 text-xs ml-[0.625rem] bg-slate-50 w-full rounded-[0.375rem] border-x-0 border-t-0 border-b-2 border-[#25C454]"
                                id="businessMobile"
                                placeholder="Enter phone number"
                                name="phoneNo"
                                // required
                                maxLength={10}
                                minLength={10}
                                value={formData.phoneNo}
                                onChange={onChange}
                            />
                        </div>
                    </div>
                    <div className="mx-9 mt-[2.5rem] pb-[1rem]">
                        {/* <button type="submit" onClick={() => setActiveForm('store')} className="py-3.5 font-semibold w-full  rounded-[0.375rem] bg-[#25C454] text-white text-base">Next</button> */}
                        {isLoading ? (
                            <button className="flex items-center justify-center py-3.5 font-semibold w-full rounded-[0.375rem] bg-[#25C454] text-white text-base">
                                <svg aria-hidden="true" className="w-5 h-5 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-[#FFFFFF]" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                </svg>
                                Loading....
                            </button>
                        ) : (
                            <button className="flex items-center justify-center py-3.5 font-semibold w-full  rounded-[0.375rem] bg-[#25C454] text-white text-base cursor-pointer"
                                type='submit'
                            >
                                <BsArrowRight color='#FFFFFF' className='mr-1' />
                                <p>Add Store</p>
                            </button>
                        )}
                    </div>
                </form>
            </div>
        )
    }

    const Store = ({ userId }) => {

        console.log("businessId Id in Store: ", userId);
        const initialData = {
            "businessId": userId,
            "storeName": "",
            "ownerName": "",
            "email": "",
            "phoneNo": "",
            "shopNo": "",
            "country": "",
            "state": "",
            "address": ""
        }
        const [storeData, setStoreData] = useState(initialData);
        const [loading, setLoading] = useState(false);

        const handleSubmit = (e) => {
            e.preventDefault();
            console.log("storeData: ", storeData);
            if (!storeData.storeName || !storeData.ownerName || !storeData.email || !storeData.phoneNo || !storeData.businessId || !storeData.address) {
                return toast.error("Please fill all mandatory fields!");
            }

            if (storeData.email && emailRegex.test(storeData.email) === false) {
                return toast.error("Invalid Email!")
            }

            if (storeData.phoneNo && storeData.phoneNo.length !== 10) {
                return toast.error("Invalid Phone No!")
            }

            if (storeData.storeName && storeData.storeName.trim().length < 3) {
                return toast.error("Store Name not valid!")
            }

            if (storeData.ownerName && storeData.storeName.trim().length < 3) {
                return toast.error("Store Name not valid!")
            }

            addStore(storeData);
            // setActiveForm('payment');
        }

        const addStore = async (data) => {
            setLoading(true);
            const url = `${Config.API_URL}/users/store-supervisor`
            try {
                const res = await axios.post(url, data);
                if (res.status === 200) {
                    console.log({
                        msg: 'Store Added Response',
                        response: res.data
                    })
                    toast.success("Store Added Successfully!");
                    setActiveForm('payment');
                    setStoreData(initialData)
                }

                if (res.status !== 200) {
                    console.log(res.data);
                    toast.error(res.data.message || 'Something went wrong!')
                }

            } catch (error) {
                console.log(error);
                toast.error(error?.response?.data?.message || 'Something went wrong!');
            } finally {
                setLoading(false);
            }
        }

        const onChange = (e) => {

            if ((e.target.name === "storeName" && e.target.value) || (e.target.name === "ownerName" && e.target.value)) {
                if (stringPattern.test(e.target.value) == false) {
                    return
                }
            }

            if (e.target.name === "phoneNo" && e.target.value) {
                if (numberPattern.test(e.target.value) == false) {
                    return
                }
            }

            setStoreData((prevState) => ({
                ...prevState,
                [e.target.name]: e.target.value,
            }))
        }

        return (
            <div className="bg-white rounded-md">
                <form onSubmit={handleSubmit}>
                    <div className="mt-[2rem] mx-9">
                        <label className="text-start font-semibold text-xs required">Store Name</label>
                        <input
                            type="text"
                            className="font-medium text-xs py-2 pl-2 w-full bg-slate-50 rounded-[0.375rem] border-x-0 border-t-0 border-b-2 border-[#25C454]"
                            id="storeName"
                            placeholder="Enter store name"
                            name="storeName"
                            value={storeData.storeName}
                            // required
                            onChange={onChange}
                        />
                    </div>
                    <div className="mt-[0.4rem] mx-9">
                        <label className="text-start font-semibold text-xs required">Manager Name</label>
                        <input
                            type="text"
                            className="font-medium text-xs py-2 pl-2 w-full bg-slate-50 rounded-[0.375rem] border-x-0 border-t-0 border-b-2 border-[#25C454]"
                            id="ownerName"
                            name="ownerName"
                            placeholder="Enter manager name"
                            value={storeData.ownerName}
                            // required
                            onChange={onChange}
                        />
                    </div>
                    <div className="mt-[0.4rem] mx-9">
                        <label className="text-start font-semibold text-xs required">Location</label>
                        <div className="flex relative ">
                            <input
                                type="text"
                                className="font-medium py-2 pl-2 text-xs relative bg-slate-50 w-full rounded-[0.375rem] border-x-0 border-t-0 border-b-2 border-[#25C454]"
                                id="location"
                                placeholder="Location"
                                name="address"
                                value={storeData.address}
                                // required
                                onChange={onChange}
                            />
                            <BsGeoAlt className='absolute right-4 top-2.5' />
                        </div>
                    </div>
                    <div className="mt-[0.4rem] mx-9">
                        <label className="text-start  font-semibold text-xs required">Email</label>
                        <div className="flex relative">
                            <input
                                type="email"
                                className="font-medium text-xs py-2 pl-2 relative bg-slate-50 w-full rounded-[0.375rem] border-x-0 border-t-0 border-b-2 border-[#25C454]"
                                id="businessEmail"
                                placeholder="Enter your email"
                                name="email"
                                value={storeData.email}
                                onChange={onChange}
                            // required
                            />
                            <BiEnvelope className='absolute right-4 top-2.5' />
                        </div>
                    </div>
                    <div className="mt-[0.4rem] mx-9">
                        <label className="text-start font-semibold text-xs required">Phone Number</label>
                        <div className="flex">
                            <div className="text-xs text-center py-[0.7rem] px-[1rem] bg-slate-50 text-[1rem] rounded-[0.375rem] ">
                                +1
                            </div>
                            <input
                                type="text"
                                className="font-medium pl-2 text-xs ml-[0.625rem] bg-slate-50 w-full rounded-[0.375rem] border-x-0 border-t-0 border-b-2 border-[#25C454]"
                                id="businessMobile"
                                placeholder="Enter phone number"
                                name="phoneNo"
                                value={storeData.phoneNo}
                                // required
                                minLength={10}
                                maxLength={10}
                                onChange={onChange}
                            />
                        </div>
                    </div>
                    <div className="mx-9 mt-[2.5rem] pb-[1rem]">

                        {loading ? (
                            <button className="flex items-center justify-center py-3.5 font-semibold w-full rounded-[0.375rem] bg-[#25C454] text-white text-base">
                                <svg aria-hidden="true" className="w-5 h-5 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-[#FFFFFF]" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                </svg>
                                Loading....
                            </button>
                        ) : (
                            <button type="submit" className="py-3.5 font-semibold w-full  rounded-[0.375rem] bg-[#25C454] text-white text-base">
                                Submit
                            </button>
                        )}
                        {/* <button type="submit" onClick={handleSubmit} className="py-3.5 font-semibold w-full  rounded-[0.375rem] bg-[#25C454] text-white text-base">

                        Next</button> */}

                    </div>
                </form>
            </div>
        )
    }

    const Payment = ({ userData }) => {

        const [subscLoading, setSubsLoading] = useState(false);
        const stripe = useStripe();
        const elements = useElements();
        const navigate = useNavigate()

        const updatePayment = async (id, email) => {
            setSubsLoading(true);

            try {
                const response = await axios.put(`${Config.API_URL}/payment/${id}`);
                console.log('Update Payment Response: ', JSON.stringify(response.data, null, 3));

                if (response.status === 200) {
                    const { status, subscriptionStatus } = response.data;
                    if (status === 'paid' && subscriptionStatus === 'active') {
                        navigate('/success');
                        // dispatch(login({
                        //     email,
                        //     password: 'pass123'
                        // }));
                        // alert('Payment done successfully!');
                    }
                }

                if (response.status !== 200) {
                    console.log(response.data)
                    toast.error(response?.data?.message);
                }

            } catch (error) {
                console.log('Update Payment Error: ', error);
                toast.error(error?.response?.data?.message || 'Something went wrong!')
            } finally {
                setSubsLoading(false);
            }
        }

        const handleSubscription = async (e) => {
            e.preventDefault();

            const { email } = userData;

            if (!stripe || !elements) {
                // Stripe.js has not yet loaded.
                // Make sure to disable form submission until Stripe.js has loaded.
                return;
            }

            const result = await stripe.createPaymentMethod({
                type: 'card',
                card: elements.getElement(CardElement),
                billing_details: {
                    email: email
                },
            });

            console.log("createPaymentMethod result: ", JSON.stringify(result, null, 3));

            if (result.error) {
                console.log(result.error.message);
                toast.error('Something went wrong!');
            } else {
                setSubsLoading(true);
                try {
                    const res = await axios.post(`${Config.API_URL}/payment`,
                        {
                            'payment_method': result.paymentMethod.id,
                            'email': email
                        });
                    // eslint-disable-next-line camelcase
                    const { client_secret, status, paymentId } = res.data;
                    console.log("subscription Response: ", JSON.stringify(res.data, null, 3));

                    if (status === 'requires_action') {
                        stripe.confirmCardPayment(client_secret).then(function (result) {
                            console.log("confirmCardPayment repsonse: ", JSON.stringify(result, null, 3));
                            if (result.error) {
                                console.log('There was an issue!');
                                console.log(result.error);
                                toast.error('Something went wrong!');
                                // Display error message in your UI.
                                // The card was declined (i.e. insufficient funds, card has expired, etc)
                            } else if (result?.paymentIntent.status === "succeeded") {
                                // alert('Success!');
                                toast.success('Success! Please login');
                                if (paymentId) {
                                    updatePayment(paymentId, email);
                                }
                            }
                        });
                    } else {
                        // alert('Success!');
                        console.log('You got the money!2');
                        if (paymentId) {
                            updatePayment(paymentId, email);
                        }
                        toast.success('Success! Please login');
                        // No additional information was needed
                        // Show a success message to your customer
                    }
                } catch (error) {
                    console.log("Subscription Error: ", error);
                    toast.error(error?.response?.data?.message || 'Something went wrong!')
                } finally {
                    setSubsLoading(false);
                }
            }
        }

        return (
            <div className="bg-white rounded-md">
                <div className="m-[2rem] border-2 border-black-100 pt-[1.25rem] px-[1.375rem]">
                    <div className="flex">
                        {/* <BsArrowLeft onClick={() => setShowPayment(false)} /> */}
                        <div className="font-semibold text-base w-full text-center">Payment via Stripe</div>
                    </div>
                    <div className="mt-[1.5rem] mb-5">
                        {/* <label className="text-left font-semibold text-xs">Card Number</label> */}
                        <div className="py-2 pl-2 text-xs bg-[#F6F6F6] w-full rounded-[0.375rem] border-x-0 border-t-0 border-b-2 border-[#25C454]">
                            <CardElement options={CARD_ELEMENT_OPTIONS} />
                        </div>
                        {/* <input type="text" className="py-2 pl-2 text-xs bg-[#F6F6F6] w-full rounded-[0.375rem] border-x-0 border-t-0 border-b-2 border-[#25C454]" id="cardNum" placeholder="Enter Card Number" name="cardNum" /> */}
                    </div>
                </div>
                <div className=" px-[2.375rem] pb-[1rem]">
                    {/* <button
                        onClick={() => {
                            window.location.reload();
                        }}
                        className="py-3 font-semibold w-full rounded-[0.375rem] bg-[#FFFFFF] text-black text-base mb-2 border">
                            Skip Payment
                        </button> */}
                    <button
                        onClick={handleSubscription}
                        className="py-3 font-semibold w-full rounded-[0.375rem] bg-[#25C454] text-white text-base"
                    >
                        {subscLoading ? 'Loading....' : 'Pay $85.00 USD'}
                    </button>
                </div>
            </div>
        )
    }

    const NOTIFICATION_STATES = {
        business: <Business />,
        store: <Store userId={userId} />,
        payment: <Payment userData={userData} />,
    };

    return (
        <>
            {NOTIFICATION_STATES[activeForm]}
        </>
    )
}

export default BusinessSignup;