import React, { useState } from 'react'

import Reports from './Reports';
import MyProfile from './MyProfile';
import { useEffect } from 'react';
import { logout, reset } from 'features/auth/authSlice';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import TestTable from './TestTable';
import { MdOutlineLogout } from "react-icons/md"
import moment from 'moment';

const IndividualAccount = () => {
    const [activeTab, setActiveTab] = useState("reports");
    const [searchTerm, setSearchTerm] = useState("");

    const { user } = useSelector((state) => state.auth);
    // console.log({
    //     user
    // })
    const navigate = useNavigate();
    const dispatch = useDispatch();


    const expiredOn = new Date(user?.expiresOn * 1000);

    const onLogout = () => {
        dispatch(logout())
        dispatch(reset())
        // navigate('/')
    }


    return (
        <div className=''>
            {/* Title - Header */}
            <div className='border-b-2 '>
                <div className="container">
                    <div className='flex justify-between items-center mb-2'>
                        <div className='title'>
                            <p className="text-2xl font-semibold">Individual Account</p>
                            {/* <p className="text-xs font-normal">You can see here all details.</p> */}
                        </div>

                        <div className='logout'>
                            <div className='flex items-center justify-center ' >
                                {expiredOn ? <div className='border mr-2 py-2 px-5 rounded'><strong>Expires on:</strong> <span>{moment(expiredOn).format("MMM-DD-YYYY")}</span></div> : '-'}
                                <button onClick={onLogout} className='flex items-center justify-center text-white py-2 px-5 rounded' style={{ backgroundColor: "#FF0606" }}>Logout <MdOutlineLogout className='ml-2' /></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container">
                {/* TABS */}
                <div className='flex flex-row justify-between py-3 xms-col'>
                    <div className='flex tabs'>
                        <div className='reports-tab'>
                            <button onClick={() => setActiveTab("reports")} className={` ${activeTab === "reports" ? 'text-white py-2 px-5 rounded bg-green-500' : 'text-black py-2 px-5 rounded '} font-bold`}>Reports</button>
                        </div>
                        <div className='profile-tab'>
                            <button onClick={() => setActiveTab("profile")}
                                // className=' text-black py-2 px-5 rounded  mr-2`
                                className={` ${activeTab === "profile" ? 'text-white py-2 px-5 rounded bg-green-500' : 'text-black py-2 px-5 rounded '} mr-2 font-bold`}

                            >My Profile</button>
                        </div>

                    </div>
                    {activeTab === 'reports' ? (
                        <div className="searchbar w-72  xms-col-mt">
                            <form>
                                <label for="default-search" className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-gray-300">Search</label>
                                <div className="relative">
                                    <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                                        <svg aria-hidden="true" className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                                    </div>
                                    <input
                                        type="search"
                                        id="default-search"
                                        className="block p-2 pl-10 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        placeholder="Search VIN"
                                        required
                                        onChange={(event) => {
                                            setSearchTerm(event.target.value);
                                        }}
                                    />
                                </div>
                            </form>
                        </div>
                    ) : null}
                </div>
                {activeTab === "reports" ? (
                    <Reports searchTerm={searchTerm} />
                    // <TestTable />
                ) : (
                    <MyProfile />
                )}

            </div>
        </div >
    )
}

export default IndividualAccount
