import React, { useEffect, useState } from 'react'
import Modal from 'components/Modal/Modal';
import { Link, useParams } from 'react-router-dom';
import { FiEye } from "react-icons/fi"
import axios from 'axios';
import { Config } from "../../config"
import { getParameterByVehilce } from '../../features/report/reportSlice';
import { useDispatch, useSelector } from 'react-redux';
import { apiGET } from 'utils/apiHelper';

const UsersReports = ({ searchTerm }) => {
    const dispatch = useDispatch()
    const [showModal, setShowModal] = React.useState(false);
    const [reports, setReports] = React.useState([]);

    const { id: userId } = useParams();

    const { user } = useSelector((state) => state.auth);
    const { id, jwtToken } = user;

    const config = {
        headers: {
            Authorization: `Bearer ${jwtToken}`,
        },
    }

    // User TestSession Data:
    const getUserReports = async (userId) => {
        try {
            const response = await apiGET(`/test-session/store/users/reports/${userId}`);
            console.log("Store User Reports ========== ", response.data);
            if (response.status === 200) {
                setReports(response.data);
            }
        } catch (error) {
            console.log(error);
        }
    }

    async function handleShow(item) {
        dispatch(getParameterByVehilce(item));
        setShowModal(true);
    }

    function convertToEST(unixTime) {
        const date = new Date(unixTime * 1000);
        const options = {
            timeZone: 'America/New_York',
            day: 'numeric',
            year: 'numeric',
            month: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric'
        };
        return date.toLocaleDateString('en-US', options);
    }

    // const unixTime = 1675143428;
    // const estDateTime = convertToEST(unixTime);

    useEffect(() => {
        getUserReports(userId)
    }, [])

    return (
        <>
            <div className="flex flex-col mb-20">
                <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="py-4 inline-block min-w-full sm:px-6 lg:px-8">
                        <div className="overflow-hidden">
                            <table className="min-w-full text-center">
                                <thead className="border-b bg-gray-50">
                                    <tr>
                                        <th scope="col" className="text-sm font-semibold text-gray-900 px-6 py-4">
                                            Business
                                        </th>
                                        <th scope="col" className="text-sm font-semibold text-gray-900 px-6 py-4">
                                            Store
                                        </th>
                                        <th scope="col" className="text-sm font-semibold text-gray-900 px-6 py-4">
                                            User
                                        </th>
                                        <th scope="col" className="text-sm font-semibold text-gray-900 px-6 py-4">
                                            Year
                                        </th>
                                        <th scope="col" className="text-sm font-semibold text-gray-900 px-6 py-4">
                                            Make
                                        </th>
                                        <th scope="col" className="text-sm font-semibold text-gray-900 px-6 py-4">
                                            Model
                                        </th>
                                        <th scope="col" className="text-sm font-semibold text-gray-900 px-6 py-4">
                                            Test date
                                        </th>
                                        <th scope="col" className="text-sm font-semibold text-gray-900 px-6 py-4">
                                            VIN
                                        </th>
                                        <th scope="col" className="text-sm font-semibold text-gray-900 px-6 py-4 text-end">
                                            View report
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {reports.length > 0 ? reports.filter((item) => {
                                        if (searchTerm === "") {
                                            return item;
                                        } else if (
                                            item?.VIN?.vin?.toString()
                                                .toLocaleLowerCase()
                                                .includes(searchTerm.toLocaleLowerCase())
                                        ) {
                                            return item;
                                        }
                                    }).map((item, index) => {
                                        return (
                                            <tr key={index} className="bg-white border-b">
                                                <td className="text-sm text-gray-900 font-normal px-6 py-4 whitespace-nowrap">
                                                    {/* {item?.generatedBy?.role === 'BusinessOwner' ? item?.generatedBy?.business?.businessName : ""} */}
                                                    {item.generatedBy.role === 'BusinessOwner' ? item.generatedBy.business.businessName :
                                                        item.generatedBy.role === 'StoreSupervisor' ? item.generatedBy.business.businessName :
                                                            item.generatedBy.role === 'StoreWorker' ? item.createdFor.store.business.businessName : ""}
                                                </td>
                                                <td className="text-sm text-gray-900 font-normal px-6 py-4 whitespace-nowrap">
                                                    {/* {item?.generatedBy?.role === 'StoreSupervisor' ? item?.generatedBy?.store?.storeName : ""} */}
                                                    {item.generatedBy.role === 'BusinessOwner' ? item.createdFor.store.storeName :
                                                        item.generatedBy.role === 'StoreSupervisor' ? item.generatedBy.store.storeName :
                                                            item.generatedBy.role === 'StoreWorker' ? item.generatedBy.store.storeName : ""
                                                    }
                                                </td>
                                                <td className="text-sm text-gray-900 font-normal px-6 py-4 whitespace-nowrap">
                                                    {/* {item?.generatedBy?.role === 'StoreWorker' || item?.generatedBy?.role === 'IndividualUser' ? item?.generatedBy?.firstName + " " + item?.generatedBy?.lastName : null} */}
                                                    {item.generatedBy.role === 'BusinessOwner' ? item.generatedBy.business.businessName :
                                                        item.generatedBy.role === 'StoreSupervisor' ? item.generatedBy.store.storeName :
                                                            item.generatedBy.role === 'StoreWorker' ? item.generatedBy.firstName + " " + item.generatedBy.lastName : ""
                                                    }
                                                </td>
                                                <td className="text-sm text-gray-900 font-normal px-6 py-4 whitespace-nowrap">
                                                    {item?.VIN?.year}
                                                </td>
                                                <td className="text-sm text-gray-900 font-normal px-6 py-4 whitespace-nowrap">
                                                    {item?.VIN?.VehicleMake?.make}
                                                </td>
                                                <td className="text-sm text-gray-900 font-normal px-6 py-4 whitespace-nowrap">
                                                    {item?.VIN?.VehicleModel?.model}
                                                </td>
                                                <td className="text-sm text-gray-900 font-normal px-6 py-4 whitespace-nowrap">
                                                    {convertToEST(item.testDate)}
                                                </td>
                                                <td className="text-sm text-gray-900 font-normal px-6 py-4 whitespace-nowrap">
                                                    {item.VIN?.vin || '-'}
                                                </td>
                                                <td className="text-sm text-gray-900 font-semibold px-6 py-4 whitespace-nowrap text-end">
                                                    <button onClick={() => handleShow(item)} className="bg-[#25C454] text-white py-2 px-3 rounded">
                                                        <span className='flex items-center justify-center'>
                                                            <FiEye className='mr-1.5' size={16} /> View report
                                                        </span>
                                                    </button>
                                                </td>

                                            </tr>
                                        );
                                    }) :
                                        <tr className='mt-5' >
                                            <td colSpan={5}>No Data Available!</td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

            </div>
            {/* Modal */}
            {showModal ? (
                <>
                    <div
                        className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-[1050] outline-none focus:outline-none"
                    >
                        <div style={{ height: '100%' }}>
                            <div className="relative w-auto my-6 mx-auto ">
                                <Modal setShowModal={setShowModal} />
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}
        </>
    )
}

export default UsersReports
