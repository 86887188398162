import { logout, reset } from 'features/auth/authSlice';
import React from 'react'
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom'

const Unauthorized = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onLogout = () => {
    dispatch(logout())
    dispatch(reset())
    navigate('/home')
  }

  return (

    <div className='flex items-center justify-center h-screen flex-col' style={{ backgroundImage: "url('images/individualLogin.png')", backgroundSize: 'cover' }}>

      <div class="max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">

        <h5 class="mb-5 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Unauthorized!</h5>

        <p class="mb-5 font-normal text-gray-700 dark:text-gray-400">
          Please complete the payment process!
        </p>

        <div className='flex items-center'>
          <Link to={'/payment'} class="inline-flex items-center px-2 py-2 text-sm font-medium text-center text-white bg-[#25C454] rounded-lg  focus:ring-4 focus:outline-none ">
            Go to Payment
            <svg aria-hidden="true" class="w-4 h-4 ml-2 -mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
          </Link>
          <button onClick={onLogout} class="ml-2 inline-flex items-center px-2 py-2 text-sm font-medium text-center border text-black bg-[#FFFFFF] rounded-lg  focus:ring-4 focus:outline-none ">
            Logout
          </button>
        </div>


      </div>

    </div>
  )
}

export default Unauthorized