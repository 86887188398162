import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import STATES from "../../states"
import { AiOutlineClose } from "react-icons/ai"
import { createStore, getStores } from 'features/store/storeSlice';
import { toast } from 'react-toastify';
import { reset } from 'features/report/reportSlice';
import { apiPOST } from 'utils/apiHelper';

const initialState = {
    "storeName": "",
    "ownerName": "",
    "email": "",
    "phoneNo": "",
    "password": "",
    // "shopNo": "",
    "country": "",
    "state": "",
    "address": "",
    "businessId": "",
}

const stringPattern = new RegExp("[a-zA-Z ]$");
const numberPattern = new RegExp("[0-9]$");
const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

const AddStoreModal = ({ setShowModal }) => {
    const dispatch = useDispatch()
    const { user } = useSelector((state) => state.auth);
    const { businessId } = user

    const [storeData, setStoreData] = useState(initialState);
    const [validPassword, setValidPassword] = useState(false);

    const {
        stores,
        isError,
        isSuccess,
        isLoading,
        message,
    } = useSelector((state) => state.store);

    // console.log({
    //     component: "Add Store:",
    //     stores,
    //     isError,
    //     isSuccess,
    //     isLoading,
    //     message,
    // });

    useEffect(() => {
        if (isError) {
            toast.error(isError);
        }

        return () => {
            dispatch(reset())
        }
    }, [isError])

    const onChangeStore = (e) => {

        if ((e.target.name === "storeName" && e.target.value) || (e.target.name === "ownerName" && e.target.value)) {
            if (stringPattern.test(e.target.value) == false) {
                return
            }
        }

        if (e.target.name === "phoneNo" && e.target.value) {
            if (numberPattern.test(e.target.value) == false) {
                return
            }
        }

        if (e.target.name === 'password' && e.target.value) {
            const isValidPassword = passwordRegex.test(e.target.value);
            console.log('isValidPassword: ', isValidPassword)
            setValidPassword(isValidPassword);
        };

        setStoreData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }))
    }

    const createStore = async (storeData) => {
        try {
            const response = await apiPOST(`/users/store-supervisor`, storeData);

            if (response.status !== 200) {
                toast.error(response?.data?.message || 'Something went wrong!');
            }

            if (response.status === 200) {
                toast.success("Store Supervisor Added!");
                dispatch(getStores(businessId));
            }

        } catch (error) {
            console.log(error);
            toast.error(error?.response?.data?.message || 'Something went wrong!')
        } finally {
            setStoreData(initialState);
            setShowModal(false);
        }
    }

    const handleAddStore = (e) => {
        e.preventDefault();
        storeData.businessId = businessId;
        console.log("StoreData ======= ", storeData);

        console.log("Business MyProfile: ", storeData);
        const allTruthy = Object.values(storeData).every(value => value);
        // console.log("allTruthy ====== ", allTruthy);

        if (!allTruthy) {
            return toast.error("Please fill all mandatory fields!");
        }

        if (storeData.email && emailRegex.test(storeData.email) === false) {
            return toast.error("Invalid Email!")
        }

        if (storeData.phoneNo && storeData.phoneNo.length !== 10) {
            return toast.error("Invalid Phone No!")
        }

        if (storeData.storeName && storeData.storeName.trim().length < 3) {
            return toast.error("Store Name not valid!")
        }

        if (storeData.ownerName && storeData.storeName.trim().length < 3) {
            return toast.error("Store Name not valid!")
        }

        if (storeData?.password && storeData?.password?.length > 0 && !validPassword) {
            return toast.error('Password should contain small, capital letter, number & special character!');
        }

        createStore(storeData);
    }

    return (
        <>
            <div
                className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
                <div className="relative w-auto my-3 mx-auto max-w-3xl">
                    {/*content*/}
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        {/*header*/}
                        <div className="flex items-center justify-between p-3 border-b border-solid border-slate-200 rounded-t">

                            <h3 className="text-2xl font-semibold">
                                Add Store
                            </h3>
                            <button
                                className="p-1 ml-auto bg-transparent border-0 text-black  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                onClick={() => setShowModal(false)}
                            >
                                <span className="bg-transparent  text-black  h-6 w-6 text-2xl block outline-none focus:outline-none">
                                    <AiOutlineClose color='#000000' />
                                </span>
                            </button>
                        </div>
                        {/*body*/}
                        <div className="relative flex-auto">
                            <div className="bg-[#F8F8F8] rounded p-[1.375rem]">
                                <form className="w-full" onSubmit={handleAddStore}>
                                    <div className="flex flex-wrap -mx-3 mb-3">
                                        <div className="w-full md:w-1/2 px-3 md:mb-0">
                                            <label className="required block  tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-first-name">
                                                Store Name
                                            </label>
                                            <input
                                                className="appearance-none block w-full bg-white  border-[#25C454]  border-none  dark:border-[#25C454] dark:focus:border-[#25C454]  text-gray-700  rounded py-3 px-4 mb-3 leading-tight  focus:outline-1 focus:bg-white"
                                                id="grid-first-name"
                                                type="text"
                                                name="storeName"
                                                //required
                                                value={storeData.storeName}
                                                onChange={onChangeStore}
                                                placeholder="Enter store name" />
                                        </div>
                                        <div className="w-full md:w-1/2 px-3">
                                            <label className="required block  tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name">
                                                Manager Name
                                            </label>
                                            <input
                                                className="appearance-none block w-full bg-white  border-[#25C454]  border-none  dark:border-[#25C454] dark:focus:border-[#25C454]  text-gray-700  rounded py-3 px-4 mb-3 leading-tight  focus:outline-1 focus:bg-white"
                                                id="grid-first-name"
                                                type="text"
                                                name="ownerName"
                                                //required
                                                value={storeData.ownerName}
                                                onChange={onChangeStore}
                                                placeholder="Enter owner name" />
                                        </div>
                                    </div>

                                    <div className="flex flex-wrap -mx-3 mb-3">
                                        <div className="w-full md:w-1/2 px-3  md:mb-0">
                                            <label className="required block  tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name">
                                                Phone Number
                                            </label>
                                            <div className="flex">
                                                <div className='bg-white rounded py-3 px-4 mb-3 mr-2'>
                                                    +1
                                                </div>
                                                <input
                                                    className="appearance-none block w-full bg-white  border-[#25C454]  border-none  dark:border-[#25C454] dark:focus:border-[#25C454]  text-gray-700  rounded py-3 px-4 mb-3 leading-tight  focus:outline-1 focus:bg-white"
                                                    id="grid-first-name"
                                                    type="text"
                                                    name="phoneNo"
                                                    //required
                                                    minLength={10}
                                                    maxLength={10}
                                                    value={storeData.phoneNo}
                                                    onChange={onChangeStore}
                                                    placeholder="Enter phone no" />
                                            </div>
                                        </div>

                                        <div className="w-full md:w-1/2 px-3">
                                            <label className="required block  tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name">
                                                Email
                                            </label>
                                            <input
                                                className="appearance-none block w-full bg-white  border-[#25C454]  border-none  dark:border-[#25C454] dark:focus:border-[#25C454]  text-gray-700  rounded py-3 px-4 mb-3 leading-tight  focus:outline-1 focus:bg-white"
                                                id="grid-first-name"
                                                type='email'
                                                name="email"
                                                //required
                                                value={storeData.email}
                                                onChange={onChangeStore}
                                                placeholder="Enter email" />
                                        </div>
                                    </div>

                                    <div className="flex flex-wrap -mx-3 mb-3">
                                        <div className="w-full md:w-1/2 px-3  md:mb-0">
                                            <label className="required block  tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name">
                                                Country
                                            </label>
                                            <select
                                                className="appearance-none block w-full bg-white  border-[#25C454]  border-none  dark:border-[#25C454] dark:focus:border-[#25C454]  text-gray-700  rounded py-3 px-4 mb-3 leading-tight  focus:outline-1 focus:bg-white"
                                                name='country'
                                                //required
                                                value={storeData.country}
                                                onChange={onChangeStore}
                                                id="businessCountry">
                                                <option value={""}>Select Country</option>
                                                <option value={'US'}>United States</option>
                                            </select>
                                        </div>

                                        <div className="w-full md:w-1/2 px-3  md:mb-0">
                                            <label className="required block  tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name">
                                                State
                                            </label>
                                            <select
                                                className="appearance-none block w-full bg-white  border-[#25C454]  border-none  dark:border-[#25C454] dark:focus:border-[#25C454]  text-gray-700  rounded py-3 px-4 mb-3 leading-tight  focus:outline-1 focus:bg-white"
                                                name='state'
                                                value={storeData.state}
                                                onChange={onChangeStore}
                                                //required
                                                id="businessCountry">
                                                <option value={""}>Select State</option>
                                                {STATES.map((item, index) => (
                                                    <option key={index} value={item.abbreviation}>{item.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>

                                    <div className="flex flex-wrap -mx-3 mb-3">
                                        <div className="w-full md:w-1/2 px-3  md:mb-0">
                                            <label className="required block  tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name">
                                                Address
                                            </label>
                                            {/* <input
                                                className="appearance-none block w-full bg-white  border-[#25C454]  border-none  dark:border-[#25C454] dark:focus:border-[#25C454]  text-gray-700  rounded py-3 px-4 mb-3 leading-tight  focus:outline-1 focus:bg-white"
                                                id="grid-first-name"
                                                type="text"
                                                name="shopNo"
                                                value={storeData.shopNo}
                                                //required
                                                minLength={10}
                                                maxLength={10}
                                                onChange={onChangeStore}
                                                placeholder="Enter shop no" /> */}
                                            <input
                                                className="appearance-none block w-full bg-white  border-[#25C454]  border-none  dark:border-[#25C454] dark:focus:border-[#25C454]  text-gray-700  rounded py-3 px-4 mb-3 leading-tight  focus:outline-1 focus:bg-white"
                                                id="grid-first-name"
                                                type="text"
                                                name='address'
                                                value={storeData.address}
                                                //required
                                                onChange={onChangeStore}
                                                placeholder="Enter address" />
                                        </div>
                                        <div className="w-full md:w-1/2 px-3 md:mb-0">
                                            <label className="required block  tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-first-name">
                                                Password
                                            </label>
                                            <input
                                                className="appearance-none block w-full bg-white  border-[#25C454]  border-none  dark:border-[#25C454] dark:focus:border-[#25C454]  text-gray-700  rounded py-3 px-4 mb-3 leading-tight  focus:outline-1 focus:bg-white"
                                                id="grid-first-name"
                                                type="password"
                                                name='password'
                                                value={storeData.password}
                                                //required
                                                onChange={onChangeStore}
                                                placeholder="Enter password" />
                                            {/* {storeData?.password && storeData?.password?.length > 0 && !validPassword ? (
                                                <small style={{ color: 'red' }}>Password should contain small, capital letter, number & special character</small>
                                            ) : null} */}
                                        </div>
                                    </div>
                                    {/* <div className="flex flex-wrap -mx-3 mb-3">
                                        <div className="w-full px-3 md:mb-0">
                                            <label className="required block  tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-first-name">
                                                Address
                                            </label>
                                            <input
                                                className="appearance-none block w-full bg-white  border-[#25C454]  border-none  dark:border-[#25C454] dark:focus:border-[#25C454]  text-gray-700  rounded py-3 px-4 mb-3 leading-tight  focus:outline-1 focus:bg-white"
                                                id="grid-first-name"
                                                type="text"
                                                name='address'
                                                value={storeData.address}
                                                //required
                                                onChange={onChangeStore}
                                                placeholder="Enter address" />
                                        </div>
                                    </div> */}

                                    <div className='flex items-center justify-end pt-2 border-t border-solid border-slate-200 rounded-b'>
                                        <button
                                            type='button'
                                            onClick={(e) => {
                                                e.preventDefault();
                                                console.log("Cancel Clicked!");
                                                setShowModal(false)
                                            }}
                                            className="bg-white rounded-[0.3125rem] py-2 px-3 border">Cancel</button>
                                        <button
                                            type='submit'
                                            // type='submit'
                                            // onClick={handleAddStore}
                                            className="ml-[0.5rem] bg-[#25C454] text-white rounded-[0.3125rem] py-2 px-3">
                                            Save
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
    )
}

export default AddStoreModal