import React from 'react'
import { useSelector } from 'react-redux';

const TestResults = () => {

    const { data } = useSelector((state) => state.report);
    const { reportData } = data
    const { endStatus, subStatus, maxTestCycle } = reportData;

    console.log("In Test Results");
    console.log({
     Component: "TestResults",
     endStatus,
       subStatus,
        maxTestCycle
    });

    function getTestConcludedText(endStatus, subStatus = 0, maxTestCycle = 0) {
        let result;
        switch (true) {
            case endStatus === 8 && subStatus === 3 && maxTestCycle === 0:
                result = "Pre-Test Observation";
                break;
            case endStatus === 8 && subStatus === 2 && maxTestCycle === 0:
                result = "Pre-Test Observation"
                break;

            
            case endStatus === 8 && [6,7,8,9].includes(subStatus) && maxTestCycle === 0:
                result = "Pre-Test Observation"
            break;

            case endStatus === 130 && [0,1,2].includes(subStatus)  && maxTestCycle === 5:
                result = `Test Drive Cycle # ${maxTestCycle}`;
                break;

            case endStatus === 30 && [0,1].includes(subStatus)  && maxTestCycle === 5:
                result = `Test Drive Cycle # ${maxTestCycle}`;
                break;

            case endStatus === 8 && subStatus === 2 && maxTestCycle> 0:
                    result = `Test Drive Cycle # ${maxTestCycle}`;
            break;
            case endStatus === 32 && subStatus === 0 && maxTestCycle === 0:
                result = `Test Terminated During Drive Cycle # ${maxTestCycle}`
                break;
            case endStatus === 33 && subStatus === 0 && maxTestCycle === 3:
                result = `Test Terminated During Drive Cycle # ${maxTestCycle}`
                break;
            case endStatus === 34: /* && subStatus === 0 && maxTestCycle === 2:*/
                result = `Test Terminated During Drive Cycle # ${maxTestCycle}`
                break;

            case endStatus === 29: /* && subStatus === 0 && maxTestCycle === 2:*/
                result = `Test Terminated During Drive Cycle # ${maxTestCycle}`
                break;
                
            default:
                result = "-";
        }
        return result;
    }

    function getDTCsLoggedText(endStatus, subStatus = 0, maxTestCycle = 0) {
        let result;
        switch (true) {
            case endStatus === 8 && subStatus === 3 && maxTestCycle === 0:
            case endStatus === 8 && subStatus === 2 && maxTestCycle === 0:
            case endStatus === 30 && subStatus === 0 && maxTestCycle === 5:
            case endStatus === 33 && subStatus === 0 && maxTestCycle === 3:
            case endStatus === 34 && subStatus === 0 && maxTestCycle === 2:
                result = `No`;
                break;
            case endStatus === 32 && subStatus === 0 && maxTestCycle === 0:
                result = `Yes`
                break;
            default:
                result = "No";
        }
        return result;
    }

    function getReasonForConclusionText(endStatus, subStatus = 0, maxTestCycle = 0) {
        let result;
        switch (true) {
            case endStatus === 8 && subStatus === 3 && maxTestCycle === 0:
                result = `The State of Charge level of the High Voltage Battery is outside the acceptable range for Power and Energy Health, and further testing is not required.`;
                break;
            case endStatus === 8 && subStatus === 2 && maxTestCycle === 0:
                result = `The voltage level of one or more modules or cells in the High Voltage Battery is below an acceptable leveland indicates substantial functional decline. Further testing is not required.`;
                break;


            case endStatus === 8 && subStatus === 6 && maxTestCycle === 0:
                    result = 'The voltage level of one or more modules or cells as well as the State of Charge level in the High Voltage Battery is below an acceptable level and indicates substantial functional decline. Further testing is not required.';
    
                    break;

            case endStatus === 8 && subStatus === 7 && maxTestCycle === 0:
                        result ='The voltage level of one or more modules or cells as well as the variation between the module or cell voltages in the High Voltage Battery is below an acceptable level and indicates substantial functional decline. Further testing is not required.'
                        
                        break;

            case endStatus === 8 && subStatus === 8 && maxTestCycle === 0:
                result =  'The variation between the module or cell voltages as well as the State of Charge level in the High Voltage Battery is below an acceptable level and indicates substantial functional decline. Further testing is not required.'
                break;

            case endStatus === 8 && subStatus === 9 && maxTestCycle === 0:
                result = 'The variation between the module or cell voltages, state of charge level as well as the voltage level of one or more modules or cells in the High Voltage Battery is below an acceptable level and indicates substantial functional decline. Further testing is not required.'
                
                break;


            case endStatus === 8 && subStatus === 2:
                    result = `The voltage level of one or more modules or cells in the High Voltage Battery is below an acceptable leveland indicates substantial functional decline. Further testing is not required.`;
                    break;
                    
            case endStatus === 30 && [0,1,7].includes(subStatus)  && maxTestCycle === 5:
                result = `All test drive cycles complete. Results shown below.`;
                break;

//maybe all endStatus 30 should display this message above case can be eliminated
                case endStatus === 30  && maxTestCycle === 5:
                    result = `All test drive cycles complete. Results shown below.`;
                    break;

                    case endStatus === 130  && maxTestCycle === 5:
                        result = `All test drive cycles complete. Results shown below.`;
                        break;
    

            case endStatus === 33 && subStatus === 0 && maxTestCycle === 3:
                result = `The Vehicle did not meet the minimum required speed or throttle position during acceleration. You must accelerate the vehicle with a wide open throttle for the duration of the guided 5 second countdown`;
                break;
            case endStatus === 34: /* && subStatus === 0 && maxTestCycle === 2:*/
                result = `The vehicle was not shifted into neutral before the brake pedal was pressed. You must firmly shift the vehicle into neutral prior to pressing the brake`;
                break;
            case endStatus === 32 && subStatus === 0 && maxTestCycle === 0:
                result = `DTCs have been found. Further testing of the High Voltage Battery should be delayed until these problems are resolved.`
                break;

            case endStatus === 29:
                  result = "The next Test Drive Cycle was not started within 20 seconds of decelerating during a previous Test Drive Cycle.";
                break;

            default:
                result = "-";
        }
        return result;
    }

    return (
        <div className="group-346-hnagnU">
            <div className="group-344-nNh4NL">
                <div className="test-conclusion-field-and-demo-input-4-c0GJvo">
                    <div className="dt-cs-logged-during-test-dv4yWR segoeui-semi-bold-black-24px">DTCs Logged During Test?</div>
                </div>
                <div className="test-conclusion-field-and-demo-input-2-c0GJvo">
                    <div className="reason-for-conclusion-6hQZBk segoeui-semi-bold-black-24px">Reason for Conclusion:</div>
                </div>
                <div className="test-conclusion-field-and-demo-input-1-c0GJvo">
                    <div className="test-concluded-during-5CR9yk segoeui-semi-bold-black-24px">
                        Test Concluded During:&nbsp;&nbsp;
                    </div>
                </div>

            </div>
            <div className="group-345-nNh4NL">
                <div className="test-drive-cycle-5-FhmZz6 segoeui-regular-normal-green-house-24px">
                    {getTestConcludedText(endStatus, subStatus ? subStatus : 0, maxTestCycle)}
                </div>
                <div className="no-FhmZz6 segoeui-regular-normal-green-house-24px">
                    {getDTCsLoggedText(endStatus, subStatus ? subStatus : 0, maxTestCycle)}
                </div>
                <div className="all-test-drive-cycles-complete-FhmZz6 segoeui-regular-normal-green-house-24px">
                    {getReasonForConclusionText(endStatus, subStatus ? subStatus : 0, maxTestCycle)}
                </div>
            </div>
        </div>
    )
}

export default TestResults
