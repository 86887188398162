import React from 'react'

const BatteryErrorBlock = ({ index }) => {
    return (
        <div
            style={{
                height: "46px",
                width: "399px",
                left: "0px",
                position: "relative",
                backgroundColor: "transparent",
            }}
        >
            <div className="group-340-gNxjvT">
                <img className="hv-battery-block-red-field"
                    //  src="/images/hv-battery-block-green-field-10@1x.png"
                    src={require("../../../assets/img/hv-battery-block-green-field-10@1x.png")}
                />
            </div>
            <div className="hv-battery-block-red-txt-icon">
                <div className="high-voltage-battery-block-03-DThcHj segoeui-regular-normal-black-24px">
                    {index < 9 ? (`${`High Voltage Battery Block 0${index + 1}`}`) : (`${`High Voltage Battery Block ${index + 1}`}`)}

                </div>
                <div className="icon-error-or-termination">
                    <div className="error-shape5-pKWbAG"></div>
                    <div className="error-shape4-pKWbAG"></div>
                    <img
                        className="error-shape3-pKWbAG"
                        src={require("../../../assets/img/error-shape3@1x.png")}
                    />
                    <img
                        className="error-shape2-pKWbAG"
                        src={require("../../../assets/img/error-shape2-10@1x.png")}
                    />
                </div>
            </div>
        </div>
    )
}

export default BatteryErrorBlock
