import { CardElement, useElements, useStripe, Elements } from "@stripe/react-stripe-js";
import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function CheckoutForm() {

    // collect data from the user
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [priceId, setPriceId] = useState("");
    const navigate = useNavigate();

    // stripe items
    const stripe = useStripe();
    const elements = useElements();


    const CARD_ELEMENT_OPTIONS = {
        style: {
            base: {
                'color': '#32325d',
                'fontFamily': '"Helvetica Neue", Helvetica, sans-serif',
                'fontSmoothing': 'antialiased',
                'fontSize': '16px',
                '::placeholder': {
                    color: '#aab7c4',
                },
            },
            invalid: {
                color: '#fa755a',
                iconColor: '#fa755a',
            },

        },
    };

    const handleSubmitSub = async (event) => {
        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }
        let result;
        try {
            result = await stripe.createPaymentMethod({
                type: 'card',
                card: elements.getElement(CardElement),
                billing_details: {
                    email: email,
                },
            });
        } catch (error) {
            console.log("PaymentMethod Error: ", error);

        }

        console.log("createPaymentMethod result: ", result);

        if (result.error) {
            console.log(result.error.message);
        } else {
            try {
                const res = await axios.post('http://localhost:5000/subscription', { 'payment_method': result.paymentMethod.id, 'email': email, name });
                // eslint-disable-next-line camelcase
                const { client_secret, status } = res.data;
                console.log("subscription Response: ", res.data);
                console.log("status ========= ", status)

                if (status === 'requires_action') {
                    stripe.confirmCardPayment(client_secret).then(function (result) {
                        console.log("confirmCardPayment repsonse: ", result);

                        if (result.error) {
                            console.log('There was an issue!');
                            console.log(result.error);
                            // Display error message in your UI.
                            // The card was declined (i.e. insufficient funds, card has expired, etc)
                        } else if (result?.paymentIntent.status === "succeeded") {
                            toast.success('Success! Please login')
                            navigate('/signup');
                        }
                    });
                } else {
                    console.log('You got the money!2');
                    // No additional information was needed
                    // Show a success message to your customer
                }
            } catch (error) {
                console.log("Subscription Error: ", error);
            }


        }
    };


    return (

        <div className="container">
            <input
                placeholder="Name"
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
            />

            <input
                placeholder="Email"
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
            />


            <CardElement options={CARD_ELEMENT_OPTIONS} />

            <button className="btn bg-black text-white px-2 py-3 mt-2" onClick={handleSubmitSub}>
                Subscribe
            </button>
        </div>

    );
}

export default CheckoutForm;