import { useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { Config } from "../../../config/index"
import { login, reset } from 'features/auth/authSlice';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

function BusinessLogin() {
    const [formData, setFormData] = useState({
        "email": "",
        "password": ""
    });

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { user, isLoading, isError, isSuccess, message } = useSelector(
        (state) => state.auth
    )

    const onChange = (e) => {
        e.persist();
        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }))
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        // console.log("handleSubmit: ", formData);
        if (!formData.email || !formData.password) {
            return toast.error("Please Enter Required Fields")
        }

        dispatch(login(formData));
    }

    useEffect(() => {
        if (isError) {
            // console.log('isError: ', isError);
            toast.error(message)
        }

        if (isSuccess || user) {
            navigate('/home')
        }

        dispatch(reset())
    }, [user, isError, isSuccess, message, navigate, dispatch])

    return (
        <>
            <div className="bg-white rounded-md">
                <form onSubmit={handleSubmit}>
                    <div className="mt-[2rem] mx-9">
                        <label className="text-left  font-semibold text-xs">Email</label>
                        <input
                           
                            type="email"
                            className="font-medium py-2 pl-2 text-xs bg-slate-50 w-full rounded-[0.375rem] border-x-0 border-t-0 border-b-2 border-b-[#25C454]"
                            id="businessId"
                            placeholder="Enter email"
                            name="email"
                            value={formData.email}
                            onChange={onChange}
                            required
                        />
                    </div>
                    <div className="mt-[0.4rem] mx-9">
                        <label className="text-left w-full font-semibold text-xs">Password</label>
                        <input
                            type="password"
                            className="font-medium py-2 pl-2 text-xs w-full bg-slate-50 rounded-[0.375rem] border-x-0 border-t-0 border-b-2 border-[#25C454]"
                            id="businessPswd"
                            placeholder="Enter password"
                            name="password"
                            value={formData.password}
                            required
                            onChange={onChange}
                        />
                    </div>
                    <div className="text-end mr-9">
                        <label className="justify-end items-end w-full font-semibold text-xs ">
                            <Link to="/reset-password">Forgot Password?</Link>
                        </label>
                    </div>
                    <div className="mx-9 mt-[2.5rem] pb-[2.5rem]">
                        <button
                            type="submit"
                            className="py-3 font-semibold w-full rounded-[0.375rem] bg-[#25C454] text-white text-base">
                            {isLoading ? 'Loading....' : 'Login'}
                        </button>
                    </div>
                </form>
            </div>
        </>
    )
}

export default BusinessLogin;