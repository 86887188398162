import React, { useState, useEffect } from 'react'
import { CardElement, useElements, useStripe, Elements } from "@stripe/react-stripe-js";
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import { Config } from "../config/index"
import { useDispatch, useSelector } from 'react-redux';
import { login, reset } from 'features/auth/authSlice';

const PaymentSuccess = () => {

    const navigate = useNavigate();

    return (
        <div className='flex items-center justify-center h-screen flex-col' style={{ backgroundImage: "url('images/individualLogin.png')", backgroundSize: 'cover' }}>

            <div class="w-full max-w-sm p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-6 md:p-8 dark:bg-gray-800 dark:border-gray-700">
                <form class="space-y-6" action="#">
                    <h5 class="text-center text-xl font-bold text-gray-900 dark:text-white">Congratulations!</h5>
                    <div>
                        <h6 class="text-center block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                            You hvae successfully completed the payment process!
                        </h6>

                    </div>

                    <button
                        type="submit"
                        onClick={() => navigate('/signup')}
                        class="w-full text-white bg-[#25C454]  focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                    >
                        Go to Login
                    </button>
                    <button
                        onClick={() => navigate('/home')}
                        class="w-full text-black bg-[#FFFFFF]  focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center border"
                    >
                        Go to Home
                    </button>
                </form>
            </div>

        </div>
    )
}

export default PaymentSuccess