import axios from 'axios'
import { toast } from 'react-toastify'
import { Config } from '../../config/index'
const API_URL = `${Config.API_URL}/store/`

// Create new store
const createStore = async (storeData, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    // const response = await axios.post(`${Config.API_URL}/store`, storeData, config)
    const response = await axios.post(`${Config.API_URL}/users/store-supervisor`, storeData, config)
    if (response.status === 200) {
        toast.success("Store Supervisor Added!");
    }
    return response.data
}

// Update store:
const updateStore = async (storeData, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    const response = await axios.put(`${Config.API_URL}/store/${storeData.id}`, storeData, config);
    return response.data
}

// Get user stores
const getStores = async (id, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    const response = await axios.get(`${Config.API_URL}/users/store-supervisor/business/${id}`, config)

    return response.data
}

// Delete store
// Todo: needs to change api endpoint
const deleteStore = async (goalId, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    const response = await axios.delete(API_URL + goalId, config)

    return response.data
}

const storeService = {
    createStore,
    updateStore,
    getStores,
    deleteStore,
}

export default storeService
