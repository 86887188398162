import React, { useRef, useState } from "react";
import html2canvas from 'html2canvas';
import { FiDownload } from "react-icons/fi"
import { jsPDF } from 'jspdf';
import "./css/globals.css";
//import "./css/reportConc.css";
import "./css/reportTerm.css";
import "./css/styleguide.css";
import LogoWithTitles from './report-sub-components/LogoWithTitles';
import VehicleDetail from './report-sub-components/VehicleDetail';
import TestResults from './report-sub-components/TestResults';
import GreenSOC from './report-sub-components/GreenSOC';
import YellowSOC from './report-sub-components/YellowSOC';
import RedSOC from './report-sub-components/RedSOC';
import Definition from './report-sub-components/Definition';
import BatteryVoltages from './report-sub-components/BatteryVoltages';
import { useDispatch, useSelector } from 'react-redux';
import { FaTimesCircle } from "react-icons/fa";
import moment from "moment";


const Report = ({ setShowModal }) => {
    const { data, isLoading, isError, isSuccess, message } = useSelector(
        (state) => state.report
    );

    console.log("In reports---->",data);

    const { reportData, reportParameters } = data
    const { batterySOC = 0, endStatus, subStatus, maxTestCycle} = data?.reportData;
    const { batteryVoltage } = data?.reportData;
    const { socThreshold } = reportParameters?.parameters;
    const printRef = React.useRef();
    const [loading, setLoding] = useState(false);

    var divLength="";

    console.log({
        component: "Report",
        reportData,
        reportParameters,
    })


    if (batteryVoltage) divLength=(1080+batteryVoltage.length*7)+'px'
    else divLength="1080px";

    console.log("divLength: ",divLength);

    /*

    Original print - but will not paginate
    const handleDownloadPdf = async () => {
        // TODO: logic
        setLoding(true);
        const element = printRef.current;
        //printref.current
        window.scrollTo(0,0)
        const canvas = await html2canvas(element);
        const data = canvas.toDataURL('image/png');

        const pdf = new jsPDF();
        const imgProperties = pdf.getImageProperties(data);
        const pdfWidth = pdf.internal.pageSize.getWidth();
      //  const pdfHeight =(imgProperties.height * pdfWidth) / imgProperties.width;
      const pdfHeight =(imgProperties.height/pdfWidth)*8;
        pdf.addImage(data, 'PNG', 0, 0, pdfWidth, pdfHeight);
        pdf.save('print.pdf');
        setLoding(false);
    };
*/


const handleDownloadPdf = async () => {
    // TODO: logic
    setLoding(true);
    const element = printRef.current;
    window.scrollTo(0,0)
    const canvas = await html2canvas(element);
    const imgData = canvas.toDataURL('image/png');

    var imgWidth = 210;
    var pageHeight = 295;
    var imgHeight = canvas.height * imgWidth / canvas.width;
    var heightLeft = imgHeight;

    var doc = new jsPDF('p', 'mm');
    var position = 0;

    doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
    heightLeft -= pageHeight;

    while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

   
    doc.save('print.pdf');
    setLoding(false);
};




    const handleDownloadImage = async () => {
        const element = printRef.current;
        const canvas = await html2canvas(element);

        const data = canvas.toDataURL('image/jpg');
        const link = document.createElement('a');

        if (typeof link.download === 'string') {
            link.href = data;
            link.download = 'image.jpg';

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            window.open(data);
        }
    };

    // if (isLoading) {
    //     return
    // }

    return (
        <>
          
            <div style={{ margin: "0", background: "#ffffff" }}>
                
           
                 
               
                <div className="flex justify-end">
                    <button onClick={() => setShowModal(false)} className="py-2 px-4">
                        <FaTimesCircle color='grey' size={25} />
                    </button>
                </div>
                

              
              

                <div id="report" style={{height:divLength}} ref={printRef}>
                    <div className="container-center-horizontal" >
                    <div id="mockup-a" className="mockup-a"  >
                        <div className="group-339-hnagnU">
                            <LogoWithTitles />
                            <VehicleDetail />
                        </div>
                        <img
                            className="line-601-hnagnU"
                            src={require("../../assets/img/line-601@1x.png")}
                        />
                        <TestResults />
                        {
                        //(endStatus === 8 || endStatus === 30) && (subStatus === 9 || subStatus === 6 || subStatus === 3 || subStatus === 2 || maxTestCycle === 5) ? (
                          
                        (endStatus === 8 || endStatus === 30 || endStatus===130 || endStatus===108) && ([6,7,8,9,3,2,1].includes(subStatus) || maxTestCycle === 5) ? (
                           <div className="group-402-hnagnU">
                                <div className="high-voltage-battery-y8xzcO segoeui-semi-bold-green-house-28px">
                                    High Voltage Battery State of Health Snapshot
                                </div>
                                {parseFloat(batterySOC) < parseFloat(socThreshold) ? <RedSOC /> : <GreenSOC />}
                                <Definition />
                                <div className="module-or-cell-voltages-y8xzcO segoeui-semi-bold-black-28px">Module or Cell Voltages</div>
                                <div className="energy-capacity-y8xzcO segoeui-semi-bold-black-28px">Energy Capacity</div>
                                <img
                                    className="line-610-y8xzcO"
                                    src={require("../../assets/img/line-601@1x.png")}
                                />
                                <BatteryVoltages />
                            </div>
                        ) : null}
                    </div>
                    </div>

                  
                    </div>

                    <div className='flex justify-end items-end p-4'>
                         
                         <button
                             className='btn flex justify-end items-center py-2 px-3 bg-[#25C454] text-white rounded'
                             onClick={handleDownloadPdf}>
                             <FiDownload size={18} className='mr-1' />
                             {loading ? 'Downloading...' : 'Download'}</button>
                     
                     </div>
            
           
            </div>
        </>
    )

}

export default Report
