import React from 'react'

const BatterySuccessBlock = ({ index }) => {
    return (
        <div
            style={{
                height: "46px",
                width: "399px",
                left: "0px",
                position: "relative",
                backgroundColor: "transparent"
            }}
        >
            <div className="group-341-ttnn8p">
                <div className="group-340-gNxjvT">
                    <div className="hv-battery-block-green-field-qho7s4"></div>
                </div>
                <div className="hv-battery-block-green-txt-icon-gNxjvT">
                    <div className="high-voltage-battery-block-03-DThcHj segoeui-regular-normal-black-24px">
                        High Voltage Battery Block {index + 1}
                    </div>
                    <div className="icon-success-or-conclusion-DThcHj">
                        <div className="success-or-conclusion-shape2-kdORIa"></div>
                        <div className="success-or-conclusion-shape1-kdORIa"></div>
                        <img className="icon-check_mark-kdORIa"
                            // src="/images/check-10@1x.png"
                            src={require("../../../assets/img/check-10@1x.png")}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BatterySuccessBlock
