import './App.css';
import MyRouter from "../src/routers/index.js"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { loadStripe } from "@stripe/stripe-js";
import { Provider } from 'react-redux'


import { CardElement, useElements, useStripe, Elements } from "@stripe/react-stripe-js";
const stripePromise = loadStripe("pk_test_51MlVkrDyh1Bqa9krhmwMJl4hBrd0FAreO9tWbt8ZENRV8mZpLeJhwLxbC8fH6vEgg2Xy8HBLTUoFBjebxbVoE2OJ00dD9FEA8p");

function App() {
  return (
    <div className="bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">
      <Elements stripe={stripePromise}>
        <MyRouter />
        <ToastContainer autoClose={1500} />
      </Elements>
    </div>
  );
}

export default App;
