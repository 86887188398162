import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import STATES from '../../states';
import { apiDELETE, apiGET, apiPUT } from '../../utils/apiHelper';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { logout, reset } from 'features/auth/authSlice';

const stringPattern = new RegExp("[a-zA-Z ]$");
const numberPattern = new RegExp("[0-9]$");
const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

const initialState = {
    "firstName": "",
    "lastName": "",
    "email": "",
    "phoneNo": "",
    "country": "",
    "state": "",
    "address": "",
    "role": "",
    "storeId": ""
}

const StoreUserProfile = () => {
    const { id: userId } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth);

    const [stores, setStores] = useState([]);
    const [storeWorker, setStoreWorker] = useState(initialState)
    const [password, setPassword] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [validPassword, setValidPassword] = useState(false);
    const [hasChanged, setHasChanged] = useState(false);

    async function getStoreWorker(userId) {
        try {
            const res = await apiGET(`/users/store-user/userId/${userId}`);
            if (res.status === 200) {
                console.log("Store Worker: ", res.data);
                setStoreWorker({
                    firstName: res.data.firstName,
                    lastName: res.data.lastName,
                    email: res.data.email,
                    phoneNo: res.data.phoneNo,
                    country: res.data.country,
                    state: res.data.state,
                    address: res.data.address,
                    role: res.data.role,
                    storeId: res.data.storeId
                });
            }
        } catch (error) {
            console.log(error);
        }
    }

    const onChange = (e) => {
        if ((e.target.name === "firstName" && e.target.value) || (e.target.name === "lastName" && e.target.value)) {
            if (stringPattern.test(e.target.value) == false) {
                return
            }
        }

        if (e.target.name === "phoneNo" && e.target.value) {
            if (numberPattern.test(e.target.value) == false) {
                return
            }
        }

        if (e.target.name === 'password' && e.target.value) {
            const isValidPassword = passwordRegex.test(e.target.value);
            console.log('isValidPassword: ', isValidPassword)
            setValidPassword(isValidPassword);
        };

        setStoreWorker((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }))

        setHasChanged(true);
    }

    const hanldeUpdate = async (e) => {
        e.preventDefault();

        // if (password) {
        //     storeWorker.password = password
        // }
        // console.log('Update storeWorker ============= ', storeWorker);

        const allTruthy = Object.values(storeWorker).every(value => value);
        if (!allTruthy) {
            return toast.error("Please fill all mandatory fields!");
        }

        if (storeWorker.email && emailRegex.test(storeWorker.email) === false) {
            return toast.error("Invalid Email!")
        }

        if (storeWorker.phoneNo && storeWorker.phoneNo.length !== 10) {
            return toast.error("Invalid Phone No!")
        }

        if (storeWorker.firstName && storeWorker.firstName.trim().length < 3) {
            return toast.error("First name not valid!")
        }

        if (storeWorker.lastName && storeWorker.firstName.trim().length < 3) {
            return toast.error("Last name not valid!")
        }

        if (storeWorker?.password && storeWorker?.password?.length > 0 && !validPassword) {
            return toast.error('Password should contain small, capital letter, number & special character!');
        }

        try {
            const res = await apiPUT(`/users/store-user/${userId}`, storeWorker);

            if (res.status === 200) {
                toast.success("Profile Updated!");
                setHasChanged(false);
            }

            if (res.status !== 200) {
                const msg = res?.data?.message ? res?.data?.message : 'Something went wrong!'
                toast.error(msg)
            }

        } catch (error) {
            toast.error("Error! Update faield");
            console.log(error);
        }
    }

    async function handleDeleteUser(e) {
        e.preventDefault();
        setIsLoading(true);
        try {
            const response = await apiDELETE(`/users/store-user/${userId}`);
            if (response.status === 200) {
                toast.success(response?.data?.message);
                if (user.role === 'BusinessOwner' || user.role === 'StoreSupervisor') {
                    navigate(-1);
                } else {
                    dispatch(logout());
                    dispatch(reset())
                    navigate('/home')
                }
            }

            if (response.status !== 200) {
                console.log(response.data)
                toast.error(response?.data?.message);
            }
        } catch (error) {
            console.log(error);
            toast.error(error?.response?.data?.message || 'Something went wrong!')
        } finally {
            setIsLoading(false);
        }
    }

    async function getStores() {
        try {
            const res = await apiGET(`/users/store-supervisor`);
            if (res.status === 200) {
                setStores(res.data);
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getStores();
        getStoreWorker(userId)
    }, [userId])

    const isDisabled = !hasChanged;

    return (
        <div className="bg-[#F8F8F8] rounded p-[1.375rem] mb-20">
            <form className="w-full" onSubmit={hanldeUpdate}>
                <div className="w-full  mb-5">
                    <div className="flex justify-between">
                        <div><span className="font-bold">User Details</span></div>
                        <div className="">
                            <button
                                onClick={(e) => {
                                    e.preventDefault();
                                    console.log("Clicked!")
                                }}
                                type='button'
                                className="bg-white rounded-[0.3125rem] py-2 px-3">Cancel</button>
                            <button
                                type='submit'
                                disabled={isDisabled}
                                className={`ml-[0.5rem] bg-[#25C454] text-white rounded-[0.3125rem] py-2 px-3 ${isDisabled && 'opacity-75'}`}>
                                Save
                            </button>
                            {/* <button
                                onClick={(e) => handleDeleteUser(e)}
                                type='button'
                                className="bg-[#FF0000] text-white rounded-[0.3125rem] ml-5 py-2 px-3">
                                {isLoading ? 'Deleting...' : 'Delete Account'}
                            </button> */}
                        </div>
                    </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-3">
                    <div className="w-full md:w-1/2 px-3 md:mb-0">
                        <label className="block required tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-first-name">
                            First Name
                        </label>
                        <input
                            className="appearance-none block w-full bg-white  border-[#25C454]  border-none  dark:border-[#25C454] dark:focus:border-[#25C454]  text-gray-700  rounded py-3 px-4 mb-3 leading-tight  focus:outline-1 focus:bg-white"
                            id="grid-first-name"
                            type="text"
                            name="firstName"
                            value={storeWorker.firstName}
                            onChange={onChange}
                            // required
                            placeholder="Enter first name" />
                    </div>
                    <div className="w-full md:w-1/2 px-3">
                        <label className="block required tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name">
                            Last Name
                        </label>
                        <input
                            className="appearance-none block w-full bg-white  border-[#25C454]  border-none  dark:border-[#25C454] dark:focus:border-[#25C454]  text-gray-700  rounded py-3 px-4 mb-3 leading-tight  focus:outline-1 focus:bg-white"
                            id="grid-first-name"
                            type="text"
                            name="lastName"
                            value={storeWorker.lastName}
                            onChange={onChange}
                            // required
                            placeholder="Enter last name" />
                    </div>
                </div>

                <div className="flex flex-wrap -mx-3 mb-3">
                    <div className="w-full md:w-1/2 px-3  md:mb-0">
                        <label className="block required tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name">
                            Phone Number
                        </label>
                        <div className="flex">
                            <div className='bg-white rounded py-3 px-4 mb-3 mr-2'>
                                +1
                            </div>
                            <input
                                className="appearance-none block w-full bg-white  border-[#25C454]  border-none  dark:border-[#25C454] dark:focus:border-[#25C454]  text-gray-700  rounded py-3 px-4 mb-3 leading-tight  focus:outline-1 focus:bg-white"
                                id="grid-first-name"
                                type="text"
                                name="phoneNo"
                                value={storeWorker.phoneNo}
                                onChange={onChange}
                                // required
                                minLength={10}
                                maxLength={10}
                                placeholder="Enter phone no" />
                        </div>
                    </div>

                    <div className="w-full md:w-1/2 px-3">
                        <label className="block required tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name">
                            Email
                        </label>
                        <input
                            className="appearance-none block w-full bg-white  border-[#25C454]  border-none  dark:border-[#25C454] dark:focus:border-[#25C454]  text-gray-700  rounded py-3 px-4 mb-3 leading-tight  focus:outline-1 focus:bg-white"
                            id="grid-first-name"
                            type="email"
                            name="email"
                            value={storeWorker.email}
                            onChange={onChange}
                            placeholder="Enter email"
                        // required
                        />
                    </div>
                </div>

                <div className="flex flex-wrap -mx-3 mb-3">
                    <div className="w-full md:w-1/2 px-3  md:mb-0">
                        <label className="block required tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name">
                            Country
                        </label>
                        <select
                            className="appearance-none block w-full bg-white  border-[#25C454]  border-none  dark:border-[#25C454] dark:focus:border-[#25C454]  text-gray-700  rounded py-3 px-4 mb-3 leading-tight  focus:outline-1 focus:bg-white"
                            name='country'
                            value={storeWorker.country}
                            onChange={onChange}
                            // required
                            id="storeWorker">
                            <option value={""}>Select Country</option>
                            <option value={'US'}>United States</option>
                        </select>
                    </div>

                    <div className="w-full md:w-1/2 px-3  md:mb-0">
                        <label className="block required tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name">
                            State
                        </label>
                        <select
                            className="appearance-none block w-full bg-white  border-[#25C454]  border-none  dark:border-[#25C454] dark:focus:border-[#25C454]  text-gray-700  rounded py-3 px-4 mb-3 leading-tight  focus:outline-1 focus:bg-white"
                            name='state'
                            value={storeWorker.state}
                            onChange={onChange}
                            // required
                            id="businessCountry"
                        >
                            <option value={""}>Select State</option>
                            {STATES.map((item, index) => (
                                <option key={index} value={item.abbreviation}>{item.name}</option>
                            ))}
                        </select>
                    </div>
                </div>

                <div className="flex flex-wrap -mx-3 mb-3">
                    <div className="w-full md:w-1/2 px-3  md:mb-0">
                        <label className="block required tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name">
                            Store Name
                        </label>
                        <select
                            className="appearance-none block w-full bg-white  border-[#25C454]  border-none  dark:border-[#25C454] dark:focus:border-[#25C454]  text-gray-700  rounded py-3 px-4 mb-3 leading-tight  focus:outline-1 focus:bg-white"
                            name='storeId'
                            value={storeWorker.storeId}
                            onChange={onChange}
                            disabled
                            // required
                            id="businessCountry">
                            <option value={""}>Select Store</option>
                            {stores.map((item, index) => (
                                <option key={index} value={item.storeId}>{item?.store?.storeName}</option>
                            ))}
                        </select>
                    </div>
                    <div className="w-full md:w-1/2 px-3 md:mb-0">
                        <label className="block  tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-first-name">
                            Change Password
                        </label>
                        <input
                            className="appearance-none block w-full bg-white  border-[#25C454]  border-none  dark:border-[#25C454] dark:focus:border-[#25C454]  text-gray-700  rounded py-3 px-4 mb-3 leading-tight  focus:outline-1 focus:bg-white"
                            id="grid-first-name"
                            type="text"
                            name='password'
                            value={storeWorker?.password}
                            onChange={onChange}
                            // onChange={(e) => setPassword(e.target.value)}
                            // required
                            placeholder="Enter password" />
                        {storeWorker?.password && storeWorker?.password?.length > 0 && !validPassword ? (
                            <small style={{ color: 'red' }}>Password should contain small, capital letter, number & special character</small>
                        ) : null}
                    </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-3">
                    <div className="w-full px-3 md:mb-0">
                        <label className="block required tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-first-name">
                            Address
                        </label>
                        <input
                            className="appearance-none block w-full bg-white  border-[#25C454]  border-none  dark:border-[#25C454] dark:focus:border-[#25C454]  text-gray-700  rounded py-3 px-4 mb-3 leading-tight  focus:outline-1 focus:bg-white"
                            id="grid-first-name"
                            type="text"
                            name='address'
                            value={storeWorker.address}
                            // required
                            onChange={onChange}
                            placeholder="Enter address" />
                    </div>
                </div>
            </form>

        </div>
    )
}

export default StoreUserProfile