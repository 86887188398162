import React, { useEffect, useState } from 'react'

// import Reports from './Reports';
// import MyProfile from './MyProfile';
import { MdOutlineLogout } from "react-icons/md"
import { AiOutlineArrowRight } from "react-icons/ai"
import { logout, reset } from 'features/auth/authSlice';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Config } from "../../config"
import axios from 'axios';
import StoreProfile from './StoreProfile';
import StoreUsers from './StoreUsers';
import Report from 'components/Reports/Report';
import UsersReports from './UsersReports';
import { apiGET } from 'utils/apiHelper';
// import Stores from './Stores';

const StoreAccount = () => {
    const [activeTab, setActiveTab] = useState("users-reports");
    const [searchTerm, setSearchTerm] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [data, setData] = useState({});
    const { id: storeSupervisorId } = useParams();
    const { user } = useSelector((state) => state.auth);

    const expiredOn = new Date(user?.expiresOn * 1000);

    const dispatch = useDispatch();

    const onLogout = () => {
        dispatch(logout())
        dispatch(reset())
    }

    async function getStore(storeUserId) {
        try {
            const res = await apiGET(`/users/store-supervisor/${storeSupervisorId}`);
            if (res.status === 200) {
                console.log("Store Supervisor Response =========== ", res.data)
                setData(res.data)
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getStore(storeSupervisorId);
    }, [storeSupervisorId]);

    return (
        <div className=''>
            {/* Title - Header */}
            <div className='border-b-2 '>
                <div className="container">
                    <div className='flex justify-between items-center mb-2'>
                        <div className='title'>
                            <p className="text-2xl font-semibold">Store Account</p>
                            {/* <p className="text-xs font-normal">You can see here all details.</p> */}
                        </div>

                        <div className='logout'>
                            <div className='flex items-center justify-center ' >
                                {expiredOn ? <div className='border mr-2 py-2 px-5 rounded'><strong>Expires on:</strong> <span>{moment(expiredOn).format("MMM-DD-YYYY")}</span></div> : null}
                                {user?.role === 'BusinessOwner' && <Link to={"/business"} className='flex items-center bg-green-500 justify-center text-white py-2 px-5 rounded mr-2'>Go to Business<AiOutlineArrowRight className='ml-2' /></Link>}
                                <button onClick={onLogout} className='flex items-center justify-center text-white py-2 px-5 rounded' style={{ backgroundColor: "#FF0606" }}>Logout <MdOutlineLogout className='ml-2' /></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container">
                {/* TABS */}
                <div className='flex flex-row justify-between py-3 xms-col'>
                    <div className='flex tabs'>
                        <div className='reports-tab'>
                            <button onClick={() => setActiveTab("users-reports")} className={` ${activeTab === "users-reports" ? 'text-white py-2 px-5 rounded bg-green-500 ' : 'text-black py-2 px-5 rounded '} font-medium`}>
                                Users Reports
                            </button>
                        </div>
                        <div className='stores-tab'>
                            <button onClick={() => setActiveTab("store-users")} className={` ${activeTab === "store-users" ? 'text-white py-2 px-5 rounded bg-green-500 ' : 'text-black py-2 px-5 rounded '} font-medium`}>
                                Store  Users
                            </button>
                        </div>
                        <div className='profile-tab'>
                            <button onClick={() => setActiveTab("storeProfile")}
                                // className=' text-black py-2 px-5 rounded  mr-2`
                                className={` ${activeTab === "storeProfile" ? 'text-white py-2 px-5 rounded bg-green-500' : 'text-black py-2 px-5 rounded '} mr-2 font-medium`}

                            >Store Profile</button>
                        </div>

                    </div>
                    {activeTab === 'users-reports' ? (
                        <div className="searchbar w-72 xms-col-mt">
                            <form>
                                <label htmlFor="default-search" className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-gray-300">Search</label>
                                <div className="relative">
                                    <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                                        <svg aria-hidden="true" className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                                    </div>
                                    <input
                                        type="search"
                                        id="default-search"
                                        className="block p-2 pl-10 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        placeholder="Search VIN"
                                        required
                                        onChange={(event) => {
                                            setSearchTerm(event.target.value);
                                        }}
                                    />
                                </div>
                            </form>
                        </div>
                    ) : null}

                    {activeTab === "store-users" ? (
                        <button
                            onClick={() => setShowModal(true)}
                            className={'text-white py-2 px-5 rounded bg-green-500 font-medium'}>Add Store User</button>
                    ) : null}
                </div>

                {activeTab === "storeProfile" ? (
                    <StoreProfile data={data} />
                ) : activeTab === "store-users" ? (
                    <StoreUsers storeId={data?.storeId} showModal={showModal} setShowModal={setShowModal} />
                ) : activeTab === "users-reports" ? (
                    <UsersReports searchTerm={searchTerm} />
                ) : null}
            </div>
        </div>
    )
}

export default StoreAccount
