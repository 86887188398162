import React from "react"
import { useTable } from 'react-table'

const DATA = [
    {
        "id": 1,
        "vehicale_number": "21695-121",
        "test_date": "05/08/2022",
        "owner_name": "Ewart Thynn",
        "phone_number": "549-402-0298"
    },
    { "id": 2, "vehicale_number": "48951-1092", "test_date": "01/23/2022", "owner_name": "Trista Elstob", "phone_number": "601-937-5813" },
    { "id": 3, "vehicale_number": "76472-1145", "test_date": "08/02/2022", "owner_name": "Emmalyn Libbis", "phone_number": "430-804-9202" },
    { "id": 4, "vehicale_number": "45802-237", "test_date": "08/07/2022", "owner_name": "Thaddeus Tzarkov", "phone_number": "682-354-1942" },
];

function TestTable() {
    const data = React.useMemo(
        () => [
            {
                col1: 'Hello',
                col2: 'World',
            },
            {
                col1: 'react-table',
                col2: 'rocks',
            },
            {
                col1: 'whatever',
                col2: 'you want',
            },
        ],
        []
    )

    const columns = React.useMemo(
        () => [
            {
                Header: 'Column 1',
                accessor: 'vehicale_number', // accessor is the "key" in the data
            },
            {
                Header: 'Column 2',
                accessor: 'test_date',
            },
        ],
        []
    )

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({ columns, DATA })

    return (
        <div className="flex flex-col mb-20">
            <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-4 inline-block min-w-full sm:px-6 lg:px-8">
                    <div className="overflow-hidden">
                        <table className="min-w-full text-center" {...getTableProps()} style={{ border: 'solid 1px blue' }}>
                            <thead>
                                {headerGroups.map(headerGroup => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map(column => (
                                            <th
                                                {...column.getHeaderProps()}
                                                style={{
                                                    borderBottom: 'solid 3px red',
                                                    background: 'aliceblue',
                                                    color: 'black',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                {column.render('Header')}
                                            </th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>
                            <tbody {...getTableBodyProps()}>
                                {rows.map(row => {
                                    prepareRow(row)
                                    return (
                                        <tr {...row.getRowProps()}>
                                            {row.cells.map(cell => {
                                                return (
                                                    <td
                                                        {...cell.getCellProps()}
                                                        style={{
                                                            padding: '10px',
                                                            border: 'solid 1px gray',
                                                            background: 'papayawhip',
                                                        }}
                                                    >
                                                        {cell.render('Cell')}
                                                    </td>
                                                )
                                            })}
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default TestTable