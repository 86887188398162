import React from 'react'
import { useSelector } from 'react-redux';

const LogoWithTitles = ({ title }) => {

    const { data } = useSelector(
        (state) => state.report
    );

    const { reportData } = data
    const { endStatus, subStatus, maxTestCycle } = reportData;

    // console.log({
    //     Component: "LogoWithTitles",
    //     reportData,
    //     endStatus,
    //     subStatus,
    //     maxTestCycle
    // })

    console.log({
        endStatus, subStatus, maxTestCycle
    })

    function getTitle(endStatus, subStatus = 0, maxTestCycle = 0) {
        let result;
        switch (true) {
            case endStatus === 8 && subStatus === 3 && maxTestCycle === 0:
            case endStatus === 8 && subStatus === 2 && maxTestCycle === 0:
            case endStatus === 8 && subStatus === 2:
            case endStatus === 30 && subStatus === 0 && maxTestCycle === 5:
            case endStatus === 30 &&  maxTestCycle === 5:
                
                result = "Test Conclusion Report";
                break;
            case endStatus === 32 && subStatus === 0 && maxTestCycle === 0:
            case endStatus === 33 && subStatus === 0 && maxTestCycle === 3:
            case endStatus === 34 && subStatus === 0 && maxTestCycle === 2:
            case endStatus === 34 && subStatus ===0:
                result = "Test Termination Report";
                break;
            default:
                result = "Test Termination Report";
        }
        return result;
    }

    return (
        <div className="group-335-kuqdKP">
            <div className="high-voltage-battery-8lx8ID segoeui-regular-normal-green-house-28px">
                High Voltage Battery <br /> {getTitle(endStatus, subStatus ? subStatus : 0, maxTestCycle)}
            </div>
            <div className="logo-med-bat-w-txt-8lx8ID">
                <img
                    className="battscan-logo-nfT4bA"
                    alt='battscan-logo'
                    // src="/images/battscan-logo@1x.png"
                    src={require("../../../assets/img/battscan-logo@1x.png")}
                />
            </div>
        </div>
    )
}

export default LogoWithTitles