import React from 'react'

const Definition = () => {
    return (
        <div className="group-386-y8xzcO">
            <div className="hv-battery-block-green-txt-icon-h361c6">
                <div className="icon-success-or-conclusion-SVWbOf">
                    <div className="success-or-conclusion-shape2-uC7SWF"></div>
                    <div className="success-or-conclusion-shape1-uC7SWF"></div>
                    <img
                        className="icon-check_mark-uC7SWF"
                        src={require("../../../assets/img/check-2@1x.png")}
                    />
                </div>
                <div className="battery-so-c-or-modu-SVWbOf segoeui-regular-normal-black-24px">
                    Battery SoC or Module/Cell Voltage<br />does not exhibit aging or decline
                </div>
            </div>
            <div className="hv-battery-block-yellow-txt-icon-h361c6">
                <div className="icon-caution-or-monitor-70XjNM">
                    <div className="caution-or-monitor-shape3-Fa34Mw"></div>
                    <div className="caution-or-monitor-shape2-Fa34Mw"></div>
                    <img
                        className="caution-or-monitor-shape1-Fa34Mw"
                        src={require("../../../assets/img/caution-or-monitor-shape1@1x.png")}
                    />
                </div>
                <div className="battery-so-c-or-modu-70XjNM segoeui-regular-normal-black-24px">
                    Battery SoC or Module/Cell Voltage<br />is exhibiting moderate decline
                </div>
            </div>
            <div className="hv-battery-block-red-txt-icon-h361c6">
                <div className="icon-error-or-termination-Px6aH0">
                    <div className="error-shape5-LqH7br"></div>
                    <div className="error-shape4-LqH7br"></div>
                    <img
                        className="error-shape3-LqH7br"
                        src={require("../../../assets/img/error-shape3@1x.png")}
                    />
                    <img
                        className="error-shape2-LqH7br"
                        src={require("../../../assets/img/error-shape2@1x.png")}
                    />
                </div>
                <div className="battery-so-c-or-modu-Px6aH0 segoeui-regular-normal-black-24px">
                    Battery SoC or Module/Cell Voltage<br />is exhibiting functional decline
                </div>
            </div>
            <div className="definitions-h361c6 segoeui-regular-normal-black-28px">Definitions</div>
            <img
                className="line-604-h361c6"
                src={require("../../../assets/img/line-604@1x.png")}
            />
            <img
                className="line-608-h361c6"
                src={require("../../../assets/img/line-604@1x.png")}
            />
        </div>
    )
}

export default Definition
