import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import reportService from './reportService'

const initialState = {
    data: {},
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: '',
}

// Get Parameters based on vehilce params:
export const getParameterByVehilce = createAsyncThunk(
    'report/getParameterByVehilce',
    async (item, thunkAPI) => {
        const token = thunkAPI.getState().auth.user.jwtToken
        const {
            makeEnumeration,
            modelEnumeration,
            year
        } = item?.VIN

        const vehicle_params = {
            "make": makeEnumeration,
            "model": modelEnumeration,
            "year": year
        }
        try {
            return await reportService.getParametersByVehicle(vehicle_params, token, item)
        } catch (error) {
            const message =
                (error.response && error.response.data && error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const reportSlice = createSlice({
    name: 'report',
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false
            state.isSuccess = false
            state.isError = false
            state.message = ''
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getParameterByVehilce.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getParameterByVehilce.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.data = action.payload
            })
            .addCase(getParameterByVehilce.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
    },
})

export const { reset } = reportSlice.actions
export default reportSlice.reducer
