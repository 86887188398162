import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import BusinessLogin from './BusinessAccount/BusinessLogin';
import BusinessSignup from './BusinessAccount/BusinessSignup';
import IndividualLogin from './IndividualAccount/IndividualLogin';
import IndividualSignup from './IndividualAccount/IndividualSignup';

function ProfileSection() {
	const [active, setActive] = useState("individualLogin");

	const { user, isLoading, isError, isSuccess, message } = useSelector(
		(state) => state.auth
	)

	const navigate = useNavigate();

	useEffect(() => {
		if (user) {
			navigate('/home')
		}
	}, [])

	 console.log(user, isLoading, isError, isSuccess, message)

	return (
		<div className='w-screen h-screen flex items-center justify-center py-10' style={{ backgroundImage: "url('images/individualLogin.png')", backgroundSize: 'cover' }}>
			<div className='bg-white text-base rounded-md 2xl:w-[30rem] xl:w-[26rem] lg:w-[25rem] md:w-[24rem] sm:w-[24rem]  border-2'>
				{active === "individualLogin" && <>
					<div className='w-100 '>
						<button onClick={() => setActive("individualLogin")} className="px-2 py-3 w-1/2 font-semibold border-x-0 border-b-2 rounded-[0.375rem] border-b-green-500">Individual Account</button>
						<button onClick={() => setActive("businessLogin")} className="px-2 py-3 w-1/2 font-semibold rounded-[0.375rem]">Business Account</button>
					</div>
					<div className="mt-4 flex font-semibold items-center justify-center">
						<button onClick={() => setActive("individualLogin")} className="px-3 py-2 bg-[#25C454] text-white rounded-[0.375rem] " id="individualLoginForm">Login</button>
						<button onClick={() => setActive("individualSignup")} className="px-3 py-2 rounded-[0.375rem]" id="individualSignupForm">Signup</button>
					</div>
					<IndividualLogin />
				</>}
				{active === "individualSignup" && <>
					<div className='w-100'>
						<button onClick={() => setActive("individualLogin")} className="px-2 py-3 w-1/2 font-semibold border-x-0 border-b-2 rounded-[0.375rem] border-b-green-500">Individual Account</button>
						<button onClick={() => setActive("businessLogin")} className="px-2 py-3 w-1/2 font-semibold rounded-[0.375rem]">Business Account</button>
					</div>
					<div className="mt-4 flex font-semibold items-center justify-center">
						<button onClick={() => setActive("individualLogin")} className="px-3 py-2 rounded-[0.375rem]" id="individualLoginForm">Login</button>
						<button onClick={() => setActive("individualSignup")} className="px-3 py-2 bg-[#25C454] text-white rounded-[0.375rem] " id="individualSignupForm">Signup</button>
					</div>
					<IndividualSignup />
				</>}

				{active === "businessLogin" && <>
					<div className='w-100'>
						<button onClick={() => setActive("individualLogin")} className="px-2 py-3 w-1/2 font-semibold rounded-[0.375rem]">Individual Account</button>
						<button onClick={() => setActive("businessLogin")} className="px-2 py-3 w-1/2 font-semibold border-x-0 border-b-2 border-b-green-500 rounded-[0.375rem]">Business Account</button>
					</div>
					<div className="mt-8 flex font-semibold items-center justify-center">
						<button onClick={() => setActive("businessLogin")} className="px-3 py-2 bg-[#25C454] text-white rounded-[0.375rem] " id="businessLoginForm">Login</button>
						<button onClick={() => setActive("businessSignup")} className="px-3 py-2 rounded-[0.375rem]" id="businessSignupForm">Signup</button>
					</div>
					<BusinessLogin />
				</>}
				{active === "businessSignup" && <>
					<div className='w-100'>
						<button onClick={() => setActive("individualLogin")} className="px-2 py-3 w-1/2 font-semibold rounded-[0.375rem]">Individual Account</button>
						<button onClick={() => setActive("businessLogin")} className="px-2 py-3 w-1/2 font-semibold border-x-0 border-b-2 border-b-green-500 rounded-[0.375rem]">Business Account</button>
					</div>
					<div className="mt-8 flex font-semibold items-center justify-center">
						<button onClick={() => setActive("businessLogin")} className="px-3 py-2 rounded-[0.375rem] " id="businessLoginForm">Login</button>
						<button onClick={() => setActive("businessSignup")} className="px-3 py-2 bg-[#25C454] text-white rounded-[0.375rem] " id="businessSignupForm">Signup</button>
					</div>
					<BusinessSignup />
				</>}
			</div>
		</div>
	);
}

export default ProfileSection;
