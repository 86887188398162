import React from 'react'

const BatteryWarningBlock = ({ index }) => {
    return (
        <div style={{
            height: "46px",
            width: "399px",
            left: "0px",
            position: "relative",
            backgroundColor: "transparent"
        }}>
            <div className="group-341-ttnn8p">
                <div className="group-340-gNxjvT">
                    <div className="hv-battery-block-yellow-field"></div>
                </div>
                <div className="hv-battery-block-green-txt-icon-gNxjvT">
                    <div className="high-voltage-battery-block-03-DThcHj segoeui-regular-normal-black-24px">
                        High Voltage Battery Block #{index + 1}
                    </div>

                    <div className="icon-caution-or-monitor">
                        <div className="caution-or-monitor-shape3"></div>
                        <div className="caution-or-monitor-shape2"></div>
                        <img className="caution-or-monitor-shape1"
                            src={require("../../../assets/img/caution-or-monitor-shape1@1x.png")}
                        />
                    </div>

                </div>
            </div>
        </div>
    )
}

export default BatteryWarningBlock
