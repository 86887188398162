
import { FiEye } from "react-icons/fi"
import { BsArrowLeft } from "react-icons/bs"


import React from 'react'

const ResetPasswordBuisnessAccount = () => {
    return (
        <div className="h-screen flex flex-col items-center justify-center w-screen" style={{ backgroundImage: 'url("https://images.pexels.com/photos/5695348/pexels-photo-5695348.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1")', backgroundSize: "cover" }}>
            <div class="bg-white text-base rounded-md 2xl:w-[30rem] xl:w-[26rem] lg:w-[25rem] md:w-[24rem] sm:w-[24rem]  border-2">
                {/* <div className=" border rounded-sm  px-5 py-5 w-[32.875rem] bg-white" > */}
                <div className="flex flex-row justify-between items-center mb-5 mt-[0.4rem] mx-9">
                    <div className="flex">
                        <span ><BsArrowLeft size={20} /></span>
                        <label className="font-bold text-sm ml-3">Reset Password</label>
                    </div>
                    <div>
                        <label className="font-light text-sm ml-3">Individual Account</label>
                    </div>
                </div>
                <form action="">
                    <div className="flex flex-col mt-[1.5rem] mx-9">
                        <div className="mb-2">
                            <label className="font-bold text-sm">Old Password</label>
                        </div>
                        <div class="relative z-0 mb-6 w-full group flex justify-between ">
                            <input type="password" name="floating_email" id="floating_email" class="block py-2.5 px-5 w-full text-sm  bg-[#F6F6F6] border-0 border-b-2 border-[#25C454] appearance-none dark:text-white dark:border-[#25C454] dark:focus:border-[#25C454] focus:outline-none focus:ring-0 focus:border-[#25C454] peer" placeholder=" " required />
                            <span class="z-10 mr-3  font-normal absolute rounded text-base items-center justify-center right-0  py-4">
                                <FiEye size={22} />
                            </span>
                        </div>
                    </div>
                    <div className="flex flex-col mt-[0.4rem] mx-9">
                        <div className="mb-2">
                            <label className="font-bold text-sm">New Password</label>
                        </div>
                        <div class="relative z-0 mb-6 w-full group flex justify-between ">
                            <input type="password" name="floating_email" id="floating_email" class="block py-2.5 px-5 w-full text-sm  bg-[#F6F6F6] border-0 border-b-2 border-[#25C454] appearance-none dark:text-white dark:border-[#25C454] dark:focus:border-[#25C454] focus:outline-none focus:ring-0 focus:border-[#25C454] peer" placeholder=" " required />
                            <span class="z-10 mr-3 font-normal absolute rounded text-base items-center justify-center right-0  py-4">
                                <FiEye size={22} />
                            </span>
                        </div>
                    </div>
                    <div className="flex flex-col mt-[0.4rem] mx-9">
                        <div className="mb-2">
                            <label className="font-bold text-sm">Verify Password</label>
                        </div>
                        <div class="relative z-0 mb-6 w-full group flex justify-between ">
                            <input type="password" name="floating_email" id="floating_email" class="block py-2.5 px-5 w-full text-sm bg-[#F6F6F6] border-0 border-b-2 border-[#25C454] appearance-none dark:text-white dark:border-[#25C454] dark:focus:border-[#25C454] focus:outline-none focus:ring-0 focus:border-[#25C454] peer" placeholder=" " required />
                            <span class="z-10 mr-3 font-normal absolute rounded text-base items-center justify-center right-0  py-4">
                                <FiEye size={22} />
                            </span>
                        </div>
                    </div>
                    <div className="mb-2 mt-[0.4rem] mx-9">
                        <div className="bg-[#25C454] py-3 rounded">
                            <div className="flex justify-center items-center">
                                <button className="text-sm font-bold text-white text-center">Reset Password</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        // <div>
        //     <div className="flex flex-col">
        //         <label className="text-left ml-[2.375rem] font-bold text-[1rem]">User Id</label>
        //         <div class="relative flex w-full flex-wrap items-stretch mb-3">
        //             <input type="text" className=" ml-[2.375rem] bg-slate-50 w-[100%] rounded-[0.375rem] border-x-0 border-t-0 border-b-2 border-b-[#25C454]" id="userId" placeholder="Enter user id" name="userId" />
        //             <span class="z-10  font-normal absolute  absolute rounded text-base items-center justify-center w-10 right-0 pr-3 py-4">
        //                 <FiEye size={22} />
        //             </span>
        //         </div>
        //     </div>
        // </div>
    )
}

export default ResetPasswordBuisnessAccount



// const ResetPasswordBuisnessAccount = () => {

//     return (

//         <div className="w-screen h-screen flex items-center justify-center">
//             <div className="border-2 border-black-900  justify-center items-center">

//                 <div className=" mt-2 mx-8 mb-8  justify-center items-center ">
//                     <div className="inline-flex">
//                         <svg xmlns="http://www.w3.org/2000/svg" width="18" height="24" fill="currentColor" class="bi bi-arrow-left-short" viewBox="0 0 16 16">
//                             <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z" />
//                         </svg>
//                         <label className="text-xl font-medium ">Reset Password</label>
//                         <label className="text-base text-slate-400 ml-6 mt-1">Buisness Account</label>
//                     </div>
//                     <form>
//                         <div className="pt-6">
//                             {/* <label>Old Password</label> */}
//                             <div className="flex">
//                                 <input type="password" className=" h-8 relative bg-gray-100 rounded-md border border-b-green-600 border-r-0 border-t-0 border-l-0 w-full" />
//                                 <span className="absolute ml-80 mt-2"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye" viewBox="0 0 16 16">
//                                     <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
//                                     <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
//                                 </svg></span>
//                             </div>
//                         </div>
//                         <div className="flex flex-col">
//                             <label className="text-left ml-[2.375rem] font-bold text-[1rem]">User Id</label>
//                             <div class="relative flex w-full flex-wrap items-stretch mb-3">
//                                 <input type="text" className=" ml-[2.375rem] bg-slate-50 w-[100%] rounded-[0.375rem] border-x-0 border-t-0 border-b-2 border-b-[#25C454]" id="userId" placeholder="Enter user id" name="userId" />
//                                 <span class="z-10  font-normal absolute  absolute rounded text-base items-center justify-center w-10 right-0 pr-3 py-4">
//                                     <FiEye size={22} />
//                                 </span>
//                             </div>
//                         </div>
//                         <div className="pt-6">
//                             <label>New Password</label>
//                             <div className="flex">
//                                 <input type="password" className="h-8 relative bg-gray-100 rounded-md border border-b-green-600 border-r-0 border-t-0 border-l-0 w-full" />
//                                 <span className="absolute ml-80 mt-2"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye" viewBox="0 0 16 16">
//                                     <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
//                                     <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
//                                 </svg></span>
//                             </div>
//                         </div>
//                         <div className="pt-6">
//                             <label>Verify New Password</label>
//                             <div className="flex">
//                                 <input type="password" className="h-8 relative bg-gray-100 rounded-md border border-b-green-600 border-r-0 border-t-0 border-l-0 w-full" />
//                                 <span className="absolute ml-80 mt-2"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye" viewBox="0 0 16 16">
//                                     <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
//                                     <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
//                                 </svg></span>
//                             </div>
//                         </div>
//                         <div className="pt-8">
//                             <button className="text-white bg-green-500 h-10 w-full rounded-md">Reset Password</button>
//                         </div>
//                     </form>
//                 </div>
//             </div>
//         </div>
//     )
// }

// export default ResetPasswordBuisnessAccount;