import React from 'react'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import BatteryErrorBlock from './BatteryErrorBlock'
import BatterySuccessBlock from './BatterySuccessBlock'
import BatteryWarningBlock from './BatteryWarningBlock'




const BatteryVoltages = () => {


   
    const { data } = useSelector((state) => state.report);

    const { reportData, reportParameters } = data
    const { lowVoltThresholdPre,redVoltThreshold, yellowVoltThreshold,
            lithiumRedVoltThreshold,
            lithiumYellowVoltDeltaThreshold,
            lithiumRedVoltDeltaThreshold, 
            splitVoltCells, highVoltCells,
            highLowVoltThresholdPre, highRedVoltThreshold,
            highYellowVoltThreshold,highVoltDeltaThreshold,
        
        
        } = reportParameters?.parameters

    const { endStatus,subStatus,batteryVoltage } = reportData
  
  /*
    const batteryVoltage_data = JSON.parse(batteryVoltage);
    const { BatteryVoltages } = batteryVoltage_data
*/
    const BatteryVoltages = JSON.parse(batteryVoltage);
    
    let redVoltThresholdCalc=redVoltThreshold
    let yellowVoltThresholdCalc=yellowVoltThreshold
    let lithiumVehicle=0
    let lithiumMaxVoltage=Number.MIN_VALUE
    let hasSplitCells=0

    function calcMaxVoltage(battVoltages)
    {
       let i=0
       while (i < battVoltages.length) {
        if (parseFloat(battVoltages[i])>lithiumMaxVoltage)
            lithiumMaxVoltage=parseFloat(battVoltages[i])

        i=i+1
       }

       console.log("Yellow lithium: ",lithiumYellowVoltDeltaThreshold)
    }

    function setThresholds(battVoltages)
    {

        let i = 0;
        let thresh=0.35

        if (splitVoltCells===undefined) hasSplitCells=0
        else hasSplitCells=1

        console.log("Split volt cells: ",hasSplitCells,highVoltCells)


        if (endStatus==130) 
            {
                console.log("Lithium case")
                lithiumVehicle=1
                hasSplitCells=0
                console.log("redVoltDelta: ",lithiumRedVoltDeltaThreshold)
                console.log("yellowVoltDelta: ",lithiumYellowVoltDeltaThreshold)
                console.log("RedThreshold: ",lithiumRedVoltThreshold)
                calcMaxVoltage(battVoltages)

            }

        console.log("Calling setThresholds")
      
      
    }


    setThresholds(BatteryVoltages)
     console.log("BatteryVoltages ____________ ", BatteryVoltages);
    // console.log({
    //     data: reportParameters,
    //     redVoltThreshold: parseFloat(redVoltThreshold),
    //     yellowVoltThreshold: parseFloat(yellowVoltThreshold)
    // })

    return (
        <div
            style={{
                height: "auto",
                width: "399px",
                left: "0px",
                position: "relative",
                backgroundColor: "transparent",
                top: "200px"
                // top: "190px"
            }}
        >
            {/* TODO: use switch case */}

        
            {BatteryVoltages.length > 0 ? BatteryVoltages?.map((item, index) => {

                if (lithiumVehicle===1)
                {
                    let deltaVolt=0

                    deltaVolt=Math.abs(parseFloat(item)-lithiumMaxVoltage)
                    
                    if (deltaVolt >= parseFloat(lithiumRedVoltDeltaThreshold)) {
                        return <BatteryErrorBlock key={index} index={index} />
                    } else if (deltaVolt >= parseFloat(lithiumYellowVoltDeltaThreshold)) {
                        return <BatteryWarningBlock index={index} />
                    } else {
                        return <BatterySuccessBlock key={index} index={index} />
                    }
                   
                }
                else {

                    if ((endStatus===8) && (subStatus===2))
                    {

                        if (hasSplitCells===1)
                        {

                            console.log("Index: ",index,typeof(index))
                            if ( (highVoltCells.includes(index)) && 
                                 (parseFloat(item) <= parseFloat(highLowVoltThresholdPre))
                               )
                           {
                            return <BatteryErrorBlock key={index} index={index} />
                           }
                           else 
                            if (parseFloat(item) <= parseFloat(lowVoltThresholdPre))        
                                {
                                return <BatteryErrorBlock key={index} index={index} />
                            } else {
                                return <BatterySuccessBlock key={index} index={index} />
                            }

                        } else {

                        if (parseFloat(item) <= parseFloat(lowVoltThresholdPre)) {
                            return <BatteryErrorBlock key={index} index={index} />
                        } else {
                            return <BatterySuccessBlock key={index} index={index} />
                        }
                    }

                    } else {

                        //add split cells 

                        if ((hasSplitCells===1) && (highVoltCells.includes(index)))
                            {


                                if (parseFloat(item) <= parseFloat(highRedVoltThreshold)) {
                                    return <BatteryErrorBlock key={index} index={index} />
                                } else if (parseFloat(item) <= parseFloat(highYellowVoltThreshold)) {
                                    return <BatteryWarningBlock index={index} />
                                } else {
                                    return <BatterySuccessBlock key={index} index={index} />
                                }

    
                            } else {
                                        if (parseFloat(item) <= parseFloat(redVoltThreshold)) {
                                            return <BatteryErrorBlock key={index} index={index} />
                                        } else if (parseFloat(item) <= parseFloat(yellowVoltThreshold)) {
                                            return <BatteryWarningBlock index={index} />
                                        } else {
                                            return <BatterySuccessBlock key={index} index={index} />
                                        }
                                    }
            }
        }
            }) : null}
            
        </div>
    )
}

export default BatteryVoltages
