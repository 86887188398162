import React from 'react'

const RedSOC = () => {
    return (
        <div className="group-385-y8xzcO">
            <div className="group-341-awARPr">
                <div className="group-340-2RgsPB">
                    <img className="hv-battery-block-red-field"
                        src={require("../../../assets/img/hv-battery-block-green-field-10@1x.png")}
                    />
                </div>
                <div className="hv-battery-block-green-txt-icon-2RgsPB">
                    <div className="hv-battery-state-of-charge-so-c-2EShw5 segoeui-regular-normal-black-24px">
                        HV Battery State of Charge (SoC)
                    </div>

                    <div className="icon-error-or-termination-soc">
                        <div className="error-shape5-pKWbAG"></div>
                        <div className="error-shape4-pKWbAG"></div>
                        <img
                            className="error-shape3-pKWbAG"
                            src={require("../../../assets/img/error-shape3@1x.png")}
                        />
                        <img
                            className="error-shape2-pKWbAG"
                            src={require("../../../assets/img/error-shape2-10@1x.png")}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RedSOC
