import axios from 'axios';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Config } from "../../config/index"
import { Link, useNavigate } from 'react-router-dom';
import { FiEye } from "react-icons/fi"
import { getStores } from 'features/store/storeSlice';
import AddStoreModal from './AddStoreModal';

const Stores = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { user } = useSelector((state) => state.auth);

    const {
        stores,
        isError,
        isSuccess,
        isLoading,
        message,
    } = useSelector((state) => state.store);

    // console.log({
    //     component: "Business Stores:",
    //     stores,
    //     isError,
    //     isSuccess,
    //     isLoading,
    //     message,
    // })

    const { userId, businessId, jwtToken } = user;
    const config = {
        headers: {
            Authorization: `Bearer ${jwtToken}`,
        },
    }

    const [showModal, setShowModal] = React.useState(false);

    // Update Store Based on User
    async function updateStore(data, storeId, index) {
        try {
            if (!storeId) {
                data.userId = userId
                const response = await axios.post(`${Config.API_URL}/store`, data, config);
                if (response.status === 200 && response.data) {
                    // setStoreData(response.data);
                    toast.success("Store Added Successfully!");
                }
            } else {
                const response = await axios.put(`${Config.API_URL}/store/${storeId}`, data, config);
                if (response.status === 200 && response.data) {
                    // setStoreData(response.data);
                    toast.success(`Store ${index + 1} Updated Successfully!`);
                }
            }

        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        dispatch(getStores(businessId))
    }, []);

    return (
        <>
            <div className="flex flex-col mb-20">
                <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="py-4 inline-block min-w-full sm:px-6 lg:px-8">
                        <div className="overflow-hidden">
                            {/* <div className='flex justify-end items-center mb-2'>
                                <button
                                    onClick={() => setShowModal(true)}
                                    className={'text-white py-2 px-5 rounded bg-green-500 font-medium'}>Add Store</button>
                            </div> */}
                            <table className="min-w-full text-center">
                                <thead className="border-b bg-gray-50">
                                    <tr>
                                        <th scope="col" className="text-sm font-semibold text-gray-900 px-6 py-4">
                                            Sr. No
                                        </th>
                                        <th scope="col" className="text-sm font-semibold text-gray-900 px-6 py-4">
                                            Store Name
                                        </th>
                                        <th scope="col" className="text-sm font-semibold text-gray-900 px-6 py-4">
                                            Owner Name
                                        </th>
                                        <th scope="col" className="text-sm font-semibold text-gray-900 px-6 py-4">
                                            Email
                                        </th>
                                        <th scope="col" className="text-sm font-semibold text-gray-900 px-6 py-4">
                                            Phone
                                        </th>
                                        <th scope="col" className="text-sm font-semibold text-gray-900 px-6 py-4 text-end">
                                            View store
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {stores.length > 0 ? stores.map((item, index) => {
                                        return (
                                            <tr key={index} className="bg-white border-b">

                                                <td className="text-sm text-gray-900 font-normal px-6 py-4 whitespace-nowrap">
                                                    {index + 1}
                                                </td>
                                                <td className="text-sm text-gray-900 font-normal px-6 py-4 whitespace-nowrap">
                                                    <Link to={`/store-detail/${item.userId}`}> {item?.store.storeName} </Link>
                                                </td>
                                                <td className="text-sm text-gray-900 font-normal px-6 py-4 whitespace-nowrap">
                                                    {item?.store.ownerName}
                                                </td>
                                                <td className="text-sm text-gray-900 font-normal px-6 py-4 whitespace-nowrap">
                                                    {item.email}
                                                </td>
                                                <td className="text-sm text-gray-900 font-normal px-6 py-4 whitespace-nowrap">
                                                    {item.phoneNo}
                                                </td>
                                                <td className="text-sm text-gray-900 font-semibold px-6 py-4 whitespace-nowrap text-end">
                                                    <button onClick={() => navigate(`/store-detail/${item.userId}`)} className="bg-[#25C454] text-white py-2 px-3 rounded">
                                                        <span className='flex items-center justify-center'>
                                                            <FiEye className='mr-1.5' size={16} /> View store
                                                        </span>
                                                    </button>
                                                </td>

                                            </tr>
                                        );
                                    }) :
                                        <tr className='mt-5' >
                                            <td colSpan={5}>No Data Available!</td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

            </div>

            {/* Add Store User Modal */}
            {showModal ? (
                <>
                    <AddStoreModal setShowModal={setShowModal} />
                </>
            ) : null}
        </>
    )
}

export default Stores
