import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'react-toastify';
import storeService from './storeService';

const initialState = {
    stores: [],
    isError: false,
    isSuccess: false,
    isLoading: false,
    isUpdated: false,
    message: '',
}

// Create new store
export const createStore = createAsyncThunk(
    'stores/create',
    async (storeData, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.jwtToken
            return await storeService.createStore(storeData, token)
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Update store
export const updateStore = createAsyncThunk(
    'stores/update',
    async (storeData, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.jwtToken
            return await storeService.updateStore(storeData, token)
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Get user stores
export const getStores = createAsyncThunk(
    'stores/getAll',
    async (id, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.jwtToken
            return await storeService.getStores(id, token)
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

// Delete user store
export const deleteStore = createAsyncThunk(
    'stores/delete',
    async (id, thunkAPI) => {
        try {
            const token = thunkAPI.getState().auth.user.jwtToken
            return await storeService.deleteStore(id, token)
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString()
            return thunkAPI.rejectWithValue(message)
        }
    }
)

export const storeSlice = createSlice({
    name: 'store',
    initialState,
    reducers: {
        reset: (state) => initialState,
        clearUpdate: (state) => (
            { ...state, isUpdated: false }
        )
    },
    extraReducers: (builder) => {
        builder
            .addCase(createStore.pending, (state) => {
                state.isLoading = true
            })
            .addCase(createStore.fulfilled, (state, action) => {
                console.log("Create Store Payload: ", action.payload);
                state.isLoading = false
                state.isSuccess = true
                state.stores.push(action.payload)
            })
            .addCase(createStore.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(updateStore.pending, (state) => {
                state.isLoading = true
            })
            .addCase(updateStore.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.stores = state.stores.map((store) => (store.id === action.payload.id ? action.payload : store))
                state.isUpdated = true
            })
            .addCase(updateStore.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(getStores.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getStores.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.stores = action.payload
            })
            .addCase(getStores.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
            .addCase(deleteStore.pending, (state) => {
                state.isLoading = true
            })
            .addCase(deleteStore.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.stores = state.stores.filter(
                    (store) => store.id !== action.payload.id
                )
            })
            .addCase(deleteStore.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
    },
})

export const { reset, clearUpdate } = storeSlice.actions
export default storeSlice.reducer
