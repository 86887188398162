import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { updateProfile, getUser } from 'features/auth/authSlice';
import axios from 'axios';
import { Config } from "../../config/index"
import { toast } from 'react-toastify';
import STATES from "../../states"
import { apiPUT, apiGET, apiDELETE } from "../../utils/apiHelper"
import { useNavigate } from 'react-router-dom';
import { logout, reset } from 'features/auth/authSlice';

const initialState = {
    "id": "",
    "firstName": "",
    "lastName": "",
    "email": "",
    "phoneNo": "",
    "country": "",
    "state": "",
    "address": "",
}

const MyProfile = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { user } = useSelector((state) => state.auth);
    const [formData, setFormData] = useState(initialState);
    const [isLoading, setIsLoading] = useState(false);
    const [hasChanged, setHasChanged] = useState(false);
    const [validPassword, setValidPassword] = useState(false);

    const stringPattern = new RegExp("[a-zA-Z ]$");
    const numberPattern = new RegExp("[0-9]$");
    const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    const onChange = (e) => {
        if ((e.target.name === "firstName" && e.target.value) || (e.target.name === "lastName" && e.target.value)) {
            if (stringPattern.test(e.target.value) == false) {
                // toast.error("Names can only contain alphabets!");
                return
            }
        }

        if (e.target.name === "phoneNo" && e.target.value) {
            if (numberPattern.test(e.target.value) == false) {
                // toast.error("Phone number cannot contain alphabets!");
                return
            }
        }

        if (e.target.name === 'address' && e.target.value) {
            setFormData({ ...formData, address: e.target.value });
            return
        };

        if (e.target.name === 'password' && e.target.value) {
            const isValidPassword = passwordRegex.test(e.target.value);
            console.log('isValidPassword: ', isValidPassword)
            setValidPassword(isValidPassword);
        };

        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value.trim(),
        }))

        // Mark form as changed
        setHasChanged(true);
        // setFormData((prevState) => ({
        //     ...prevState,
        //     [e.target.name]: e.target.value,
        // }))
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        // check validation

        console.log("formData: ", formData)

        if (formData?.password?.length > 0 && !validPassword) {
            return toast.error('Invalid Password!');
        }

        if (formData.email && emailRegex.test(formData.email) === false) {
            return toast.error("Invalid Email!")
        }

        if (formData.phoneNo && formData.phoneNo.length !== 10) {
            return toast.error("Invalid Phone No!")
        }

        const allTruthy = Object.values(formData).every(value => value);
        // console.log("allTruthy ====== ", allTruthy);

        if (!allTruthy) {
            return toast.error("Please fill all mandatory fields!");
        }

        const { userId } = user
        updateUser(formData, userId)
    }

    const updateUser = async (data, id) => {
        try {
            const response = await apiPUT(`/users/individual/${id}`, data);
            if (response.status === 200 && response.data) {
                toast.success("Profile Updated Successfully!");
            }

            if (response.status !== 200) {
                console.log(response.data)
                return toast.error(response?.data?.message || 'Error!');
            }
        } catch (error) {
            console.log(error);
            toast.error(error.response.data.message || 'Error!');
        } finally {
            setHasChanged(false)
        }
    }

    const getUser = async (userId) => {

        // console.log("userId ==== ", userId);

        try {
            const response = await apiGET(`/users/${userId}`);

            if (response.status === 200) {
                setFormData({
                    id: response.data.userId,
                    firstName: response.data.firstName,
                    lastName: response.data.lastName,
                    email: response.data.email,
                    phoneNo: response.data.phoneNo,
                    country: response.data.country,
                    state: response.data.state,
                    address: response.data.address
                });
            }

            if (response.status !== 200) {
                console.log(response.data);
                toast.error('Something went wrong!')
            }

        } catch (error) {
            console.log(error);
            toast.error('Something went wrong!')
        }
    }

    async function handleDeleteUser(e) {
        e.preventDefault();
        setIsLoading(true);
        if (user?.userId) {
            try {
                const response = await apiDELETE(`/users/individual/${user?.userId}`);
                if (response.status === 200) {
                    toast.success(response?.data?.message);
                    dispatch(logout());
                    dispatch(reset())
                    navigate('/home')
                }

                if (response.status !== 200) {
                    console.log(response.data)
                    toast.error(response?.data?.message);
                }
            } catch (error) {
                console.log(error);
                toast.error('Something went wrong!');
            } finally {
                setIsLoading(false);
            }
        }
    }

    useEffect(() => {
        if (user?.userId) {
            getUser(user?.userId)
        }
    }, [user]);

    const isDisabled = !hasChanged;

    return (
        <div className="bg-[#F8F8F8] rounded p-[1.375rem] mb-20">
            <form onSubmit={handleSubmit} className="w-full">
                <div className="w-full  mb-5">
                    <div className="flex justify-between">
                        <div><span className="font-bold">Personal Details</span></div>
                        <div className="">
                            <button
                                onClick={(e) => {
                                    e.preventDefault();
                                    navigate(0)
                                }}
                                className="bg-white rounded-[0.3125rem] py-2 px-3">
                                Cancel
                            </button>
                            <button
                                disabled={isDisabled}
                                type='submit'
                                className={`ml-[0.5rem] bg-[#25C454] text-white rounded-[0.3125rem] py-2 px-3 ${isDisabled && 'opacity-75'}`}>
                                Save
                            </button>

                            {/* <button
                                onClick={(e) => handleDeleteUser(e)}
                                type='submit'
                                className="bg-[#FF0000] text-white rounded-[0.3125rem] ml-5 py-2 px-3">
                                {isLoading ? 'Deleting...' : 'Delete Account'}
                            </button> */}
                        </div>
                    </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-6">
                    <div className="w-full md:w-1/2 px-3 md:mb-0">
                        <label className="block  tracking-wide text-xs font-bold mb-2 required" htmlFor="grid-first-name">
                            First Name
                        </label>
                        <input
                            className="font-medium appearance-none block w-full bg-white  border-[#25C454]  border-none  dark:border-[#25C454] dark:focus:border-[#25C454]   rounded py-3 px-4 mb-3 leading-tight  focus:outline-1 focus:bg-white"
                            id="grid-first-name"
                            type="text"
                            name='firstName'
                            value={formData.firstName}
                            onChange={onChange}
                            // required
                            placeholder="Enter first name" />
                    </div>
                    <div className="w-full md:w-1/2 px-3">
                        <label className="block  tracking-wide  text-xs font-bold mb-2 required" htmlFor="grid-last-name">
                            Last Name
                        </label>
                        <input
                            className="font-medium appearance-none block w-full bg-white  border-[#25C454]  border-none  dark:border-[#25C454] dark:focus:border-[#25C454]    rounded py-3 px-4 mb-3 leading-tight  focus:outline-1 focus:bg-white"
                            id="grid-first-name"
                            type="text"
                            name='lastName'
                            value={formData.lastName}
                            onChange={onChange}
                            // required
                            placeholder="Enter lirst name" />
                    </div>
                </div>

                <div className="flex flex-wrap -mx-3 mb-6">
                    <div className="w-full md:w-1/2 px-3  md:mb-0">
                        <label className="block  tracking-wide  text-xs font-bold mb-2 required" htmlFor="grid-last-name">
                            Phone Number
                        </label>
                        <div className="flex">
                            <div className='bg-white font-medium rounded py-3 px-4 mb-3 mr-2'>
                                +1
                            </div>
                            <input
                                className="font-medium appearance-none block w-full bg-white  border-[#25C454]  border-none  dark:border-[#25C454] dark:focus:border-[#25C454]    rounded py-3 px-4 mb-3 leading-tight  focus:outline-1 focus:bg-white"
                                id="grid-first-name"
                                type="text"
                                name='phoneNo'
                                value={formData.phoneNo}
                                onChange={onChange}
                                minLength={10}
                                maxLength={10}
                                // required
                                placeholder="Enter mobile no" />
                        </div>
                    </div>

                    <div className="w-full md:w-1/2 px-3">
                        <label className="block  tracking-wide  text-xs font-bold mb-2 required" htmlFor="grid-last-name">
                            Email
                        </label>
                        <input
                            className="font-medium appearance-none block w-full bg-white  border-[#25C454]  border-none  dark:border-[#25C454] dark:focus:border-[#25C454]    rounded py-3 px-4 mb-3 leading-tight  focus:outline-1 focus:bg-white"
                            id="grid-first-name"
                            type="email"
                            name='email'
                            value={formData.email}
                            onChange={onChange}
                            // required
                            placeholder="Enter email" />
                    </div>
                </div>

                <div className="w-full md:w-1/2 mt-6 mb-6">
                    <h4 className='font-bold'>
                        Address Details</h4>
                </div>

                <div className="flex flex-wrap -mx-3 mb-6">
                    <div className="w-full md:w-1/2 px-3  md:mb-0">
                        <label className="block  tracking-wide  text-xs font-bold mb-2 required" htmlFor="grid-last-name">
                            Country
                        </label>
                        <select
                            className="font-medium appearance-none block w-full bg-white  border-[#25C454]  border-none  dark:border-[#25C454] dark:focus:border-[#25C454]    rounded py-3 px-4 mb-3 leading-tight  focus:outline-1 focus:bg-white"
                            id="businessCountry"
                            name='country'
                            value={formData.country}
                            onChange={onChange}
                        // required
                        >
                            <option value={""}>Select Country</option>
                            <option value={'US'}>United States</option>
                        </select>
                    </div>

                    <div className="w-full md:w-1/2 px-3  md:mb-0">
                        <label className="block  tracking-wide  text-xs font-bold mb-2 required" htmlFor="grid-last-name">
                            State
                        </label>
                        <select
                            className="font-medium appearance-none block w-full bg-white  border-[#25C454]  border-none  dark:border-[#25C454] dark:focus:border-[#25C454]    rounded py-3 px-4 mb-3 leading-tight  focus:outline-1 focus:bg-white"
                            id="businessCountry"
                            name='state'
                            value={formData.state}
                            onChange={onChange}
                        // required
                        >
                            <option value={""}>Select State</option>
                            {STATES.map((item, index) => (
                                <option key={index} value={item.abbreviation}>{item.name}</option>
                            ))}
                        </select>
                    </div>
                </div>

                <div className="flex flex-wrap  -mx-3 mb-6">
                    <div className="w-full md:w-1/2 px-3 md:mb-0">
                        <label className="block  tracking-wide  text-xs font-bold mb-2 required" htmlFor="grid-first-name">
                            Address
                        </label>
                        <input
                            className="font-medium appearance-none block w-full bg-white  border-[#25C454]  border-none  dark:border-[#25C454] dark:focus:border-[#25C454]    rounded py-3 px-4 mb-3 leading-tight  focus:outline-1 focus:bg-white"
                            id="grid-first-name"
                            type="text"
                            name='address'
                            value={formData.address}
                            onChange={onChange}
                            // required
                            placeholder="Enter address" />
                    </div>

                    <div className="w-full md:w-1/2 px-3 md:mb-0">
                        <label className="block  tracking-wide  text-xs font-bold mb-2" htmlFor="grid-first-name">
                            Change Password
                        </label>
                        <input
                            className="font-medium appearance-none block w-full bg-white  border-[#25C454]  border-none  dark:border-[#25C454] dark:focus:border-[#25C454]    rounded py-3 px-4 mb-3 leading-tight  focus:outline-1 focus:bg-white"
                            id="grid-first-name"
                            type="text"
                            name='password'
                            value={formData.password}
                            onChange={onChange}
                            // required
                            placeholder="Enter password" />
                        {formData?.password && formData?.password?.length > 0 && !validPassword ? (
                            <small style={{ color: 'red' }}>Password should contain small, capital letter, number & special character</small>
                        ) : null}
                    </div>



                </div>

            </form>
        </div>
    )
}

export default MyProfile
