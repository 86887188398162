import React from 'react'

const GreenSOC = () => {
    return (
        <div className="group-385-y8xzcO">
            <div className="group-341-awARPr">
                <div className="group-340-2RgsPB">
                    <div className="hv-battery-block-green-field-WR6W8p"></div>
                </div>
                <div className="hv-battery-block-green-txt-icon-2RgsPB">
                    <div className="hv-battery-state-of-charge-so-c-2EShw5 segoeui-regular-normal-black-24px">
                        HV Battery State of Charge (SoC)
                    </div>
                    <div className="icon-success-or-conclusion-2EShw5">
                        <div className="success-or-conclusion-shape2-qepx7x"></div>
                        <div className="success-or-conclusion-shape1-qepx7x"></div>
                        <img
                            className="icon-check_mark-qepx7x"
                            src={require("../../../assets/img/check-10@1x.png")}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GreenSOC
