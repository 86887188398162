import './header.css'
import './main_style.css'
import { Transition } from "@headlessui/react";
import { useRef, useState } from "react";
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

const Headers = () => {
    const [isOpen, setIsOpen] = useState(false);
    // const [isLoggedIn, setIsLoggedIn] = useState(false)
    const divRef = useRef()

    const { user, isLoading, isError, isSuccess, message } = useSelector(
        (state) => state.auth
    )

     console.log("User Role: ", user?.role);
    // BusinessOwner
    // StoreWorker
    // StoreSupervisor

    // console.log("Header === ", user)

    return (
        <div className='shadow-slate-800'>
            	



            <nav className="">
                <div className="  max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="flex items-center justify-between h-16">
                        <div className="flex items-center">
                            <div className="flex-shrink-0">
                                <Link to="/user">
                                    <img
                                        src={require("../../assets/img/battscan-logo.png")}
                                        // src="images\battscan-logo.png"
                                        width="120px" className="md:w-20 lg:w-24 xl:w-28 2xl:w-32"
                                    />
                                </Link>
                            </div>
                            {user ? (
                            <div className="hidden md:block">
                                <div className=" xl:flex xl:ml-4 xl:space-x-4 lg:flex lg:ml-3 lg:space-x-2 md:flex md:ml-1 md:space-x-1 ml-4  flex items-baseline space-x-3">
                                    <a
                                        href="/user"
                                        className=" xl:text-xs xl:px-3 lg:text-[0.720rem] lg:px-2 md:text-[0.600rem] md:px-1 hover:border border-t-white border-l-white border-r-white border-b-green-500  px-3 py-2  text-xs font-bold"
                                    >
                                        Home
                                    </a>

                                    <a
                                        href="#"
                                        className="xl:text-xs xl:px-3 lg:text-[0.720rem] lg:px-2 md:text-[0.600rem] md:px-1 hover:border border-t-white border-l-white border-r-white border-b-green-500  px-3 py-2  text-xs font-bold"
                                    >
                                        BattScan hardware
                                    </a>

                                    <a
                                        href="#"
                                        className="xl:text-xs xl:px-3 lg:text-[0.720rem] lg:px-2 md:text-[0.600rem] md:px-1 hover:border border-t-white border-l-white border-r-white border-b-green-500 px-3 py-2  text-xs font-bold"
                                    >
                                        Latest news
                                    </a>

                                    <a
                                        href="#"
                                        className=" xl:text-xs xl:px-3 lg:text-[0.720rem] lg:px-2 md:text-[0.600rem] md:px-1 hover:border border-t-white border-l-white border-r-white border-b-green-500 px-3 py-2  text-xs font-bold"
                                    >
                                        Product
                                    </a>

                                    <a
                                        href="#faq"
                                        className="xl:text-xs xl:px-3 lg:text-[0.720rem] lg:px-2 md:text-[0.600rem] md:px-1 hover:border border-t-white border-l-white border-r-white border-b-green-500  px-3 py-2  text-xs font-bold"
                                    >
                                        FAQ
                                    </a>
                                </div>
                            </div>
                           ): null}
                        </div>
                        <div className=" mainBtn sm:hidden lg:flex md:flex space-x-3">
                            <div className="space-x-3 xl:flex xl:space-x-3 lg:flex lg:space-x-2 md:flex md:space-x-1">
                                {!user ? (
                                    <>
                                        <Link to="/signup"
                                            className="flex justify-center items-center xl:w-20 xl:h-8 xl:border      md:border border-green-500 lg:w-18 lg:h-6   md:w-16 md:h-6 md:text-xs text-sm border boreder-green-500 w-20 h-8 rounded"
                                        >
                                            <span className='text-center'>Login</span>
                                        </Link>

                                        <Link
                                            to="/signup"
                                            className="flex justify-center items-center xl:w-20 xl:h-8 lg:w-18 lg:h-6  md:w-16 md:h-6 md:text-xs text-sm bg-green-500 text-white w-20 h-8 rounded"
                                        >
                                            <span className='text-center'>Signup</span>
                                        </Link>
                                    </>
                                ) : null}

                            </div>
                            <div className='flex items-center'>
                                <div className={`flex space-x-1 ${user?.role ? 'mr-4' : null}`}>
                                   <img
                                        src={require("../../assets/img/1x1.png")}
                                        // src="Images\king.png"
                                        className="xl:w-6 xl:h-6 md:w-4 md:h-4 w-6 h-6 " />
                                    <h6 className=" md:text-xs md:font-bold  text-sm font-bold mt-1"></h6>
                                </div>

                                <div className="flex flex-end items-center w-[30px]">
                                    {user?.role === 'BusinessOwner' ? (
                                        <Link to="/business">
                                            <img className="rounded"
                                                // src="/images/user.png"
                                                src={require("../../assets/img/user.png")}
                                                alt="profile" />
                                        </Link>
                                    ) : user?.role === 'StoreSupervisor' ? (
                                        <Link to={`/store-detail/${user.userId}`}>
                                            <img className="rounded" src={require("../../assets/img/user.png")} alt="profile" />
                                        </Link>
                                    ) : user?.role === 'StoreWorker' ? (
                                        <Link to={`/user-detail/${user.userId}`}>
                                            <img className="rounded" src={require("../../assets/img/user.png")} alt="profile" />
                                        </Link>
                                    ) : user?.role === 'IndividualUser' ? (
                                        <Link to="/individual">
                                            <img className="rounded" src={require("../../assets/img/user.png")} alt="profile" />
                                        </Link>
                                    ) : null}
                                </div>

                            </div>
                        </div>
                        <div className="-mr-2 flex md:hidden">
                            <button
                                onClick={() => setIsOpen(!isOpen)}
                                type="button"
                                className="inline-flex items-center justify-center p-2 rounded-md  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                                aria-controls="mobile-menu"
                                aria-expanded="false"
                            >
                                <span className="sr-only">Open main menu</span>
                                {!isOpen ? (
                                    <svg
                                        className="block h-6 w-6"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        aria-hidden="true"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M4 6h16M4 12h16M4 18h16"
                                        />
                                    </svg>
                                ) : (
                                    <svg
                                        className="block h-6 w-6"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        aria-hidden="true"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M6 18L18 6M6 6l12 12"
                                        />
                                    </svg>
                                )}
                            </button>
                        </div>
                    </div>
                </div>
                <Transition
                    show={isOpen}
                    enter="transition ease-out duration-100 transform"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="transition ease-in duration-75 transform"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                >
                    {() => (
                        <div className="md:hidden" id="mobile-menu">
                            <div ref={divRef} className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                                <div className="text-center">
                                    <a
                                        href="#"
                                        className="  block px-3 py-2 rounded-md text-base font-medium"
                                    >
                                        Home
                                    </a>

                                    <a
                                        href="#"
                                        className=" block px-3 py-2 rounded-md text-base font-medium"
                                    >
                                        BattScan hardware
                                    </a>

                                    <a
                                        href="#"
                                        className=" block px-3 py-2 rounded-md text-base font-medium"
                                    >
                                        Latest news
                                    </a>

                                    <a
                                        href="#"
                                        className=" block px-3 py-2 rounded-md text-base font-medium"
                                    >
                                        Product
                                    </a>

                                    <a
                                        href="#"
                                        className=" block px-3 py-2 rounded-md text-base font-medium"
                                    >
                                        FAQ
                                    </a>
                                </div>
                                {!user ? (
                                    <div className='flex justify-center text-center items-center'>
                                        <div className="space-y-1 ">
                                            <button className="border border-green-500 block  text-sm  w-80 h-8 rounded">
                                                <Link to="/signup">Login</Link>
                                            </button>
                                            <button className=" block  text-sm bg-green-500 text-white w-80 h-8 rounded">
                                                <Link to="/signup">Signup</Link>
                                            </button>
                                        </div>
                                    </div>
                                ) : null}

                            </div>
                        </div>
                    )}
                </Transition>
            </nav>



        </div>
    );
}

export default Headers;