import axios from 'axios'
import { Config } from "../../config/index"
const API_URL = `${Config.API_URL}/users`
// const API_URL = '/api/users/'

// Register user
const getParametersByVehicle = async (vehicleParams, token, item) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    // /parameter/vehicle-parameters

    const response = await axios.post(`${Config.API_URL}/key-parameter/vehicle-parameters`, vehicleParams, config);

    if (response.data) {
        const data = {
            reportData: item,
            reportParameters: response.data
        }
        return data
    }
}

const reportService = {
    getParametersByVehicle
}

export default reportService
