import axios from 'axios';
import { clearUpdate, updateStore } from 'features/store/storeSlice';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { apiDELETE, apiPUT } from 'utils/apiHelper';
import { Config } from "../../config/index"
import STATES from "../../states"
import { logout, reset } from 'features/auth/authSlice';

const initialState = {
    "storeName": "",
    "ownerName": "",
    "email": "",
    "phoneNo": "",
    // "shopNo": "",
    "address": "",
    "country": "",
    "state": "",
}

const stringPattern = new RegExp("[a-zA-Z ]$");
const numberPattern = new RegExp("[0-9]$");
const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

const StoreProfile = ({ data }) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { id: storeUserId } = useParams();
    const { user } = useSelector((state) => state.auth);

    const {
        stores,
        isError,
        isSuccess,
        isLoading,
        isUpdated,
        message,
    } = useSelector((state) => state.store);

    const [store, setStore] = useState(initialState)
    const [loading, setIsLoading] = useState(false)
    const [validPassword, setValidPassword] = useState(false);
    const [hasChanged, setHasChanged] = useState(false);

    const handleChange = (e) => {
        if ((e.target.name === "storeName" && e.target.value) || (e.target.name === "ownerName" && e.target.value)) {
            if (stringPattern.test(e.target.value) == false) {
                return
            }
        }

        if (e.target.name === "phoneNo" && e.target.value) {
            if (numberPattern.test(e.target.value) == false) {
                return
            }
        }

        if (e.target.name === 'password' && e.target.value) {
            const isValidPassword = passwordRegex.test(e.target.value);
            console.log('isValidPassword: ', isValidPassword)
            setValidPassword(isValidPassword);
        };

        setStore((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }))

        setHasChanged(true)
    }

    async function updateStoreSupervisor(storeUserId) {
        try {
            const response = await apiPUT(`${Config.API_URL}/users/store-supervisor/${storeUserId}`, store);
            if (response.status === 200) {
                toast.success("Supervisor details updated");
                setHasChanged(false);
                // setStore(response.data)
            }

            if (response.status !== 200) {
                console.log(response.data);
                return toast.error(response?.data?.message);
            }

        } catch (error) {
            console.log(error);
            toast.error(error?.response?.data?.message || 'Something went wrong!')
        }
    }

    const handleUpdateStore = (e) => {
        e.preventDefault();
        console.log("Store MyProfile: ", store);

        const allTruthy = Object.values(store).every(value => value);
        if (!allTruthy) {
            return toast.error("Please fill all mandatory fields!");
        }

        if (store.email && emailRegex.test(store.email) === false) {
            return toast.error("Invalid Email!")
        }

        if (store.phoneNo && store.phoneNo.length !== 10) {
            return toast.error("Invalid Phone No!")
        }

        if (store.storeName && store.storeName.trim().length < 3) {
            return toast.error("Store name not valid!")
        }

        if (store.ownerName && store.ownerName.trim().length < 3) {
            return toast.error("Manager name not valid!")
        }

        if (store?.password?.length > 0 && !validPassword) {
            return toast.error('Invalid Password!');
        }

        updateStoreSupervisor(storeUserId);
    }

    async function handleDeleteStore(e) {
        e.preventDefault();
        setIsLoading(true);
        try {
            const response = await apiDELETE(`/users/store-supervisor/${storeUserId}`);
            if (response.status === 200) {
                toast.success(response?.data?.message);
                if (user?.role === 'BusinessOwner') {
                    navigate(-1);
                } else {
                    dispatch(logout());
                    dispatch(reset())
                    navigate('/home')
                }
            }

            if (response.status !== 200) {
                console.log(response.data);
                return toast.error(response?.data?.message);
            }
        } catch (error) {
            console.log(error);
            toast.error(error?.response?.data?.message || 'Something went wrong!')
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        // getStore(storeUserId);
        if (data) {
            setStore({
                storeName: data.storeName,
                ownerName: data.ownerName,
                email: data.email,
                phoneNo: data.phoneNo,
                // shopNo: data.shopNo,
                address: data.address,
                country: data.country,
                state: data.state,
            })
        }
    }, [data]);

    useEffect(() => {
        if (isUpdated) {
            toast.success("Store Updated!");
            // dispatch(reset());
            dispatch(clearUpdate());
        }

        if (isError) {
            console.log("isError: ", message);
            // dispatch(reset());
        }
    }, [isUpdated, isError, message])

    const isDisabled = !hasChanged;
    return (
        <>
            <div className="bg-[#F8F8F8] rounded p-[1.375rem] mb-5">


                <form className="w-full">
                    <div className="w-full  mb-5">
                        <div className="flex justify-between">
                            <div className='flex justify-center items-center'>
                                <span className="font-bold">Store Details</span>
                            </div>
                            <div className="">
                                <button
                                    onClick={(e) => {
                                        e.preventDefault();
                                        navigate(0);
                                    }}
                                    className="bg-white rounded-[0.3125rem] py-2 px-3">Cancel</button>
                                <button
                                    disabled={isDisabled}
                                    onClick={handleUpdateStore}
                                    type='submit'
                                    className={`ml-[0.5rem] bg-[#25C454] text-white rounded-[0.3125rem] py-2 px-3 ${isDisabled && 'opacity-75'}`}>
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full md:w-1/2 px-3 md:mb-0">
                            <label className="block required tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-first-name">
                                Store Name
                            </label>
                            <input
                                className="appearance-none block w-full bg-white  border-[#25C454]  border-none  dark:border-[#25C454] dark:focus:border-[#25C454]  text-gray-700  rounded py-3 px-4 mb-3 leading-tight  focus:outline-1 focus:bg-white" id="grid-first-name"
                                type="text"
                                name="storeName"
                                value={store?.storeName}
                                required
                                onChange={(event) =>
                                    handleChange(event)
                                }
                                placeholder="Enter store name" />
                        </div>
                        <div className="w-full md:w-1/2 px-3">
                            <label className="block required tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name">
                                Manager Name
                            </label>
                            <input
                                className="appearance-none block w-full bg-white  border-[#25C454]  border-none  dark:border-[#25C454] dark:focus:border-[#25C454]  text-gray-700  rounded py-3 px-4 mb-3 leading-tight  focus:outline-1 focus:bg-white" id="grid-first-name"
                                type="text"
                                name="ownerName"
                                placeholder="Enter manager name"
                                required
                                value={store?.ownerName}
                                onChange={(event) =>
                                    handleChange(event)
                                }
                            />
                        </div>
                    </div>

                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full md:w-1/2 px-3  md:mb-0">
                            <label className="block required tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name">
                                Phone Number
                            </label>
                            <div className="flex">
                                <div className='bg-white rounded py-3 px-4 mb-3 mr-2'>
                                    +1
                                </div>
                                <input
                                    className="appearance-none block w-full bg-white  border-[#25C454]  border-none  dark:border-[#25C454] dark:focus:border-[#25C454]  text-gray-700  rounded py-3 px-4 mb-3 leading-tight  focus:outline-1 focus:bg-white"
                                    id="grid-first-name"
                                    type="text"
                                    placeholder="Enter phone no"
                                    name="phoneNo"
                                    required
                                    minLength={10}
                                    maxLength={10}
                                    value={store.phoneNo}
                                    onChange={(event) =>
                                        handleChange(event)
                                    }
                                />
                            </div>
                        </div>

                        <div className="w-full md:w-1/2 px-3">
                            <label className="block required tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name">
                                Email
                            </label>
                            <input
                                className="appearance-none block w-full bg-white  border-[#25C454]  border-none  dark:border-[#25C454] dark:focus:border-[#25C454]  text-gray-700  rounded py-3 px-4 mb-3 leading-tight  focus:outline-1 focus:bg-white"
                                id="grid-first-name"
                                type="email"
                                placeholder="Enter email"
                                name="email"
                                value={store.email}
                                required
                                onChange={(event) =>
                                    handleChange(event)
                                }
                            />
                        </div>
                    </div>

                    <div className="w-full md:w-1/2 mt-6 mb-6">
                        <h5 className='font-bold'>
                            Address Details</h5>
                    </div>

                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full md:w-1/2 px-3  md:mb-0">
                            <label className="block required tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name">
                                Country
                            </label>
                            <select
                                className="appearance-none block w-full bg-white  border-[#25C454]  border-none  dark:border-[#25C454] dark:focus:border-[#25C454]  text-gray-700  rounded py-3 px-4 mb-3 leading-tight  focus:outline-1 focus:bg-white"
                                id="businessCountry"
                                name='country'
                                value={store.country}
                                onChange={(event) =>
                                    handleChange(event)
                                }
                                required
                            >
                                <option value={""}>Select Country</option>
                                <option value={'US'}>United States</option>
                            </select>
                        </div>

                        <div className="w-full md:w-1/2 px-3  md:mb-0">
                            <label className="block required tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name">
                                State
                            </label>
                            <select
                                className="appearance-none block w-full bg-white  border-[#25C454]  border-none  dark:border-[#25C454] dark:focus:border-[#25C454]  text-gray-700  rounded py-3 px-4 mb-3 leading-tight  focus:outline-1 focus:bg-white"
                                id="businessCountry"
                                name='state'
                                value={store.state}
                                onChange={(event) =>
                                    handleChange(event)
                                }
                                required
                            >
                                <option value={""}>Select State</option>
                                {STATES.map((item, index) => (
                                    <option key={index} value={item.abbreviation}>{item.name}</option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full md:w-1/2 px-3 md:mb-0">
                            <label className="block required tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-first-name">
                                Address
                            </label>
                            <input
                                className="appearance-none block w-full bg-white  border-[#25C454]  border-none  dark:border-[#25C454] dark:focus:border-[#25C454]  text-gray-700  rounded py-3 px-4 mb-3 leading-tight  focus:outline-1 focus:bg-white"
                                id="grid-first-name"
                                type="text"
                                name='address'
                                value={store.address}
                                onChange={(event) =>
                                    handleChange(event)
                                }
                                required
                                placeholder="Enter address" />
                        </div>

                        <div className="w-full md:w-1/2 px-3 md:mb-0">
                            <label className="block  tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-first-name">
                                Change Password
                            </label>
                            <input
                                className="appearance-none block w-full bg-white  border-[#25C454]  border-none  dark:border-[#25C454] dark:focus:border-[#25C454]  text-gray-700  rounded py-3 px-4 mb-3 leading-tight  focus:outline-1 focus:bg-white"
                                id="grid-first-name"
                                type="text"
                                name='password'
                                value={store.password}
                                onChange={(event) =>
                                    handleChange(event)
                                }
                                placeholder="Enter password" />
                            {store?.password && store?.password?.length > 0 && !validPassword ? (
                                <small style={{ color: 'red' }}>Password should contain small, capital letter, number & special character</small>
                            ) : null}
                        </div>

                    </div>
                </form>


            </div>
        </>
    )
}

export default StoreProfile

// //  {/* business */}
// <div className="bg-[#F8F8F8] rounded p-[1.375rem] mb-20">
// <form onSubmit={handleSubmit} className="w-full">
//     <div className="w-full  mb-5">
//         {/* <h5 className='font-bold'>
//             Business Details</h5> */}
//         <div className="flex justify-between">
//             <div><span className="font-bold">Business Details</span></div>
//             <div className="">
//                 <button
//                     onClick={(e) => {
//                         e.preventDefault();
//                         console.log("Clicked!")
//                     }}
//                     className="bg-white rounded-[0.3125rem] py-2 px-3">Cancel</button>
//                 <button
//                     type='submit'
//                     className="ml-[0.5rem] bg-[#25C454] text-white rounded-[0.3125rem] py-2 px-3">
//                     Save
//                 </button>
//             </div>
//         </div>
//     </div>
//     <div className="flex flex-wrap -mx-3 mb-6">
//         <div className="w-full md:w-1/2 px-3 md:mb-0">
//             <label className="block  tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-first-name">
//                 Business Name
//             </label>
//             <input
//                 className="appearance-none block w-full bg-white  border-[#25C454]  border-none  dark:border-[#25C454] dark:focus:border-[#25C454]  text-gray-700  rounded py-3 px-4 mb-3 leading-tight  focus:outline-1 focus:bg-white"
//                 id="grid-first-name"
//                 type="text"
//                 name="businessName"
//                 value={formData.businessName}
//                 onChange={onChange}
//                 placeholder="Enter store name" />
//         </div>
//         <div className="w-full md:w-1/2 px-3">
//             <label className="block  tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name">
//                 Owner Name
//             </label>
//             <input
//                 className="appearance-none block w-full bg-white  border-[#25C454]  border-none  dark:border-[#25C454] dark:focus:border-[#25C454]  text-gray-700  rounded py-3 px-4 mb-3 leading-tight  focus:outline-1 focus:bg-white"
//                 id="grid-first-name"
//                 type="text"
//                 name="contactName"
//                 value={formData.contactName}
//                 onChange={onChange}
//                 placeholder="Enter owner name" />
//         </div>
//     </div>

//     <div className="flex flex-wrap -mx-3 mb-6">
//         <div className="w-full md:w-1/2 px-3  md:mb-0">
//             <label className="block  tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name">
//                 Phone Number
//             </label>
//             <div className="flex">
//                 <div className='bg-white rounded py-3 px-4 mb-3 mr-2'>
//                     +1
//                 </div>
//                 <input
//                     className="appearance-none block w-full bg-white  border-[#25C454]  border-none  dark:border-[#25C454] dark:focus:border-[#25C454]  text-gray-700  rounded py-3 px-4 mb-3 leading-tight  focus:outline-1 focus:bg-white"
//                     id="grid-first-name"
//                     type="text"
//                     name="phoneNo"
//                     value={formData.phoneNo}
//                     onChange={onChange}
//                     placeholder="Enter mobile no" />
//             </div>
//         </div>

//         <div className="w-full md:w-1/2 px-3">
//             <label className="block  tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name">
//                 Email
//             </label>
//             <input
//                 className="appearance-none block w-full bg-white  border-[#25C454]  border-none  dark:border-[#25C454] dark:focus:border-[#25C454]  text-gray-700  rounded py-3 px-4 mb-3 leading-tight  focus:outline-1 focus:bg-white"
//                 id="grid-first-name"
//                 type="text"
//                 name="email"
//                 value={formData.email}
//                 onChange={onChange}
//                 placeholder="Enter email" />
//         </div>
//     </div>

//     <div className="w-full md:w-1/2 mt-6 mb-6">
//         <h5 className='font-bold'>
//             Address Details</h5>
//     </div>

//     <div className="flex flex-wrap -mx-3 mb-6">
//         <div className="w-full md:w-1/2 px-3  md:mb-0">
//             <label className="block  tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name">
//                 Country
//             </label>
//             <select
//                 className="appearance-none block w-full bg-white  border-[#25C454]  border-none  dark:border-[#25C454] dark:focus:border-[#25C454]  text-gray-700  rounded py-3 px-4 mb-3 leading-tight  focus:outline-1 focus:bg-white"
//                 name='country'
//                 value={formData.country}
//                 onChange={onChange}
//                 id="businessCountry">
//                 <option value={""}>Select Country</option>
//                 <option value={'US'}>United State</option>
//             </select>
//         </div>

//         <div className="w-full md:w-1/2 px-3  md:mb-0">
//             <label className="block  tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name">
//                 States
//             </label>
//             <select
//                 className="appearance-none block w-full bg-white  border-[#25C454]  border-none  dark:border-[#25C454] dark:focus:border-[#25C454]  text-gray-700  rounded py-3 px-4 mb-3 leading-tight  focus:outline-1 focus:bg-white"
//                 name='state'
//                 value={formData.state}
//                 onChange={onChange}
//                 id="businessCountry">
//                 <option value={""}>Select State</option>
//                 {STATES.map((item, index) => (
//                     <option key={index} value={item.abbreviation}>{item.name}</option>
//                 ))}
//             </select>
//         </div>
//     </div>

//     <div className="flex flex-wrap -mx-3 mb-6">
//         <div className="w-full md:w-1/2 px-3 md:mb-0">
//             <label className="block  tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-first-name">
//                 Location
//             </label>
//             <input
//                 className="appearance-none block w-full bg-white  border-[#25C454]  border-none  dark:border-[#25C454] dark:focus:border-[#25C454]  text-gray-700  rounded py-3 px-4 mb-3 leading-tight  focus:outline-1 focus:bg-white"
//                 id="grid-first-name"
//                 type="text"
//                 name='address'
//                 value={formData.address}
//                 onChange={onChange}
//                 placeholder="Enter location" />
//         </div>
//     </div>
// </form>
// </div>