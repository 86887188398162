import React, { useState } from "react";
import { BrowserRouter, Routes, Route, Navigate, Link, Outlet } from "react-router-dom";
// import AuthMiddleware from "middleware/Authmiddleware";

import Home from "../pages/home";
import SiteHeader from "components/Header/header";
import Footer from "components/Footer/Footer";
import BusinessAccount from "components/BusinessAccount";
import IndividualAccount from "components/IndividualAccount";
import ViewDetails from "components/BusinessAccount/ViewDetails";
import ProfileSection from "components/accountProfile/ProfileSection";
import ResetPasswordBuisnessAccount from "components/ResetPassword/ResetPasswordBuisnessAccount";
import FAQ from "components/Faq/FAQ";
import Logo from "components/Header/logo";
import { useSelector } from "react-redux";
import StoreAccount from "components/StoreAccount/StoreAccount";
import StoreUserAccount from "components/StoreUserAccount/StoreUserAccount";
import TestStripe from "./test";
import Unauthorized from "components/Unauthorized";
import Payment from "components/Payment";
import PaymentSuccess from "components/PaymentSuccess";
// import MainLandingPage from "MainLandingPage";
export const restrictedRoute = [
  //   { path: "/my-profile", component: AuthorPage },
];

const publicRoute = [
  { path: "/", exact: true, component: <Home /> },
  { path: "/#", exact: true, component: <Home /> },
  { path: "/business-account", exact: true, component: <BusinessAccount /> },
  { path: "/individual-account", exact: true, component: <IndividualAccount /> },
  { path: "/view-report", exact: true, component: <ViewDetails /> },
  { path: "/signin", exact: true, component: <ProfileSection /> },
  { path: "/reset-password", exact: true, component: <ResetPasswordBuisnessAccount /> },
];

const Navbar = () => {
  return (
    <header className="border-0 border-gray-100 py-2">
      <div className="container">
        <div className="flex items-center justify-between ">
          <Logo />
          <nav className="flex items-center w-auto">
            <ul className="text-base text-gray-600">
              <li>
                <a href="#" className="px-5 py-2 hover:0">Home</a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </header>
  )
}

const Layout = () => {
  return (
    <>
      {/* <header/> */}
      <SiteHeader />
      {/* <Navbar /> */}
      <main>
        <Outlet />
      </main>
      {/* <FAQ />
      <Footer /> */}
    </>
  )
}

const ProtectedRoute = ({ children }) => {
  const { user } = useSelector((state) => state.auth);
  // const location = useLocation();
  if (!user) {
    return <Navigate to="/signup" replace />;
  }
  return children;
};

const MyProtectedRoute = ({ isAllowed, children }) => {
  const { user } = useSelector((state) => state.auth);
  console.log('MyProtectedRoute user ======== ', isAllowed);
  console.log({
    user,
    role: user?.role
  })

  if (!isAllowed) {
    return <Navigate to={'/'} replace />;
  }

  if (!user?.isPaid) {
    return <Navigate to={'/unauthorized'} replace />;
  }

 // if (user?.isExpired) {
 //   alert('Plan Expired!');
 //   return <Navigate to={'/'} replace />;
 // }

  return children ? children : <Outlet />;
};



const MyRouter = ({ }) => {
  const { user } = useSelector((state) => state.auth);

  return (
   // <BrowserRouter basename="/user">
   <BrowserRouter basename="/">
      <Routes>
        <Route element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/home" caseSensitive={true} element={<Home />} />
          <Route
            path="individual"
            caseSensitive={true}
            element={
              <MyProtectedRoute
                isAllowed={user && user?.role === 'IndividualUser' ? true : false}
              >
                <IndividualAccount />
              </MyProtectedRoute>
            }
          />
          <Route
            path="business"
            caseSensitive={true}
            element={
              <MyProtectedRoute
                isAllowed={user && user?.role === 'BusinessOwner'}
              >
                <BusinessAccount />
              </MyProtectedRoute>
            }
          />
          <Route
            path="store-detail/:id"
            element={
              <MyProtectedRoute
                isAllowed={user && (user?.role === 'StoreSupervisor' || user?.role === 'BusinessOwner')}
              >
                <StoreAccount />
              </MyProtectedRoute>
            }
          />
          <Route
            path="user-detail/:id"
            element={
              <MyProtectedRoute
                isAllowed={user && (user?.role === 'StoreSupervisor' || user?.role === 'BusinessOwner' || user?.role === 'StoreWorker')}
              >
                <StoreUserAccount />
              </MyProtectedRoute>
            }
          />




          <Route path="*" element={<Home />} />
          {/* <Route index element={<ProtectedRoute><Home /></ProtectedRoute>} />
          <Route path="home" caseSensitive={true} element={<ProtectedRoute> <Home /></ProtectedRoute>} />
          <Route path="business" caseSensitive={true} element={<BusinessUser > <BusinessAccount /></BusinessUser>} />
          <Route path="individual"
            element={
              <ProtectedRoute
              >
                <IndividualAccount />
              </ProtectedRoute>
            } />
          <Route path="view-detail" element={<ProtectedRoute> <ViewDetails /> </ProtectedRoute>} />
          <Route path="store-detail/:id" element={<ProtectedRoute> <StoreAccount /> </ProtectedRoute>} />
          <Route path="user-detail/:id" element={<ProtectedRoute> <StoreUserAccount /> </ProtectedRoute>} /> */}
        </Route>
        <Route path="signup" element={<ProfileSection />} />
        <Route path="reset-password" element={<ResetPasswordBuisnessAccount />} />
        <Route path="payment" element={<Payment />} />
        <Route path="success" element={<PaymentSuccess />} />
        <Route path="unauthorized" element={<Unauthorized />} />
        <Route path="test" element={<TestStripe />} />
      </Routes>
    </BrowserRouter>
  );
};

export default MyRouter;


