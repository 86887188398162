import React, { useState, useEffect } from 'react'
import { CardElement, useElements, useStripe, Elements } from "@stripe/react-stripe-js";
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import { Config } from "../config/index"
import { useDispatch, useSelector } from 'react-redux';
import { logout, reset } from 'features/auth/authSlice';

const CARD_ELEMENT_OPTIONS = {
    style: {
        base: {
            'color': '#32325d',
            'fontFamily': '"Helvetica Neue", Helvetica, sans-serif',
            'fontSmoothing': 'antialiased',
            'fontSize': '14px',
            '::placeholder': {
                color: '#aab7c4',
            },
        },
        invalid: {
            color: '#fa755a',
            iconColor: '#fa755a',
        },

    },
};

const Payment = () => {

    const { user, isLoading, isError, isSuccess, message } = useSelector(
        (state) => state.auth
    )

    const navigate = useNavigate();
    const stripe = useStripe();
    const elements = useElements();
    const dispatch = useDispatch();


    const [email, setEmail] = useState(user?.email ? user?.email : null);
    const [loading, setLoading] = useState(null)


    const updatePayment = async (id, email) => {
        setLoading(true)

        try {
            const response = await axios.put(`${Config.API_URL}/payment/${id}`);
            console.log('Update Payment Response: ', JSON.stringify(response.data, null, 3));

            if (response.status === 200) {
                const { status, subscriptionStatus } = response.data;
                if (status === 'paid' && subscriptionStatus === 'active') {
                    // dispatch(login({
                    //     email,
                    //     password: 'pass123'
                    // }));
                    localStorage.removeItem('user');
                    dispatch(logout());
                    dispatch(reset())
                    navigate('/success');
                }
            }

            if (response.status !== 200) {
                console.log(response.data)
                toast.error(response?.data?.message);
            }

        } catch (error) {
            console.log('Update Payment Error: ', error);
            toast.error(error?.response?.data?.message || 'Something went wrong!')
        } finally {
            setLoading(false);
        }
    }


    const handlePayment = async (e) => {

        e.preventDefault();
        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        const result = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardElement),
            billing_details: {
                email: email
            },
        });

        if (result.error) {
            toast.error(result.error.message || 'Something went wrong!');
            console.log("CreatePaymentMethodError: ", result.error.message);
        } else {
            try {
                setLoading(true);
                const res = await axios.post(`${Config.API_URL}/payment`,
                    {
                        'payment_method': result.paymentMethod.id,
                        'email': email,
                    });

                const { client_secret, status, paymentId } = res.data;
                console.log("subscription Response: ", JSON.stringify(res.data, null, 3));

                if (status === 'requires_action') {
                    stripe.confirmCardPayment(client_secret).then(function (result) {
                        console.log("confirmCardPayment repsonse: ", JSON.stringify(result, null, 3));
                        if (result.error) {
                            console.log('There was an issue!');
                            console.log(result.error);
                            toast.error('Something went wrong!');
                            // Display error message in your UI.
                            // The card was declined (i.e. insufficient funds, card has expired, etc)
                        } else if (result?.paymentIntent.status === "succeeded") {
                            toast.success('Success! Please login');
                            if (paymentId) {
                                updatePayment(paymentId, email);
                            }
                        }
                    });
                } else {
                    console.log('You got the money!2');
                    if (paymentId) {
                        updatePayment(paymentId, email);
                    }
                    toast.success('Success! Please login');
                }
            } catch (error) {
                console.log("Subscription Error: ", error);
                toast.error(error?.response?.data?.message || 'Something went wrong!')
            } finally {
                setLoading(false);
            }
        }
    }


    useEffect(() => {
        if (isError) {
            toast.error(message)
        }

        // if (isSuccess || user) {
        //     navigate('/home')
        // }

        if (user?.isPaid) {
            navigate('/home')
        }

        dispatch(reset())
    }, [user, isError, isSuccess, message, navigate, dispatch])


    return (
        <div className='flex items-center justify-center h-screen flex-col' style={{ backgroundImage: "url('images/individualLogin.png')", backgroundSize: 'cover' }}>

            <div class="w-full max-w-sm p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-6 md:p-8 dark:bg-gray-800 dark:border-gray-700">
                <form class="space-y-6" action="#">
                    <h5 class="text-xl font-medium text-gray-900 dark:text-white">Payment</h5>
                    <div>
                        <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Registered Email</label>
                        <input
                            type="email"
                            name="email"
                            id="email"
                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                            placeholder="Enter email"
                            required
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div>
                        <label for="password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Card Detail</label>
                        <div className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white">
                            <CardElement options={CARD_ELEMENT_OPTIONS} />
                        </div>
                    </div>
                    <button
                        type="submit"
                        onClick={handlePayment}
                        class="w-full text-white bg-[#25C454]  focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                    >
                        {loading ? 'Loading....' : `${user?.role === 'IndividualUser' ? 'Pay $50.00 USD' : user?.role === 'BusinessOwner' ? 'Pay $80.00 USD' : null}`}
                    </button>
                    <button
                        onClick={() => navigate('/home')}
                        class="w-full text-black bg-[#FFFFFF]  focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center border"
                    >
                        Cancel
                    </button>
                </form>
            </div>

        </div>
    )
}

export default Payment