import  store  from "../app/store"
import axios from "axios"
import { Config } from "../config/index"

const token = ''

const baseUrl = Config.API_URL

// const getAccessToken = () => store.getState().user.tokens?.access.token
const getAccessToken = () => store.getState().auth.user?.jwtToken

const axiosApi = axios.create({
  baseURL: Config.API_URL,
})

axiosApi.defaults.headers.common["Authorization"] = token

axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
)



export async function apiGET(url, config = {}) {
  let accessToken = getAccessToken()
  // console.log('accessToken:::: ', accessToken);
  axiosApi.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`
  return await axiosApi.get(url, { ...config }).then(response => response).catch(error => error.response)
}

export async function apiPOST(url, data, config = {}) {
  let accessToken = getAccessToken()
  // console.log("accessTokennn", accessToken)
  axiosApi.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response).catch((error) => error.response)
}

export async function apiPUT(url, data, config = {}) {
  let accessToken = getAccessToken()
  axiosApi.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response).catch((error) => error.response)
}

export async function apiDELETE(url, config = {}) {
  let accessToken = getAccessToken()
  axiosApi.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response).catch((error) => error.response)
}

export async function uploadPost(data) {
  let myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  const accessToken = getAccessToken()
  myHeaders.append("Authorization", "Bearer " + accessToken);
  const raw = JSON.stringify(data);
  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
  };
  try {
    const uploadUrl = baseUrl + "/v1/upload-file"
    const result = await fetch(uploadUrl, requestOptions)
    const response = await result.json()
    return response.data
  } catch (err) {
    console.log("err errerr ", err.message)
  }
}

export const getCoinbaseExchangeRates = async (fromCurrency = 'USD') => {
  var config = {
    method: 'get',
    url: 'https://api.coinbase.com/v2/exchange-rates?currency=' + fromCurrency,
    headers: {}
  };
  let resp = await axios(config);
  if (resp.status == 200) {
    return resp.data
  } else return null
}

const getGasPriceForMatic = async () => {
  var config = {
    method: 'get',
    url: 'https://api.polygonscan.com/api?module=gastracker&action=gasoracle&apikey=' + process.env.POLYGONSCAN_API_KEY,
    headers: {}
  };
  let resp = await axios(config);
  // console.log(resp, "getGasPriceForMatic")
  if (resp.status == 200) {
    return resp
  } else return null
}