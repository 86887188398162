import axios from 'axios';
import { getStores } from 'features/store/storeSlice';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { apiGET, apiPUT, apiDELETE } from 'utils/apiHelper';
import { Config } from "../../config/index"
import STATES from "../../states"
import { logout, reset } from 'features/auth/authSlice';
import { useNavigate } from 'react-router-dom';

const initialState = {
    "businessName": "",
    "ownerName": "",
    "email": "",
    "phoneNo": "",
    "country": "",
    "state": "",
    "address": "",
}

const stringPattern = new RegExp("[a-zA-Z ]$");
const numberPattern = new RegExp("[0-9]$");
const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

const MyProfile = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { user } = useSelector((state) => state.auth);
    const { userId } = user;

    const [formData, setFormData] = useState(initialState);
    const [isLoading, setIsLoading] = useState(false);
    const [hasChanged, setHasChanged] = useState(false);
    const [validPassword, setValidPassword] = useState(false);

    async function updateUser(data) {
        try {
            const response = await apiPUT(`${Config.API_URL}/users/business/${userId}`, data);

            if (response.status === 200 && response.data) {
                toast.success("Profile Updated Successfully!")
            }

            if (response.status !== 200) {
                console.log(response.data)
                return toast.error(response?.data?.message);
            }

        } catch (error) {
            console.log(error);
            toast.error(error?.response?.data?.message || 'Something went wrong!')
        }
    }

    async function getUser(userId) {
        try {
            const response = await apiGET(`${Config.API_URL}/users/${userId}`);
            if (response.status === 200) {
                setFormData({
                    businessName: response.data.businessName,
                    ownerName: response.data.ownerName,
                    email: response.data.email,
                    phoneNo: response.data.phoneNo,
                    country: response.data.country,
                    state: response.data.state,
                    address: response.data.address
                })
            }
        } catch (error) {
            console.log(error);
            toast.error('Something went wrong!');
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("Business MyProfile: ", formData);
        const allTruthy = Object.values(formData).every(value => value);
        // console.log("allTruthy ====== ", allTruthy);

        if (!allTruthy) {
            return toast.error("Please fill all mandatory fields!");
        }

        if (formData.email && emailRegex.test(formData.email) === false) {
            return toast.error("Invalid Email!")
        }

        if (formData.phoneNo && formData.phoneNo.length !== 10) {
            return toast.error("Invalid Phone No!")
        }

        if (formData.businessName && formData.businessName.trim().length < 3) {
            return toast.error("Business name not valid!")
        }

        if (formData.ownerName && formData.ownerName.trim().length < 3) {
            return toast.error("Owner name not valid!")
        }


        if (formData?.password?.length > 0 && !validPassword) {
            return toast.error('Invalid Password!');
        }

        updateUser(formData);
    }

    const onChange = (e) => {

        if ((e.target.name === "businessName" && e.target.value) || (e.target.name === "ownerName" && e.target.value)) {
            if (stringPattern.test(e.target.value) == false) {
                return
            }
        }

        if (e.target.name === "phoneNo" && e.target.value) {
            if (numberPattern.test(e.target.value) == false) {
                return
            }
        }

        if (e.target.name === 'password' && e.target.value) {
            const isValidPassword = passwordRegex.test(e.target.value);
            console.log('isValidPassword: ', isValidPassword)
            setValidPassword(isValidPassword);
        };

        setFormData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }))

        setHasChanged(true)
    }

    async function handleDeleteUser(e) {
        e.preventDefault();
        setIsLoading(true);
        try {
            const response = await apiDELETE(`/users/business/${userId}`);
            if (response.status === 200) {
                toast.success(response?.data?.message);
                dispatch(logout());
                dispatch(reset())
                navigate('/home')
            }

            if (response.status !== 200) {
                console.log(response.data)
                toast.error(response?.data?.message);
            }
        } catch (error) {
            console.log(error);
            toast.error(error?.response?.data?.message || 'Something went wrong!')
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        if (userId) {
            getUser(userId)
        }
    }, [userId]);

    const isDisabled = !hasChanged;

    return (
        <>
            <div className="bg-[#F8F8F8] rounded p-[1.375rem] mb-20">
                <form onSubmit={handleSubmit} className="w-full">
                    <div className="w-full  mb-5">
                        <div className="flex justify-between">
                            <div><span className="font-bold">Business Details</span></div>
                            <div className="">
                                <button
                                    onClick={(e) => {
                                        e.preventDefault();
                                        navigate(0);
                                    }}
                                    className="bg-white rounded-[0.3125rem] py-2 px-3">Cancel</button>
                                <button
                                    disabled={isDisabled}
                                    type='submit'
                                    className={`ml-[0.5rem] bg-[#25C454] text-white rounded-[0.3125rem] py-2 px-3 ${isDisabled && 'opacity-75'}`}>
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full md:w-1/2 px-3 md:mb-0">
                            <label className="block  tracking-wide text-gray-700 text-xs font-bold mb-2 required" htmlFor="grid-first-name">
                                Business Name
                            </label>
                            <input
                                className="appearance-none block w-full bg-white  border-[#25C454]  border-none  dark:border-[#25C454] dark:focus:border-[#25C454]  text-gray-700  rounded py-3 px-4 mb-3 leading-tight  focus:outline-1 focus:bg-white"
                                id="grid-first-name"
                                type="text"
                                name="businessName"
                                value={formData.businessName}
                                onChange={onChange}
                                placeholder="Enter store name" />
                        </div>
                        <div className="w-full md:w-1/2 px-3">
                            <label className="block  tracking-wide text-gray-700 text-xs font-bold mb-2 required" htmlFor="grid-last-name">
                                Owner Name
                            </label>
                            <input
                                className="appearance-none block w-full bg-white  border-[#25C454]  border-none  dark:border-[#25C454] dark:focus:border-[#25C454]  text-gray-700  rounded py-3 px-4 mb-3 leading-tight  focus:outline-1 focus:bg-white"
                                id="grid-first-name"
                                type="text"
                                name="ownerName"
                                value={formData.ownerName}
                                onChange={onChange}
                                placeholder="Enter owner name" />
                        </div>
                    </div>

                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full md:w-1/2 px-3  md:mb-0">
                            <label className="block  tracking-wide text-gray-700 text-xs font-bold mb-2 required" htmlFor="grid-last-name">
                                Phone Number
                            </label>
                            <div className="flex">
                                <div className='bg-white rounded py-3 px-4 mb-3 mr-2'>
                                    +1
                                </div>
                                <input
                                    className="appearance-none block w-full bg-white  border-[#25C454]  border-none  dark:border-[#25C454] dark:focus:border-[#25C454]  text-gray-700  rounded py-3 px-4 mb-3 leading-tight  focus:outline-1 focus:bg-white"
                                    id="grid-first-name"
                                    type="text"
                                    name="phoneNo"
                                    value={formData.phoneNo}
                                    onChange={onChange}
                                    minLength={10}
                                    maxLength={10}
                                    placeholder="Enter mobile no" />
                            </div>
                        </div>

                        <div className="w-full md:w-1/2 px-3">
                            <label className="block  tracking-wide text-gray-700 text-xs font-bold mb-2 required" htmlFor="grid-last-name">
                                Email
                            </label>
                            <input
                                className="appearance-none block w-full bg-white  border-[#25C454]  border-none  dark:border-[#25C454] dark:focus:border-[#25C454]  text-gray-700  rounded py-3 px-4 mb-3 leading-tight  focus:outline-1 focus:bg-white"
                                id="grid-first-name"
                                type="text"
                                name="email"
                                value={formData.email}
                                onChange={onChange}
                                placeholder="Enter email" />
                        </div>
                    </div>

                    <div className="w-full md:w-1/2 mt-6 mb-6">
                        <h5 className='font-bold'>
                            Address Details</h5>
                    </div>

                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full md:w-1/2 px-3  md:mb-0">
                            <label className="block  tracking-wide text-gray-700 text-xs font-bold mb-2 required" htmlFor="grid-last-name">
                                Country
                            </label>
                            <select
                                className="appearance-none block w-full bg-white  border-[#25C454]  border-none  dark:border-[#25C454] dark:focus:border-[#25C454]  text-gray-700  rounded py-3 px-4 mb-3 leading-tight  focus:outline-1 focus:bg-white"
                                name='country'
                                value={formData.country}
                                onChange={onChange}
                                id="businessCountry">
                                <option value={""}>Select Country</option>
                                <option value={'US'}>United States</option>
                            </select>
                        </div>

                        <div className="w-full md:w-1/2 px-3  md:mb-0">
                            <label className="block  tracking-wide text-gray-700 text-xs font-bold mb-2 required" htmlFor="grid-last-name">
                                State
                            </label>
                            <select
                                className="appearance-none block w-full bg-white  border-[#25C454]  border-none  dark:border-[#25C454] dark:focus:border-[#25C454]  text-gray-700  rounded py-3 px-4 mb-3 leading-tight  focus:outline-1 focus:bg-white"
                                name='state'
                                value={formData.state}
                                onChange={onChange}
                                id="businessCountry">
                                <option value={""}>Select State</option>
                                {STATES.map((item, index) => (
                                    <option key={index} value={item.abbreviation}>{item.name}</option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full md:w-1/2 px-3 md:mb-0">
                            <label className="block  tracking-wide text-gray-700 text-xs font-bold mb-2 required" htmlFor="grid-first-name">
                                Address
                            </label>
                            <input
                                className="appearance-none block w-full bg-white  border-[#25C454]  border-none  dark:border-[#25C454] dark:focus:border-[#25C454]  text-gray-700  rounded py-3 px-4 mb-3 leading-tight  focus:outline-1 focus:bg-white"
                                id="grid-first-name"
                                type="text"
                                name='address'
                                value={formData.address}
                                onChange={onChange}
                                placeholder="Enter address" />
                        </div>
                        <div className="w-full md:w-1/2 px-3 md:mb-0">
                            <label className="block  tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-first-name">
                                Change Password
                            </label>
                            <input
                                className="appearance-none block w-full bg-white  border-[#25C454]  border-none  dark:border-[#25C454] dark:focus:border-[#25C454]  text-gray-700  rounded py-3 px-4 mb-3 leading-tight  focus:outline-1 focus:bg-white"
                                id="grid-first-name"
                                type="text"
                                name='password'
                                value={formData.password}
                                onChange={onChange}
                                placeholder="Enter password" />
                            {formData?.password && formData?.password?.length > 0 && !validPassword ? (
                                <small style={{ color: 'red' }}>Password should contain small, capital letter, number & special character</small>
                            ) : null}
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}

export default MyProfile
